import React, { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'semantic-ui-react';
import { Button, Header, Input, Message, Field } from 'react-frontier';
import { SetLoading, Location } from '@arema/components/Classes';
import { bindFormChange, MEXICO_STATES } from '@arema/components/Util';
import API from '../API';
import Validator from '@arema/components/Validator';
import Toast from "react-hot-toast";

interface ModalProps {
	open: boolean,
	editable: boolean,
	onClose: () => void,
	location_id: number
	onLocationEdit?: (address: Location) => void,
}

var LocationModal = (props: ModalProps) => {
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [address, setAddress] = useState<Location>(null);
	var [loadError, setLoadError] = useState<string>(null);
	let location_id = props.location_id || null

	useEffect(() => {
		if (props.open && !Number.isNaN(location_id)) {
			if (!location_id) {
				setAddress({
					street: '',
					exterior_number: '',
					neighborhood: '',
					zipcode: '',
					city: '',
					state: '',
					country: '',
				});
			} else {
				loadData(location_id);
			}
		}
	}, [props.open, props.location_id]);

	const loadData = async (location_id: number) => {
		setLoadError(null);
		API.getLocationInfo(location_id).then(res => {
			if (res.error) return setLoadError(res.message);
			const data = res.data;
			setAddress(data);
		}).catch(err => {
			setModalPrompts(['Hubo un error cargando la cuenta (LCL-3)']);
		})
	}

	var submit = (setLoading: SetLoading) => {
		if (!props.editable) return;
		var { valid, prompts } = Validator(address, {
			street: [
				{ rule: 'minLength', params: [3], label: 'Calle' }
			],
			exterior_number: [
				{ rule: 'empty', label: 'Número exterior' }
			],
			neighborhood: [
				{ rule: 'minLength', params: [3], label: 'Colonia' }
			],
			zipcode: [
				{ rule: 'minLength', params: [4], label: 'Código postal' }
			],
			city: [
				{ rule: 'minLength', params: [3], label: 'Ciudad' }
			],
			state: [
				{ rule: 'empty', prompt: 'Favor de seleccionar el estado' }
			]
		});
		setModalPrompts(prompts);
		if (!valid) return;

		if (address.location_id) {
			updateLocation(setLoading);
		} else {
			createLocation(setLoading);
		}
	}

	var createLocation = (setLoading: SetLoading) => {
		setLoading(true);
		API.createLocation(address).then(res => {
			if (res.error) return setLoadError(res.message);
			Toast.success('Se guardaron los datos correctamente');
			let newLocation = {
				...address,
				location_id:  res.data
			}
			if (props.onLocationEdit) {
				props.onLocationEdit(newLocation);
			}
			setAddress(newLocation);
		}).catch(err => {
			Toast.error(`Hubo un error inesperado guardando los datos (LCL-10)`);
		}).finally(() => {
			setLoading(false)
		});
	}

	var updateLocation = (setLoading: SetLoading) => {
		setLoading(true);
		API.updateLocation(address).then(res => {
			if (res.error) return setLoadError(res.message);
			Toast.success('Se guardaron los datos correctamente');
			if (props.onLocationEdit) {
				props.onLocationEdit(address);
			}
		}).catch(err => {
			Toast.error(`Hubo un error inesperado guardando los datos (LCL-10)`);
		}).finally(() => {
			setLoading(false)
		});
	}

	var onAddressChange = bindFormChange(address, setAddress);

	return <Modal open={props.open} onClose={props.onClose} size='tiny'>
		<Modal.Header>Direccion</Modal.Header>
		<Modal.Content>
			{loadError ? (
				<Header text='Error' iconName='face-frown-open' subtext={loadError} />
			) : address === null ? (
				<Header loading text='Cargando Direccion' />
			) :
				address ? <>
					<Input readonly={!props.editable} label='Calle' value={address.street} onChange={onAddressChange('street')} />
					<Field amount={2} style={{ marginBottom: 0 }}>
						<Input readonly={!props.editable} label='Número exterior' value={address.exterior_number} onChange={onAddressChange('exterior_number')} />
						<Input readonly={!props.editable} label='Número interior' value={address.interior_number} onChange={onAddressChange('interior_number')} />
					</Field>
					<Field amount={2} style={{ marginBottom: 0 }}>
						<Input readonly={!props.editable} label='Colonia' value={address.neighborhood} onChange={onAddressChange('neighborhood')} />
						<Input readonly={!props.editable} label='Código postal' value={address.zipcode} onChange={onAddressChange('zipcode')} />
					</Field>
					<Input readonly={!props.editable} label='Ciudad' value={address.city} onChange={onAddressChange('city')} />
					<Field label='Estado'>
						<Dropdown
							disabled={!props.editable}
							search
							selection
							selectOnBlur={false}
							placeholder='Estado'
							value={address.state}
							onChange={onAddressChange('state', true)}
							options={MEXICO_STATES.map(a => ({
								value: a, text: a
							}))}
						/>
					</Field>
					<Field amount={2}>
						{address.lattitude &&
							<Input label='Latitud' readonly value={address.lattitude} />
						}
						{address.longitude &&
							<Input label='Longitud' readonly value={address.longitude} />
						}
					</Field>
				</> : null
			}
			<Message style={{ marginTop: 15 }} type='error' list={modalPrompts} />
		</Modal.Content>
		<Modal.Actions>
			<Button basic text='Cerrar' onClick={props.onClose} />
			{ props.editable && <Button text='Guardar' color='black' onClick={submit} /> }
		</Modal.Actions>
	</Modal>
}

export default LocationModal;