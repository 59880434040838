import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Field, Groupper, Header, Input, Message, Table } from "react-frontier";
import { Location, SetLoading, Venue, VenueSection, Zone } from "@arema/components/Classes";
import { PLACES_KEY } from "../../AdminConfig";
import { bindClose, bindFormChange, checkLocation, formatPlacesComponents } from "@arema/components/Util";
import { Checkbox, Dropdown, Icon, Modal } from "semantic-ui-react";
import { LocationView, NotFound, UserLog, DeletedBanner, SearchZoneModal } from "../../components";
import { useUser } from "../../AdminHooks";
import Autocomplete from "react-google-autocomplete";
import API from "../../API";
import Toast from "react-hot-toast";
import Validator from "@arema/components/Validator";
import moment from "moment";
import UserAccess from '../../UserAccess';

enum MODAL {
	EDIT_SECTION = 100,
	CLONE_SECTION = 101,
	USER_SECTION = 102,
	DELETE_SECTION = 103,
	DELETE_VENUE = 104,
	SEARCH_ZONE = 1,
}

const VenueCreate = (props: any) => {
	var { venue_id } = useParams();
	var { hasAccess } = useUser();
	var navigate = useNavigate()
	var IS_CREATE = venue_id === 'create';
	var [venue, setVenue] = useState<Venue>(null);
	var [editLocation, setEditLocation] = useState<boolean>(false);
	var [newLocation, setNewLocation] = useState<Location>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [editSection, setEditSection] = useState<VenueSection>(null);
	var [cloneSection, setCloneSection] = useState<VenueSection>(null);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [shownModal, setShownModal] = useState<MODAL>(null);
	var [deleteConfirm, setDeleteConfirm] = useState<string>('');
	var canDeleteSection = hasAccess(UserAccess.VENUES.DELETE_SECTION);
	var canDeleteVenue = hasAccess(UserAccess.VENUES.DELETE_VENUE);

	useEffect(() => {
		if (!IS_CREATE && !Number.isNaN(parseInt(venue_id))) {
			loadData(parseInt(venue_id));
		} else {
			setVenue({
				venue_id: null,
				venue_name: '',
				zone_id: null,
				zone_name: null,
				location_id: null,
			})
		}
	}, [venue_id]);

	const loadData = async (venue_id: number) => {
		if (venue) return;
		API.getVenue(venue_id).then(res => {
			if (res.error) return setLoadError(res.message);
			setVenue(res.data);
		}).catch(err => {
			setLoadError('Hubo un error cargando el recinto (LCL-3)');
		});
	}

	const submit = async (setLoading: SetLoading) => {
		var body: any = {
			venue_name: venue ? venue.venue_name : '',
			zone_id: venue.zone_id,
			venue_id: null,
			location: null,
		}
		if (IS_CREATE || newLocation) {
			body.location = newLocation;
		}
		var { valid, prompts } = Validator(body, {
			venue_name: [{
				rule: 'length', params: [4, 32], label: 'Nombre'
			}],
			...((IS_CREATE || newLocation) ? {
				location: [{ rule: checkLocation, label: 'Ubicación' }]
			} : {})
		});
		setErrorPrompts(prompts);
		if (!valid) return;

		if (!IS_CREATE) {
			body.venue_id = parseInt(venue_id);
		}

		setLoading(true);
		if (IS_CREATE) {
			API.createVenue(body.venue_name, body.zone_id, body.location).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				Toast.success('Se creó el recinto de manera correcta')
				navigate(`/venues/${res.data.venue_id}`, { replace: true });
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperado creando el calendario (LCL-10)']);
			}).finally(() => {
				setLoading(false);
			});
		} else {
			API.editVenue(body.venue_id, body.venue_name, body.zone_id, body.location).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				if (newLocation) {
					var vn = { ...venue };
					vn.location = { ...newLocation };
					setVenue(vn);
					setEditLocation(false);
					setNewLocation(null);
				}
				Toast.success('Se ha editando el recinto.');
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperado editando el calendario (LCL-11)']);
			}).finally(() => {
				setLoading(false);
			});
		}
	}

	const onLocationSelected = (location: Location) => {
		if (!location) return;
		setEditLocation(false);
		setNewLocation(location);
	}

	const showEditSection = (section: VenueSection) => {
		return () => {
			setShownModal(MODAL.EDIT_SECTION)
			setEditSection({ ...section });
		}
	}

	const showCloneSection = (section: VenueSection) => {
		return () => {
			setShownModal(MODAL.CLONE_SECTION)
			setCloneSection({ ...section });
		}
	}

	const showUserSection = (section: VenueSection) => {
		return () => {
			setShownModal(MODAL.USER_SECTION)
			setEditSection({ ...section });
		}
	}

	const showDeleteSection = (section: VenueSection) => {
		return () => {
			setShownModal(MODAL.DELETE_SECTION)
			setEditSection({ ...section });
		}
	}

	const showAddSection = () => {
		setShownModal(MODAL.EDIT_SECTION)
		setEditSection({
			section_id: null,
			section_name: '',
			description: '',
			infinite: null,
			numbered: null,
			ticket_count: 0,
			venue_id: venue.venue_id,
		})
	}

	const saveSection = (setLoading: SetLoading) => {
		if (!editSection) return;
		var { valid, prompts, form } = Validator({
			section_name: editSection.section_name,
			venue_id: editSection.venue_id,
			ticket_count: (editSection.infinite || editSection.numbered) ? 999 : editSection.ticket_count,
		}, {
			venue_id: ['empty'],
			section_name: [
				{ rule: 'length', params: [3, 32], label: 'Nombre' },
			],
			ticket_count: [
				{ rule: 'min', params: [1], label: 'Cantidad de boletos' }
			]
		});

		setModalPrompts(prompts);
		if (!valid) return;
		setLoading(true);
		if (!editSection.section_id) {
			API.createVenueSection(venue.venue_id, {
				section_name: editSection.section_name,
				description: editSection.description,
				infinite: !!editSection.infinite,
				numbered: !!editSection.numbered,
				ticket_count: form.ticket_count
			}).then(res => {
				if (res.error) return setModalPrompts([res.message]);
				if (!res.data.section_id) return setModalPrompts(['Hubo un error inesperado creando la sección. (LCL-2)']);
				var vn = { ...venue };
				vn.sections.push({
					section_id: res.data.section_id,
					section_name: editSection.section_name,
					description: editSection.description,
					infinite: !!editSection.infinite,
					numbered: !!editSection.numbered,
					ticket_count: form.ticket_count,
					venue_id: venue.venue_id,
				});
				setVenue(vn);
				Toast.success(`Se ha agregado la sección "${editSection.section_name}"`);
				setEditSection(null);
				setShownModal(null);
			}).catch(err => {
				setModalPrompts(['Hubo un error inesperado creando la sección. (LCL-10)']);
			}).finally(() => {
				setLoading(false);
			})
		} else {
			API.editVenueSection(editSection.section_id, {
				section_name: editSection.section_name,
				description: editSection.description,
				infinite: !!editSection.infinite,
				ticket_count: form.ticket_count,
				numbered: null,
			}).then(res => {
				if (res.error) return setModalPrompts([res.message]);
				var vn = { ...venue };
				var six = vn.sections.findIndex(a => a.section_id == editSection.section_id);
				if (six > -1) {
					vn.sections[six].section_name = editSection.section_name;
					vn.sections[six].description = editSection.description;
					vn.sections[six].infinite = !!editSection.infinite;
					vn.sections[six].ticket_count = form.ticket_count;
					setVenue(vn);
				}
				Toast.success(`Se ha editado la sección "${editSection.section_name}"`);
			}).catch(err => {
				setModalPrompts(['Hubo un error inesperado creando la sección. (LCL-11)']);
			}).finally(() => {
				setLoading(false);
			});
		}
	}

	const onCloneSection = (setLoading: SetLoading) => {
		if (!cloneSection) return;
		var { valid, prompts } = Validator({
			section_name: cloneSection.section_name,
			description: cloneSection.description,
		}, {
			section_name: [
				{ rule: 'length', params: [3, 32], label: 'Nombre' },
			],
			description: [
				{ rule: 'length', params: [0, 128], label: 'Descripcion' },
			],
		});

		setModalPrompts(prompts);
		if (!valid) return;
		setLoading(true);
		API.cloneVenueSection(cloneSection.section_id, cloneSection.section_name, cloneSection.description).then(res => {
			if (res.error) return setModalPrompts([res.message]);
			if (!res.data.section_id) return setModalPrompts(['Hubo un error inesperado clonando la sección. (LCL-2)']);
			var vn = { ...venue };
			vn.sections.push({
				section_id: res.data.section_id,
				section_name: cloneSection.section_name,
				description: cloneSection.description,
				infinite: !!cloneSection.infinite,
				numbered: !!cloneSection.numbered,
				ticket_count: 999,
				venue_id: venue.venue_id,
			});
			setVenue(vn);
			Toast.success(`Se ha agregado la sección "${cloneSection.section_name}"`);
			setCloneSection(null);
			setShownModal(null);
		}).catch(err => {
			setModalPrompts(['Hubo un error inesperado creando la sección. (LCL-10)']);
		}).finally(() => {
			setLoading(false);
		})
	}

	const onDeleteSection = (setLoading: SetLoading) => {
		if (!editSection) return;
		setLoading(true);
		API.deleteSection(editSection.section_id).then(res => {
			if (res.error) return setModalPrompts([res.message]);
			if (!res.data) return setModalPrompts(['Hubo un error inesperado eliminando la sección. (LCL-2)']);
			var vn = { ...venue };
			vn.sections = vn.sections.filter(s => s.section_id !== editSection.section_id);
			setVenue(vn);
			Toast.success(`Se ha eliminado la sección "${editSection.section_name}"`);
			setCloneSection(null);
			setShownModal(null);
		}).catch(err => {
			setModalPrompts(['Hubo un error inesperado eliminando la sección. (LCL-10)']);
		}).finally(() => {
			setLoading(false);
		})
	}

	const onDeleteVenue = (setLoading: SetLoading) => {
		if (deleteConfirm.trim() !== venue.venue_name) return Toast.error(`Favor de ingresar el texto de confirmación: "${venue.venue_name}"`);

		setLoading(true);
		API.deleteVenue(parseInt(venue_id)).then(res => {
			if (res.error) return setModalPrompts([res.message]);
			if (!res.data) return setModalPrompts(['Hubo un error inesperado eliminando el recinto. (LCL-2)']);
			Toast.success(`Se ha eliminado el recinto "${venue.venue_name}"`);
			return navigate(-1);
		}).catch(err => {
			setModalPrompts(['Hubo un error inesperado eliminando el recinto. (LCL-10)']);
		}).finally(() => {
			setLoading(false);
		})
	}

	var clearLocation = () => {
		setEditLocation(true);
		setNewLocation(null);
	}
	
	var onSelectZone = (zone: Zone) => {
		setVenue({
			...venue,
			zone_id: zone.zone_id,
			zone_name: zone.zone_name,
		})
		setShownModal(null);
	}

	if (!IS_CREATE && Number.isNaN(parseInt(venue_id))) {
		return <NotFound />
	}
	if (loadError) {
		return <Header text="Error" subtext={loadError} iconName='face-frown-open' />
	}
	if (!IS_CREATE && !venue) {
		return <Header text="Cargando recinto" loading />
	}

	var onEditSectionChange = bindFormChange(editSection, setEditSection);
	var onEditVenueChange = bindFormChange(venue, setVenue);
	var onCloneSectionChange = bindFormChange(cloneSection, setCloneSection);

	return (
		<div>
			{!!venue?.deleted && <DeletedBanner date_deleted={venue.date_deleted} />}
			<Groupper title={IS_CREATE ? 'Crear recinto' : `Editar recinto`} width={600} actions={(
				<Button text="Guardar" color="black" onClick={submit} />
			)}>
				<Input label="Nombre" placeholder="Nombre del Recinto" value={venue?.venue_name} onChange={onEditVenueChange('venue_name')} />
				{!editLocation && (newLocation || venue?.location) ? (
					<LocationView location={newLocation || venue?.location} onButtonClick={clearLocation} onChange={setNewLocation} />
				) : (
					<Field label="Ubicación">
						<Autocomplete
							apiKey={PLACES_KEY}
							options={{
								fields: ["formatted_address", "geometry", "name", "address_components", "place_id"],
								strictBounds: false,
								types: ["establishment"],
								componentRestrictions: { country: 'mx' }
							}}
							onPlaceSelected={place => {
								var loc = null;
								try {
									loc = formatPlacesComponents(place);
								} catch (e) { }
								if (!loc) return Toast.error('Hubo un error inesperado seleccionando la ubicación.');
								onLocationSelected(loc);
							}}
						/>
					</Field>
				)}
				<Field label="Zona" style={{ marginTop: 5 }} >
					<Input
						readonly
						placeholder="Zona del recinto"
						value={venue?.zone_name}
						button={<Button
							icon
							iconName='pencil'
							style={{ padding: 5, width: 55, borderLeft: '1px solid #DDDDDD' }}
							onClick={() => setShownModal(MODAL.SEARCH_ZONE)} />}
					/>
				</Field>
				{IS_CREATE && (
					<Message text="Las secciones y mapas de butacas se podrán agregar una vez creado el recinto." style={{ marginTop: 15, marginBottom: 15 }} />
				)}
				<Message list={errorPrompts} type="error" style={{ marginTop: 15 }} />
			</Groupper>
			{(!IS_CREATE && venue) ? (
				<table className="fr details striped divided table" style={{ maxWidth: 800, margin: 'auto', marginTop: 15 }}>
					<thead>
						<tr>
							<th className="title" colSpan={2}>Secciones</th>
							<th style={{ textAlign: 'right' }} colSpan={3}>
								<Button color="black" size="tiny" text="Agregar" iconName="plus" onClick={showAddSection} />
							</th>
						</tr>
						<tr>
							<th className="collapsing">ID</th>
							<th>Nombre</th>
							<th>Descripción</th>
							<th className="collapsing">Tipo</th>
							<th className="collapsing"><Icon name="cog" /></th>
						</tr>
					</thead>
					<tbody>
						{venue.sections && venue.sections.length > 0 ? venue.sections.map(a => (
							<tr key={`sect-${a.section_id}`}>
								<td>{a.section_id}</td>
								<td className="collapsing">{a.section_name}</td>
								<td>{a.description || <i style={{ color: 'gray' }}>Sin descripción</i>}</td>
								<td><i style={{ textAlign: 'center', width: '100%', fontSize: 20 }} className={`${a.numbered ? 'chair' : 'hashtag'} icon`}></i></td>
								<td>
									<Dropdown direction="left" icon={null} trigger={(
										<Button icon iconName="cog" size="tiny" style={{ lineHeight: '20px' }} />
									)}>
										<Dropdown.Menu>
											<Dropdown.Item text="Editar sección" onClick={showEditSection(a)} />
											{a.numbered ? (
												<>
													<Dropdown.Item as={Link} text="Editar numeración" to={`/venues/${venue_id}/sections/${a.section_id}/numerate`} />
													<Dropdown.Item text="Clonar sección" onClick={showCloneSection(a)} />
												</>
											) : null}
											<Dropdown.Item text="Usuarios" onClick={showUserSection(a)} />
											{
												canDeleteSection && <Dropdown.Item text="Eliminar Seccion" onClick={showDeleteSection(a)} />
											}
										</Dropdown.Menu>
									</Dropdown>
								</td>
							</tr>
						)) : (
							<tr>
								<td colSpan={5} className="empty">
									No hay secciones en este recinto
								</td>
							</tr>
						)}
					</tbody>
				</table>
			) : null}

			{(!IS_CREATE) ?
				<UserLog user={venue} /> : null
			}

			{(!IS_CREATE && canDeleteVenue && !venue.deleted) ?
				<div className="fr option last" style={{ justifyContent: 'center', marginTop: 20 }}>
					<Button color="red" text="Eliminar recinto" basic onClick={() => setShownModal(MODAL.DELETE_VENUE)} />
				</div>
				: null
			}

			<Modal open={shownModal === MODAL.EDIT_SECTION} onClose={bindClose(setShownModal)} size="tiny">
				<Modal.Header>{editSection?.section_id ? 'Editar' : 'Agregar'} sección</Modal.Header>
				{editSection ? (
					<Modal.Content>
						<Input label="Nombre" value={editSection.section_name} onChange={onEditSectionChange('section_name')} />
						<Field label="Tipo de sección">
							<Dropdown
								selection
								disabled={!!editSection.section_id}
								placeholder="Tipo de sección"
								text={editSection.numbered === null ? 'Seleccionar el tipo de sección' : (editSection.numbered ? 'Numerado' : 'General')}
								value={editSection.numbered}
								options={[
									{ text: 'General', value: 0 },
									{ text: 'Numerado', value: 1 },
								]}
								onChange={onEditSectionChange('numbered', true)}
							/>
						</Field>
						{editSection.numbered !== null && !editSection.numbered && !editSection.infinite ? (
							<Input label="Cantidad de boletos" onChange={onEditSectionChange('ticket_count')} value={editSection.ticket_count} />
						) : null}
						{editSection.numbered !== null && !editSection.numbered ? (
							<Checkbox toggle label={'Capacidad infinita'} checked={!!editSection.infinite} onChange={onEditSectionChange('infinite', true)} style={{ marginTop: 5, marginBottom: 10 }} />
						) : null}
						<Field label="Descripción interna">
							<textarea onChange={e => onEditSectionChange('description')(e.target.value)} placeholder="Descripción y comentarios internos de la sección" value={editSection.description || ''}></textarea>
						</Field>
						<Message list={modalPrompts} type="error" />
					</Modal.Content>
				) : null}
				<Modal.Actions>
					<Button text="Guardar" color="black" onClick={saveSection} />
				</Modal.Actions>
			</Modal>

			<Modal open={shownModal === MODAL.CLONE_SECTION} onClose={bindClose(setShownModal)} size="tiny">
				<Modal.Header>Clonar sección</Modal.Header>
				{cloneSection ? (
					<Modal.Content>
						<Table
							details
							striped
							fitted
							style={{ maxWidth: 600 }}
							data={[
								['ID', ''],
								['Nombre', ''],
								['Descripción', '']
							]}
						/>

						<Header size="small" text="Nueva sección" style={{ marginTop: 15 }} />
						<Input label="Nombre" value={cloneSection.section_name} onChange={onCloneSectionChange('section_name')} />
						<Input label="Descripcion" value={cloneSection.description} onChange={onCloneSectionChange('description')} />
						<Message type="info" text="Se copiarán todas las butacas y el estado de las butacas a la nueva sección con el nombre y descripción ingresado" />
						<Message list={modalPrompts} type="error" />
					</Modal.Content>
				) : null}
				<Modal.Actions>
					<Button text="Regresar" basic onClick={bindClose(setShownModal)} />
					<Button text="Clonar Seccion" color="black" onClick={onCloneSection} />
				</Modal.Actions>
			</Modal>

			<Modal open={shownModal === MODAL.USER_SECTION} onClose={bindClose(setShownModal)} size="mini">
				<Modal.Header>Usuarios</Modal.Header>
				<Modal.Content>
					<Table
						details
						striped
						style={{ margin: 'auto', maxWidth: 600 }}
						data={[
							['Creador', <>
								{editSection?.admin_creator_name?.toUpperCase()}
								<div className="meta">ID: {editSection?.admin_id}</div>
							</>],
							['Fecha creación', <>
								{moment.unix(editSection?.date_created).format('DD/MMM/YY HH:mm')}
							</>],
						]}
					/>
				</Modal.Content>
				<Modal.Actions>
					<Button text="Cerrar" onClick={bindClose(setShownModal)} />
				</Modal.Actions>
			</Modal>

			<Modal open={shownModal === MODAL.DELETE_SECTION} onClose={bindClose(setShownModal)} size="mini">
				<Modal.Header>Eliminar sección</Modal.Header>
				{!!editSection && <Modal.Content>
					<Header text="Eliminar sección" subtext="¿Seguro que quiere eliminarla?" />
					<Table
						details
						title="Sección"
						titleSize="small"
						style={{ marginTop: 15 }}
						data={[
							['ID', editSection.section_id],
							['Nombre', editSection.section_name],
						]}
					/>
					<Message list={modalPrompts} type="error" />
				</Modal.Content>}
				<Modal.Actions>
					<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
					<Button text="Eliminar" color="red" onClick={onDeleteSection} />
				</Modal.Actions>
			</Modal>

			<Modal open={shownModal === MODAL.DELETE_VENUE} onClose={bindClose(setShownModal)} size="mini">
				<Modal.Header>Eliminar Recinto</Modal.Header>
				<Modal.Content>
					<Header size='small' text='¿Eliminar el recinto?' />
					<ul style={{ paddingLeft: 15 }}>
						<li>Una vez eliminada el recinto, no podrá ser recuperado</li>
						<li>Si hay eventos activos el recinto <b>NO</b> será eliminado</li>
					</ul>
					<Field label='Confirmación' comment={'Ingresa el siguiente texto para eliminar el reciinto'}>
						<Input
							placeholder={venue?.venue_name}
							comment={`"${venue?.venue_name}"`}
							commentStyle={{ fontWeight: 'bold' }}
							value={deleteConfirm}
							onChange={setDeleteConfirm}
						/>
					</Field>
				</Modal.Content>
				<Modal.Actions>
					<Button text='Cancelar' basic onClick={bindClose(setShownModal)} />
					<Button text='Eliminar' color={'red'} onClick={onDeleteVenue} />
				</Modal.Actions>
			</Modal>

			<SearchZoneModal 
				open={shownModal===MODAL.SEARCH_ZONE}
				onClose={() => setShownModal(null)}
				onSelected={onSelectZone}
				title="Buscar Zona"
			/>
		</div>
	)
}

export default VenueCreate;