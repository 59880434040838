import React, { useEffect, useState } from 'react';
import { RouterProvider, useSearchParams } from 'react-router-dom';
import { UserProvider, useUser } from './AdminHooks';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import API from './API';
import moment from 'moment';

import 'fomantic-ui-css/semantic.css';
import 'frontier-css/css/frontier.css';
import './style/admin.scss';

import ChangePassword from './screens/profile/ChangePassword';
import RouterComponent from './Router';
import { Toast } from 'react-frontier';

const MOMENT_SPANISH_LOCALE = {
	months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
	monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
	longDateFormat: {
		LT: 'h:mm a',
		LTS: 'h:mm:ss a',
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY h:mm a',
		LLLL: 'dddd D MMMM YYYY h:mm a'
	}
}
moment.updateLocale('es', MOMENT_SPANISH_LOCALE);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

var Root = ()=>{
	var { login: is_login, user } = useUser();
	var is_auth = !user && window.location.pathname==='/auth';

	useEffect(()=>{
		var now = moment().unix();
		var login = API.getToken();
		if((!login || now>(login.expires-30)) && !is_auth){
			window.location.href = API.getAuthUrl();
		}
		
		var logoutEvent = ()=>{
			if(!is_login){
				window.location.href = API.getAuthUrl();
			}
		}
		window.addEventListener('user_logout', logoutEvent);
		return ()=>{
			window.removeEventListener('user_logout', logoutEvent);
		}
	}, [is_login]);
	
	var RouterNav = RouterComponent();

	return <UserProvider>
		<Toast.Provider>
			<RouterProvider router={RouterNav} />
		</Toast.Provider>
	</UserProvider>
}

root.render(<Root />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();