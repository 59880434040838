import React, { useEffect, useState } from 'react';
import { Button, Field, Input, Message, Header, Pagination } from 'react-frontier';
import { Checkbox, Modal } from 'semantic-ui-react';
import { Link, Params, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SetLoading, APIResponse } from '@arema/components/Classes';
import { bindChange, bindFormChange, bindToggle } from '@arema/components/Util';
import Validator, { RuleType, ValidatorFormRules } from '@arema/components/Validator';
import { useTitle } from '@arema/components/Hooks';
import classNames from 'classnames';

export interface CatalogParams{
	query?: string,
	[x: string]: any,
}

export interface CatalogFilter{
	key: string,
	shorthand?: string,
	label: string,
	defaultValue?: any,
	options?: { value: any, text: string }[],
	type: 'text' | 'checkbox' | 'dropdown' | 'calendar',
	rules?: RuleType
}

export interface CatalogOptions{
	key: string,
	title: string,
	useTitle?: boolean,
	search?: boolean,
	searchText?: string,
	emptyText: string,
	loadingText?: string,
	submitCreate?: (data: any, setLoading: SetLoading)=>Promise<APIResponse<any>>,
	createUrl?: string,
	getData: (params: CatalogParams, offset?: number, count?: number)=>Promise<APIResponse<any[]>>
	catalogUrl?: (val: any)=>string,
	pagination?: boolean,
	pageSize?: number,
	extractKey?: (item: any)=>any,
	renderData?: (item: any)=>JSX.Element,
	table?: {
		name: string,
		key?: string,
		collapsing?: boolean,
		centered?: boolean,
		render?: (val: any, obj: any)=>(string | JSX.Element)
	}[],
	createText?: string,
	filters?: CatalogFilter[][],
	alwaysShowFilters?: boolean,
	form?: {
		[x: string]: {
			type: 'text' | 'checkbox' | 'message',
			label?: string,
			options?: string,
			description?: string,
			content?: string,
			value?: any,
			rules?: RuleType
		}	
	}
}

var Catalog = (props: { options: CatalogOptions, style?: React.CSSProperties, containerStyle?: React.CSSProperties })=>{
	var { options } = props;
	var navigate = useNavigate();
	var location = useLocation();
	var [params, setParams] = useSearchParams();
	var [pathname, setPathname] = useState(null);
	var [data, setData] = useState<APIResponse<any[]>>(null);
	var [searchData, setSearchData] = useState<APIResponse<any[]>>(null);
	var [page, setPage] = useState<number>(0);
	var [searchQuery, setSearchQuery] = useState<string>(null)
	var [searchVal, setSearchVal] = useState<string>(params?.get('query') || '');
	var [createModal, setCreateModal] = useState<boolean>(false);
	var [createForm, setCreateForm] = useState<any>({});
	var [createPrompts, setCreatePrompts] = useState<string[]>(null);
	var [searching, setSearching] = useState<boolean>(false);
	var [showFilters, setShowFilters] = useState<boolean>(false);
	var [filterData, setFilterData] = useState<any>(null);
	var { setTitle } = useTitle();

	var getData = async (use_search: boolean=true, new_page: number=null)=>{
		if(data) setData(null);
		var offset = typeof options.pageSize!=='undefined' ? options.pageSize*(new_page!==null ? new_page : page) : 0;
		
		try{
			var { filters, params: filter_params } = getSearchData();
			var res = await options.getData(use_search ? filters : null, offset, options.pageSize || 20);
			if(use_search && params.get('query') && params.get('query').length>0){
				setSearchQuery(params.get('query'));
				setSearchData(res);
			}else{
				setSearchData(null);
				setData(res);
			}
		}catch(e){
			throw e;
		}
		return res;
	}

	useEffect(()=>{
		if(options.getData){
			if(pathname!==location.pathname){
				setSearchData(null);
				setData(null);
				setSearchQuery('');
				search();
			}
		}
		if(pathname!==location.pathname){
			setPathname(location.pathname);
		}
		if(props.options.useTitle!==false) setTitle(props.options.title);
	}, [page, location.pathname, params]);

	useEffect(()=>{
		var filter_data : any = {};
		if(options.filters){
			for(var i of options.filters.flat()){
				if (i.defaultValue) {
					filter_data[i.key] = i.defaultValue;
				}
				var d = params.get(i.shorthand);
				if(d===null) continue;
				filter_data[i.key] = i.type==='checkbox' ? parseInt(d)==1 : d;
			}

			if(Object.keys(filter_data).length>0){
				setShowFilters(true);
				setFilterData(filter_data);
			}
		}
	}, []);

	var submitForm = (setLoading: SetLoading)=>{
		var rules : ValidatorFormRules = {}
		var cf = { ...createForm };
		for(var k in options.form){
			if(options.form[k].rules){
				rules[k] = {
					label: options.form[k].label,
					rules: options.form[k].rules as any
				}
				if(!cf[k]){
					cf[k] = ''
				}
			}
		}
		var { valid, prompts, form } = Validator(cf, rules);
		setCreatePrompts(prompts);
		if(!valid) return;
		setLoading(true);
		options.submitCreate(form, setLoading).then(res=>{
			if(res.error) return setCreatePrompts([res.message]);
			var url = options.catalogUrl(res.data);
			if(url) navigate(url);
			else if(data && data.data){
				var dt = {...data};
				dt.data.push(res.data);
				setData(dt);
			}
		}).catch(err=>{
			setCreatePrompts([`Hubo un error enviando la información. (LCL-${options.title.toUpperCase()}-1`]);
		}).finally(()=>{
			setLoading(false);
		})
	}
	var searchInputKey = (e: React.KeyboardEvent<HTMLInputElement>)=>{
		if(e.key==='Enter'){
			search()
		}
	}

	var getSearchData = (use_params: boolean=false, check_shown: boolean=false)=>{
		var query = searchVal && searchVal.length>0 ? searchVal : null;
		var new_params : any = {};
		var filters = (use_params || !filterData) ? {} : { ...filterData };
		if(query){
			new_params['query'] = query;
			filters['query'] = query;
		}
		if(options.filters && options.filters.length>0){
			if(!use_params && filterData){
				if(!check_shown || options.alwaysShowFilters || showFilters){
					for(let i in filterData){
						if(filterData[i] && filterData[i].toString().trim().length>0){
							var filter = options.filters.flat(1).find(a=>a.key===i);
							if(!filter) continue;
							new_params[filter.shorthand] = typeof filterData[i] === 'boolean' ? (filterData[i] ? 1 : 0) : filterData[i];
						}
					}
				}
			}else{
				for(let i of options.filters.flat()){
					var d = params.get(i.shorthand);
					if(d===null && !i.defaultValue) continue;
					if (i.defaultValue) {
						new_params[i.shorthand] = i.defaultValue;
						filters[i.key] = i.defaultValue;
					} else {
						new_params[i.shorthand] = d;
						filters[i.key] = i.type==='checkbox' ? parseInt(d)==1 : d;
					}
				}
			}
		}

		return {
			params: new_params,
			filters,
		}
	}

	var search = ()=>{
		var query = searchVal && searchVal.length>0 ? searchVal : null;
		var search_data = getSearchData(filterData===null, filterData!==null);
		setParams(search_data.params);
		setSearching(true);
		setSearchQuery(query);
		setPage(0);
		options.getData(search_data.filters, 0, options.pageSize || 0).then(setSearchData).finally(()=>{
			setSearching(false);
		})
	}

	var onSearchChange = (e: { target: { value: string } })=>{
		if(searchData && (!e.target?.value || e.target?.value.length===0)){
			setSearchData(null)
			setSearchQuery(null);
			setPage(0);
		
			params.delete('query');
			setParams(params);

			if(!data){
				getData(false, 0);
			}
		}
		return bindChange(setSearchVal)(e);
	}

	var onPageChange = (new_page: number)=>{
		setPage(new_page);
		setSearching(true);
		getData(true, new_page).finally(()=>{
			setSearching(false);
		})
	}

	var onFilterChange = (key: string)=>{
		return (val: any)=>{
			setFilterData({
				...filterData,
				[key]: val,
			});
		}
	}
	
	var onInputFormChange = bindFormChange(createForm, setCreateForm)
	var res_data = (searchData && searchData.data) ? searchData : data;

	return <div style={props.containerStyle}>
		<div className="fr catalog fitted groupper" style={props.style}>
			{!!options.title && options.title.length>0 && (
				<div className="head big" style={{ marginBottom: options.search ? 15 : 0 }}>{options.title}</div>
			)}
			<div className="top" style={{ padding: `${options.title && options.title.length>0 ? '0px 15px' : '15px 15px 5px 15px'}` }}>
				{options.search ? (
					<div className="fr input action">
						<input type="text" placeholder={options.searchText || 'Buscar...'} onKeyUp={searchInputKey} value={searchVal} onChange={onSearchChange} />
						{options.filters && options.filters.length>0 && !options.alwaysShowFilters ? (
							<Button icon iconName='filter' onClick={bindToggle(showFilters, setShowFilters)} style={{ padding: 5, width: 55, borderLeft: '1px solid #DDDDDD' }} />
						) : null}
						<Button text='Buscar' onClick={search} />
					</div>
				) : null}
			</div>
			{options.filters?.length>0 && (showFilters || options.alwaysShowFilters===true) && (
				<div className="top filters">
					{options.filters.map((a,i)=>{
						const Elem = a.length==1 ? (props: { amount: number, children: any })=><div style={{ marginBottom: 10 }}>{props.children}</div> : Field;
						return <Elem amount={a.length} key={`filterfield-${i}`}>
							{a.map(b=>(
								b.type==='text' ? (
									<Input key={`filter${i}-${b.key}`} label={b.label} value={filterData ? (filterData[b.key] || '') : ''} onChange={onFilterChange(b.key)} />
								) : b.type==='checkbox' ? (
									<Field key={`filter${i}-${b.key}`}>
										<Checkbox toggle label={b.label} checked={filterData ? (filterData[b.key] || false) : false} onChange={bindChange(onFilterChange(b.key), true)} />
									</Field>
								) : b.type==='calendar' ? (
									<Input calendar={{
										date: filterData ? (filterData[b.key] || '') : '',
										mode: 'date'
									}} key={`filter${i}-${b.key}`} value={filterData ? (filterData[b.key] || '') : ''} label={b.label} onChange={onFilterChange(b.key)} />
								) : null
							))}
						</Elem>
					})}
				</div>
			)}

			{!options.search && (options.filters && options.filters.length > 0) &&  (
				<Button text='Buscar Filtro' onClick={search} />
			)}

			{options.createUrl ? (
				<Link className='fr button' style={{ margin: 'auto', marginBottom: 10, display: 'block', width: 250 }} to={options.createUrl}>
					<i className="plus circle icon"></i>
					{options.createText || 'Crear nuevo'}
				</Link>
			) : options.submitCreate ? (
				<Button text={options.createText || 'Crear nuevo'} style={{ margin: 'auto', marginBottom: 10, display: 'block', width: 250 }} iconName='plus-circle' onClick={()=>setCreateModal(true)} />
			) : null}
			{(searching || (!searchData && !data)) ? (
				<Header loading text='Cargando datos...' containerStyle={{ margin: '50px 0' }} />
			) : !res_data || res_data.error ? (
				<Header text='Error' subtext={res_data?.message || 'Hubo un error inesperado cargando el catálogo. (LCL-1)'} containerStyle={{ margin: '50px 0' }} />
			) : options.table ? (
				<table className="fr selectable compact divided striped first table" style={{ marginTop: 0 }}>
					<thead>
						{!!searchData && searchQuery && searchQuery.length>0 && (
							<tr>
								<th style={{ textAlign: 'center', fontWeight: 'normal' }} colSpan={options.table.length+1}>
									Resultados de "<b>{searchQuery}</b>"
								</th>
							</tr>
						)}
						<tr>
							{options.table.map(a=>(
								<th key={`table-head-${options.key}-${a.key || a.name}`} className={a.collapsing ? 'collapsing' : ''}>{a.name}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{res_data && res_data.data && res_data.data.length>0 ? <>
							{res_data.data.map((a: any, i: number)=>(
								<tr key={`clg-rw-${options.key}-${i}`}>
									{options.table.map(t=>{
										var contents = t.key ? (
											t.render ? t.render(a[t.key], a) : a[t.key]
										) : t.render ? (
											t.render(null, a)
										) : null
										return (
											<td className={classNames({
												collapsing: t.collapsing,
												centered: t.centered,
												normal: !options.catalogUrl
											})} key={`clg-cl-${options.key}-${i}:${(t.key || t.name)}`}>
												{options.catalogUrl ? (
													<Link to={options.catalogUrl(a)}>{contents}</Link>
												) : contents}
											</td>
										)
									})}
								</tr>
							))}
						</> : (
							<tr>
								<td className="empty normal" colSpan={options.table.length+1}>
									{options.emptyText || `No hay registros de ${options.title.toLowerCase()}`}
								</td>
							</tr>
						)}
					</tbody>
					{options.pagination && (res_data.data.length>=options.pageSize || page!=0) && (
						<tfoot>
							<tr>
								<td colSpan={options.table.length+1} style={{ padding: 10 }}>
									<div style={{ justifyContent: 'flex-end', display: 'flex' }}>
										<Pagination page={page} disabled={searching} onPageChange={onPageChange} hasNext={res_data.data.length>=options.pageSize} />
									</div>
								</td>
							</tr>
						</tfoot>
					)}
				</table>
			) : options.renderData && options.extractKey ? (
				<div className="fr striped selectable items">
					{!!searchData && searchQuery && searchQuery.length>0 && (
						<div className='item noselect' style={{ display: 'block', textAlign: 'center', fontWeight: 'normal' }} >
							Resultados de "<b>{searchQuery}</b>"
						</div>
					)}
					{res_data.data.length==0 ? (
						<Header text='Sin registros' subtext={options.emptyText || `No hay registros de ${options.title.toLowerCase()}`} containerStyle={{ margin: '50px 0' }} />
					) : res_data.data.map((a, i)=>{
						var key = options.extractKey(a);
						if(!key) return null;
						return <Link className="item" to={options.catalogUrl ? options.catalogUrl(a) : null} key={`clg-cli-${options.key}-${key}`}>
							{options.renderData(a)}
						</Link>
					})}
				</div>
			) : null}
		</div>
		{options.form ? (
			<Modal open={createModal} onClose={()=>setCreateModal(false)} size='tiny'>
				<Modal.Header>{options.createText}</Modal.Header>
				<Modal.Content>
					{Object.keys(options.form).map(k=>(
						options.form[k].type=='text' ? (
							<Input key={`form-inp-${options.key}-${k}`} label={options.form[k].label} value={createForm[k] || ''} onChange={onInputFormChange(k)} />
						) : options.form[k].type=='checkbox' ? (
							<Field key={`form-inp-${options.key}-${k}`} label={options.form[k].label}>
								<Checkbox toggle label={options.form[k].description} value={options.form[k].value ? 1 : 0} onChange={onInputFormChange(k, true)} />
							</Field>
						) : options.form[k].type=='message' ? (
							<Message key={`form-inp-${options.key}-${k}`} text={options.form[k].content} type='info' style={{ marginTop: 15, marginBottom: createPrompts ? 15 : 0 }} />
						) : null
					))}
					<Message list={createPrompts} type={'error'} />
				</Modal.Content>
				<Modal.Actions>
					<Button text='Guardar' color='black' onClick={submitForm} />
				</Modal.Actions>
			</Modal>
		) : null}
	</div>
}

export default Catalog;