import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Dropdown, Icon, Modal } from 'semantic-ui-react';
import { addCommas, bindClose, bindFormChange } from '@arema/components/Util';
import { Button, Field, Groupper, Header, Input, Toolbar } from 'react-frontier';
import { usePrinter } from '../../AdminHooks';
import { SetLoading } from '@arema/components/Classes';
import Draggable, { DraggableCore } from 'react-draggable';

import '../../style/creator.scss'
import API from '../../API';
import { PrintTicket } from '../../AdminClasses';
import moment from 'moment';
import { useParams } from 'react-router-dom';

enum ContentType{
	TEXT = 1,
	BOX = 2,
	QRCODE = 3,
	BARCODE = 4,
}

enum Rotation{
	PORTRAIT = 0,
	LANDSCAPE = 1,
	LANDSCAPE_REVERSE = 2,
}

enum TicketData{
	EVENT_NAME = 'event_name',
	VENUE_NAME = 'venue_name',
	PROMOTER_NAME = 'promoter_name',
	ORDER_HASH = 'order_hash',
	TICKET_HASH = 'ticket_hash',
	DATE = 'ticket_date',
	DATE_ID = 'date_id',
	DATE_PURCHASE = 'date_paid',
	TICKET_COST = 'ticket_cost',
	PRICE_ID = 'price_id',
	PRICE_NAME = 'price_name',
	SECTION_ID = 'section_id',
	SECTION_NAME = 'section_name',
	SEAT_ROW = 'seat_row',
	SEAT_NUMBER = 'seat_number',
	SEAT_FORMAT = 'seat_format',
	SEAT_SECTION = 'seat_section'
}

const PRINTER_DPI = 203;
const TICKET_SIZE_WIDTH = 2;
const TICKET_SIZE_HEIGHT = 5.5;

interface TicketItem{
	x: number,
	y: number,
	type: ContentType,
	size?: number,
	width?: number,
	height?: number,
	thickness?: number,
	content?: any,
	content_key?: TicketData,
	inverted?: boolean,
	rotation: Rotation,
}

interface DraggableItemProps{
	onClick?: ()=>void,
	onPositionChange?: (x: number, y: number)=>{ x: number, y: number },
	onSizeChange?: (width: number, height: number)=>void,
	selected?: boolean,
	scale: number, 
	bounds: {
		left: number,
		right: number,
		top: number,
		bottom: number,
		rotation: Rotation,
	},
}

var getTicketDataOptions = ()=>{
	return [
		{ value: null,								text: 'Texto normal' },
		{ value: TicketData.EVENT_NAME,		text: 'Nombre evento' },
		{ value: TicketData.VENUE_NAME,		text: 'Nombre recinto' },
		{ value: TicketData.PROMOTER_NAME,	text: 'Nombre promotor' },
		{ value: TicketData.ORDER_HASH,		text: 'Identificador orden' },
		{ value: TicketData.TICKET_HASH,		text: 'Identificador boleto' },
		{ value: TicketData.DATE_ID,			text: 'ID Calendario' },
		{ value: TicketData.DATE,				text: 'Fecha boleto' },
		{ value: TicketData.DATE_PURCHASE,	text: 'Fecha compra' },
		{ value: TicketData.TICKET_COST,		text: 'Costo boleto' },
		{ value: TicketData.PRICE_ID,			text: 'ID Precio' },
		{ value: TicketData.PRICE_NAME,		text: 'Nombre precio' },
		{ value: TicketData.SECTION_ID,		text: 'ID Sección' },
		{ value: TicketData.SECTION_NAME,	text: 'Nombre sección' },
		{ value: TicketData.SEAT_ROW,			text: 'Fila asiento' },
		{ value: TicketData.SEAT_NUMBER,		text: 'Butaca asiento' },
		{ value: TicketData.SEAT_FORMAT,		text: 'Butaca formateada' },
		{ value: TicketData.SEAT_SECTION,	text: 'Sección butaca' },
	]
}

var getPlaceholderData = ()=>{
	var now = moment();
	return {
		ticket_id: 2024,
		event_name: 'Evento prueba',
		venue_name: 'Teatro AREMA',
		promoter_name: 'AREMA',
		order_id: parseInt(now.format('DDMMYY')),
		ticket_date: now.unix(),
		date_id: now.get('day'),
		date_paid: now.unix(),
		ticket_cost: now.get('year'),
		price_id: now.get('month'),
		price_name: 'Precio prueba',
		section_id: now.get('dayOfYear'),
		section_name: 'Sección prueba',
		seat_id: 2024,
		seat_row: 'A',
		seat_number: '97',
		seat_section: 'San',  
		ticket_hash: 'TS7A88AIAN13',
		order_hash: 'S4RBB5CAN0G',
		seat_format: 'A97',
	} as PrintTicket;
}

const DATE_KEYS = ['ticket_date', 'date_paid'];
const MONEY_KEYS = ['ticket_cost'];

function formatZplTemplate(zpl: string, data: PrintTicket){
	for(var k in data){
		var d = data[k as keyof PrintTicket];
		if(DATE_KEYS.indexOf(k)>-1){
			d = moment.unix(d as number).format('DD/MMM/YY hh:mm a');
		}else if(MONEY_KEYS.indexOf(k)>-1){
			d = `$${addCommas(d as number)}`;
		}

		zpl = zpl.replace(new RegExp(`%ZTICKETA\\[${k}\\]%`, 'gi'), d as string);
	}
	return zpl;
}

var DraggableItem = (props: TicketItem & DraggableItemProps)=>{
	var ref = useRef(null);

	useEffect(()=>{
		var width = ref.current?.offsetWidth || 0;
		var height = ref.current?.offsetHeight || 0;
		if(props.onSizeChange) props.onSizeChange(width, height);
	}, [ref, props.rotation, props.content, props.size]);

	var placeholder = getPlaceholderData();

	return <Draggable
		onDrag={(e, d)=>{
			props.onPositionChange(d.x/props.scale, d.y/props.scale);
		}}
		grid={[5, 5]}
		position={{
			x: (props.x || 0)*props.scale, 
			y: (props.y || 0)*props.scale
		}}
		bounds={'parent'}
		disabled={!props.selected}
	>
		<div ref={ref} onClick={props.onClick} style={{ 
			position: 'absolute',
			fontWeight: 'bold',
			fontSize: `${((props.size || 10)*0.93)*props.scale}px`,
			lineHeight: `${((props.size || 10)*0.93)*props.scale}px`,
			cursor: 'pointer',
			userSelect: 'none',
			borderRadius: 4,
			border: !props.selected ? 'none' : '2px solid blue',
			padding: 5*props.scale,
			margin: props.selected ? -(4*props.scale) : 0,
		}}>
			{props.type==ContentType.TEXT ? (
				<div style={{
					writingMode: props.rotation===Rotation.LANDSCAPE ? 'sideways-rl' : (props.rotation===Rotation.LANDSCAPE_REVERSE ? 'sideways-lr' : 'horizontal-tb'),
					textAlign: 'left',
					whiteSpace: 'nowrap',
					fontStyle: props.content_key ? 'italic' : 'normal',
					color: props.content_key ? 'blue' : 'black',
				}}>
					{props.content_key ? (
						`%${placeholder[props.content_key] || props.content_key}%`
					) : props.content}
				</div>
			) : props.type===ContentType.BOX ? (
				<div style={{
					width: props.width*props.scale,
					height: props.height*props.scale,
					backgroundColor: !props.inverted ? 'transparent' : 'black',
					border: !props.inverted ? '1px solid black' : 'none',
				}}></div>
			) : props.type===ContentType.QRCODE ? (
				<div style={{ width: props.size*10, height: props.size*10, backgroundColor: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Icon style={{ marign: 0, color: 'white', fontSize: props.size*5 }} name="qrcode" />
				</div>
			) : null}
		</div>
	</Draggable>
}

interface RenderProps{
	contents: TicketItem[], 
	onContentsChange?: (contents: TicketItem[])=>void,
	onContentSelected?: (ix: number)=>void,
	onAdd?: (x: number, y: number)=>void,
	selected?: number,
	scale: number, 
	width: number,
	height: number,
	rotation?: Rotation,
	adding?: boolean,
}
var ZPLRender = (props: RenderProps)=>{
	const IS_PORTRAIT = props.rotation===Rotation.PORTRAIT
	var width_px = Math.ceil(PRINTER_DPI*props.width);
	var height_px = Math.ceil(PRINTER_DPI*props.height)+50;

	return <div id="zplclear" style={{
		display: 'flex',
		flexDirection: IS_PORTRAIT ? 'column' : 'row-reverse'
	}}>
		<div 
			id="zplclear"
			onClick={(e)=>{
				if(!props.adding || !props.onAdd) return;
				var bounds = (e.currentTarget as any).getBoundingClientRect();
				var x = Math.ceil((e.clientX - bounds.left)/props.scale);
				var y = Math.ceil((e.clientY - bounds.top)/props.scale);
				props.onAdd(x, y);
			}}
			style={{
				width: width_px*props.scale,
				height: height_px*props.scale,
				backgroundColor: '#DDD',
				borderRadius: 8,
				position: 'relative',
				cursor: props.adding ? 'crosshair' : 'default'
			}}
		>
			{props.contents.map((a,i)=>(
				<DraggableItem {...a} 
					key={`ITM-${i}`}
					scale={props.scale} 
					bounds={{ left: 0, top: 50, right: width_px, bottom: height_px+50, rotation: props.rotation }} 
					selected={props.selected===i}
					onPositionChange={(x, y)=>{
						var cts = [...props.contents];
						cts[i].x = x;
						cts[i].y = y;
						props.onContentsChange(cts);
						return { x, y };
					}}
					onSizeChange={(width: number, height: number)=>{
						var cts = [...props.contents];
						cts[i].width = width;
						cts[i].height = height;
						props.onContentsChange(cts);
					}}
					onClick={()=>{
						if(props.onContentSelected) props.onContentSelected(i);
					}}
				/>
			))}
		</div>
		<div id="zplclear"  style={{ 
			width: IS_PORTRAIT ? width_px*props.scale : 30,
			height: !IS_PORTRAIT ? height_px*props.scale : 30,
			marginTop: IS_PORTRAIT ? 5 : 0,
			marginLeft: !IS_PORTRAIT ? 5 : 0,
			writingMode: IS_PORTRAIT ? 'horizontal-tb' : 'sideways-rl',
			paddingTop: IS_PORTRAIT ? 5 : 0,
			paddingRight: !IS_PORTRAIT ? 5 : 0,
			backgroundColor: '#151717',
			borderRadius: 8,
			color: 'white',
			textAlign: 'center',
			fontWeight: 'bold',
		}}>
			Frente
		</div>
	</div>
}

interface CreatorProps{
	create?: boolean,
}

var TicketCreator = (props: CreatorProps)=>{
	var [size, setSize] = useState({ 
		scale: 0.5,
		width: TICKET_SIZE_WIDTH, 
		height: TICKET_SIZE_HEIGHT,
		rotation: Rotation.PORTRAIT
	});
	var [adding, setAdding] = useState<ContentType>(null);
	var [selected, setSelected] = useState<number>(null);
	var [confirmModal, setConfirmModal] = useState<boolean>(false);
	var [printModal, setPrintModal] = useState<boolean>(false);
	var [contents, setContents] = useState<TicketItem[]>([]);
	var [template, setTemplate] = useState({ template_name: '', description: '' });
	var { settings, configurePrinter, printZPL } = usePrinter();
	var params = useParams();

	var width_px = Math.ceil(PRINTER_DPI*size.width);
	var height_px = Math.ceil(PRINTER_DPI*size.height);

	useEffect(()=>{
		setContents([
			{ "x": 350, "y": 30, "content": "test", "rotation": 1, "type": 1, "size": 20, "width": 14, "height": 153, "content_key": TicketData.PROMOTER_NAME },
			{ "x": 323, "y": 30, "content": "PRESENTA", "rotation": 1, "type": 1, "size": 20, "width": 14, "height": 51, "content_key": null },
			{ "x": 276, "y": 30, "content": "", "rotation": 1, "type": 1, "size": 35, "width": 21, "height": 251, "content_key": TicketData.EVENT_NAME },
			{ "x": 205, "y": 80, "content": "", "rotation": 1, "type": 1, "size": 20, "width": 14, "height": 116, "content_key": TicketData.ORDER_HASH },
			{ "x": 205, "y": 30, "content": "Folio", "rotation": 1, "type": 1, "size": 20, "width": 14, "height": 25, "content_key": null },
			{ "x": 159, "y": 30, "content": "", "rotation": 1, "type": 1, "size": 35, "width": 21, "height": 241, "content_key": TicketData.DATE },
			{ "x": 123, "y": 30, "content": "", "rotation": 1, "type": 1, "size": 25, "width": 17, "height": 178, "content_key": TicketData.VENUE_NAME },
			{ "x": 86, "y": 30, "content": "", "rotation": 1, "type": 1, "size": 25, "width": 17, "height": 179, "content_key": TicketData.SECTION_NAME },
			{ "x": 50, "y": 30, "content": "", "rotation": 1, "type": 1, "size": 25, "width": 17, "height": 104, "content_key": TicketData.TICKET_COST },
			{ "x": 176, "y": 582, "type": 3, "content": null, "size": 9, "rotation": 0, "width": 99, "height": 99, "content_key": TicketData.TICKET_HASH },
		])
	}, []);
	
	var rotationToLetter = (rotation: Rotation)=>{
		switch(rotation){
			case Rotation.PORTRAIT: return 'N';
			case Rotation.LANDSCAPE: return 'R';
			case Rotation.LANDSCAPE_REVERSE: return 'B';
			// case Rotation.I: return 'I';
			// case Rotation.B: return 'B';
			default: return 'N'
		}
	}

	var generateZPL = (pretty: boolean=false, display: boolean=false)=>{
		var zpl = ['^XA'];

		var cts = [...contents];

		for(var c in contents){
			let i = cts[c];
			var text = i.content_key ? `%ZTICKETA[${i.content_key}]%` : i.content;
			if((text && text.length>0) || ([ContentType.BOX, ContentType.QRCODE].indexOf(i.type)>-1)){
				zpl.push(`^FX ${c},${i.type}`)
			}else continue;
			switch(i.type){
				case ContentType.TEXT:
					if(i.rotation===Rotation.PORTRAIT){
						zpl.push(`^CF0,${i.size}`);
						zpl.push(`^FWN^FO${i.x},${Math.min(i.y, height_px-i.height)},0`);
					}else{
						zpl.push(`^A0${rotationToLetter(i.rotation)}${i.size},${i.size}^FO${i.x},${i.y}`);
					}

					zpl.push(`^FD${text}^FS`)
					break;
				case ContentType.BOX:
					zpl.push(`^FO${i.x},${i.y}`, `^GB${i.width},${i.height},${i.inverted ? Math.min(i.width, i.height) : (i.thickness || 1)},B`, '^FS')
					break;
				case ContentType.QRCODE:
					zpl.push('^BY5,2,0', `^FO${i.x},${i.y*0.95}`, `^BQN,2,${Math.max(1, Math.min(i.size, 10))},Q,7`);
					zpl.push(`^FDMA${display ? '0000000000' : text}^FS`)
					break;
			}
		}
		zpl.push('^XZ');
		return zpl.join(pretty ? '\n' : '');
	}

	var zoom = (amt: number)=>{
		return ()=>{
			setSize({ ...size, scale: size.scale+(amt*0.1) })
		}
	}

	var rotate = ()=>{
		var co = [...contents];
		for(var i of co){
			var dx = i.x;
			i.x = i.y;
			i.y = dx;
			i.rotation = i.rotation===Rotation.LANDSCAPE ? Rotation.PORTRAIT : Rotation.LANDSCAPE;
		}
		setContents(co);
		setSize({ ...size, rotation: size.rotation===Rotation.LANDSCAPE ? Rotation.PORTRAIT : Rotation.LANDSCAPE });
	}

	var getPreviewUrl = ()=>{
		return `http://api.labelary.com/v1/printers/${Math.ceil(PRINTER_DPI*0.0393701)}dpmm/labels/${size.width}x${size.height}/0/${encodeURIComponent(generateZPL(false, true))}`;
	}

	var onSelected = (ix: number)=>{
		setSelected(ix);
	}

	var onContentChange = (k: keyof TicketItem, semantic: boolean=false)=>{
		if(selected===null) return null;
		return (e: { target: { value: string } } | any, data?: { value?: any, checked: boolean } | any)=>{
			var c = ([...contents] as any[]);
			if(!c[selected]) return;
			c[selected][k] = semantic ? (data.checked || data.value) : e;
			setContents(c);
		}
	}

	var startAdding = (type: ContentType)=>{
		return ()=>{
			setAdding(type);
			setSelected(null);
		}
	}

	var onAdd = (x: number, y: number)=>{
		if(!adding) return;
		var c = [...contents];
		var len = c.push({
			x, y,
			type: adding,
			content: adding===ContentType.TEXT ? 'Nuevo texto' : null,
			size: adding===ContentType.TEXT ? 30 : 3,
			rotation: 0,
			width: 30,
			height: 30,
		});
		setContents(c);
		setAdding(null);
		setSelected(len-1);
	}

	var rotateContent = ()=>{
		if(selected===null || !contents[selected]) return;
		var co = [...contents];
		if(co[selected].type===ContentType.BOX){
			var w = co[selected].width;
			co[selected].width = co[selected].height;
			co[selected].height = w;
		}else{
			if(co[selected].rotation==2){
				co[selected].rotation = 0;
			}else{
				co[selected].rotation++;
			}
		}
		setContents(co);
	}

	var deleteContent = ()=>{
		if(selected===null || !contents[selected]) return;
		var co = [...contents];
		co.splice(selected, 1);
		setSelected(null);
		setContents(co);
	}

	var cloneContent = ()=>{
		if(selected===null || !contents[selected]) return;
		var co = [...contents];
		var clone = { ...co[selected] };
		clone.x += clone.width+5;
		var len = co.push(clone);
		setContents(co);
		setSelected(len-1);
	}
	
	var printTest = async (setLoading: SetLoading)=>{
		setLoading(true);

		try{
			await printZPL(formatZplTemplate(generateZPL(false, false), getPlaceholderData()));
		}catch(e){
			alert('Hubo un error inesperado enviando la prueba (LCL-1)');
		}finally{
			setLoading(false);
		}
	}
	
	var onSizeChange = bindFormChange(size, setSize);
	var onTemplateChange = bindFormChange(template, setTemplate);
	var selcontent = selected!==null && contents[selected] ? contents[selected] : null

	return <div>
		<div className='ar creator'>
			<Toolbar style={{ marginBottom: 10, maxWidth: 1000 }} items={[
				{ icon: 'plus-circle', text: 'Agregar', items: [
					{ text: 'Texto', 				icon: 'font', onClick: startAdding(ContentType.TEXT) },
					{ text: 'Rectangulo', 		icon: 'square', onClick: startAdding(ContentType.BOX) },
					{ text: 'Código QR', 		icon: 'qrcode', onClick: startAdding(ContentType.QRCODE) },
					// { text: 'Código Barras', 	icon: 'barcode', onClick: startAdding(ContentType.BARCODE) },
				]},
				// { icon: 'redo', text: 'Rotar boleto', className: 'icon', onClick: rotate },
				{ icon: 'magnifying-glass-minus', className: 'icon', onClick: zoom(-1) },
				{ icon: 'magnifying-glass-plus', className: 'icon', onClick: zoom(1) },
				'space',
				{ icon: 'print', text: 'Probar',  onClick: ()=>setPrintModal(true) },
				{ icon: 'save', text: 'Guardar',  onClick: ()=>setConfirmModal(true) }
			]} />
			<div className="contents">
				<Groupper title='Template' className='compact'>
					{/* <Input label='Nombre template' value={template.template_name} onChange={onTemplateChange('template_name')} /> */}
					<Field amount={2} label='Tamaño (pulgadas)'>
						<Input placeholder='Ancho' value={size.width} onChange={onSizeChange('width')} />
						<Input placeholder='Alto' value={size.height} onChange={onSizeChange('height')} />
					</Field>
					{selected!==null && selcontent && <>
						<div className="section head">Opciones</div>
						<Toolbar style={{ marginBottom: 10, boxShadow: 'none' }} items={[
							{ icon: 'undo', className: 'icon', onClick: rotateContent },
							{ icon: 'copy', className: 'icon', onClick: cloneContent },
							'space',
							{ icon: 'trash', className: 'icon', onClick: deleteContent }
						]} />
						<Field amount={2} label='Posición'>
							<Input placeholder='X' value={selcontent.x} onChange={onContentChange('x')} />
							<Input placeholder='Y' value={selcontent.y} onChange={onContentChange('y')} />
						</Field>
						{(selcontent.type===ContentType.TEXT || selcontent.type===ContentType.QRCODE) ? <>
							<Field label="Tipo de texto">
								<Dropdown selection placeholder='Tipo de texto' value={selcontent.content_key} onChange={onContentChange('content_key', true)} options={getTicketDataOptions()} />
							</Field>
							{!selcontent.content_key && (
								<Input label='Texto' value={selcontent.content} onChange={onContentChange('content')} />
							)}
							<Field label='Tamaño'>
								<Dropdown placeholder='Tamaño' value={selcontent.size} selection onChange={onContentChange('size', true)} options={(
									selcontent.type===ContentType.TEXT ? new Array(20).fill(0).map((a,i)=>10+(i*5)) :
									(selcontent.type===ContentType.QRCODE || selcontent.type===ContentType.BARCODE) ? new Array(10).fill(0).map((a,i)=>i+1) :
									[]
								).map(a=>({ value: a, text: a.toString() }))} />
							</Field>
						</> : selcontent.type===ContentType.BOX ? <>
							<Field amount={2} label='Tamaño'>
								<Input placeholder='Ancho' value={selcontent.width} onChange={onContentChange('width')} />
								<Input placeholder='Alto' value={selcontent.height} onChange={onContentChange('height')} />
							</Field>
							<Checkbox label={'Rectangulo solido'} onChange={onContentChange('inverted', true)} checked={selcontent.inverted} />
						</> : null}
					</>}
				</Groupper>
				<div className="viewport" id="zplclear" onClick={(e)=>{
					if((e.target as any).id==='zplclear'){
						if(!adding) setSelected(null);
					}
				}}>
					<ZPLRender
						contents={contents}
						selected={selected}
						onContentsChange={setContents}
						onContentSelected={onSelected}
						scale={size.scale} 
						width={size.rotation==Rotation.LANDSCAPE ? size.height : size.width} 
						height={size.rotation===Rotation.LANDSCAPE ? size.width : size.height} 
						rotation={size.rotation} 
						adding={!!adding}
						onAdd={onAdd}
					/>
				</div>
			</div>
		</div>
		<Modal open={confirmModal} onClose={bindClose(setConfirmModal)} size='tiny'>
			<Modal.Header>Confirmación</Modal.Header>
			{confirmModal && (
				<Modal.Content style={{ position: 'relative' }}>
					<Header text='Confirmación de template' subtext='A continuación se muestra una vista previa mas cercana a la vista final del boleto. Favor de confirmar el template del boleto' />
					<img style={{ height: 300, display: 'block', margin: 'auto', objectFit: 'contain', borderRadius: 8, border: '1px solid #CCC' }} src={getPreviewUrl()} />
				</Modal.Content>
			)}
			<div style={{ padding: '0px 20px 5px 20px' }}>
				<Input label='Nombre template' value={template.template_name} onChange={onTemplateChange('template_name')} />
				<Input label='Descripción' value={template.description} onChange={onTemplateChange('description')} textarea />
				<Input label='Código ZPL' readonly value={generateZPL(true, false)} onFocus={e=>{ e.target.select() }} textarea />
			</div>
			<Modal.Actions>
				<Button text='Cancelar' basic onClick={bindClose(setConfirmModal)} />
				<Button text={props.create ? 'Crear' : 'Guardar'} color='black' />
			</Modal.Actions>
		</Modal>
		<Modal open={printModal} onClose={bindClose(setPrintModal)} size='tiny'>
			<Modal.Header>Imprimir prueba</Modal.Header>
			<Modal.Content>
				<Input readonly icon='print' inputStyle={{ fontStyle: settings?.printer ? 'normal' : 'italic' }} value={settings?.printer || 'Sin configuración'} button={
					<Button text='Configurar' onClick={configurePrinter} />
				} />
				{/* <Input label='URL' value={printerConfig.url} onChange={onPrinterConfigChange('url')} />
				<Input label='Código' value={printerConfig.code} onChange={onPrinterConfigChange('code')} />
				<Input label='Impresora' value={printerConfig.printer} onChange={onPrinterConfigChange('printer')} /> */}
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={bindClose(setPrintModal)} />
				<Button text='Imprimir' color='black' onClick={printTest} />
			</Modal.Actions>
		</Modal>
	</div>
}

export default TicketCreator;