import React, { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'semantic-ui-react';
import { AccountOwnerType, BankAccount } from '../AdminClasses';
import { Button, Field, Header, Input, Message } from 'react-frontier';
import { DepositType, SetLoading } from '@arema/components/Classes';
import { bindFormChange, formatCreditCard, getBankParticipants, validateClabeParticipant } from '@arema/components/Util';
import { clabe } from 'clabe-validator';
import Validator from '@arema/components/Validator';
import API from '../API';

interface BankAccountForm{
	type: DepositType,
	email: string,
	account_owner: string
	clabe: string,
	card_number: string,
	bank_participant: number,
	rfc: string,
	postal_code: string,
	owner_type: AccountOwnerType,
}

interface ModalProps{
	open: boolean,
	onClose: ()=>void,
	promoter: number,
	onCreate: (account: BankAccount)=>void,
	account_id?: string,
}

var BankAccountModal = (props: ModalProps)=>{
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [loading, setLoading] = useState(false);
	var [form, setForm] = useState<BankAccountForm>({
		type: null,
		email: '',
		account_owner: '',
		clabe: '',
		card_number: '',
		bank_participant: null,
		rfc: '',
		postal_code: '',
		owner_type: null,
	});
	let account_id = props.account_id || null
	const IS_CREATE = account_id === null;

	useEffect(()=>{
		if(props.open && !IS_CREATE && !Number.isNaN(parseInt(account_id))){
			loadData(parseInt(account_id));
		}
		if(IS_CREATE){
			setLoading(false);
		}
	}, [props.open]);

	const loadData = async (account_id: number) => {
		setLoading(true);
		API.getPromoterBankAccount(account_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			const data = res.data;
			setForm({
				owner_type: data.postal_code ? AccountOwnerType.FISICA : AccountOwnerType.MORAL,
				type: data.clabe ? DepositType.SPEI : DepositType.CARD,
				email: data.email_account,
				account_owner: data.name_account,
				clabe: data.clabe || '',
				card_number: data.card_number || '',
				bank_participant: data.bank_participant,
				rfc: data.rfc,
				postal_code: data.postal_code,
			});
		}).catch(err=>{
			setModalPrompts(['Hubo un error cargando la cuenta (LCL-3)']);
		}).finally(()=>{
			setLoading(false);
		});
	}

	var submit = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(form, {
			email: [{ rule: 'email', prompt: 'El correo electrónico no es válido' }],
			type: [{ rule: 'empty', prompt: 'Favor de seleccionar el tipo de cuenta bancaria.' }],
			account_owner: [{ rule: 'minLength', params: [3], prompt: `Favor de ingresar el propietario de ${form.type===DepositType.CARD ? 'la tarjeta' : 'la cuenta'}` }],
			...(form.owner_type===AccountOwnerType.FISICA ? {
				postal_code: [{ rule: 'minLength', params: [5] }, 'number'],
			} : {}),
			...(form.type===DepositType.CARD ? {
			
				// Campos tarjeta bancaria
				card_number: [{ rule: 'creditcard' }],
				bank_participant: [{ rule: 'empty', prompt: 'Favor de seleccionar el banco de la tarjeta' }],

			} : form.type===DepositType.SPEI ? {
				
				// Campos SPEI
				clabe: [{ 
					rule: v=>{
						var val = clabe.validate(v);
						return val && val.ok;
					}, 
					prompt: 'La CLABE no es válida.' 
				}, {
					skipEmpty: true,
					rule: validateClabeParticipant,
					prompt: 'La CLABE no pertenece a un banco válido.'
				}]
				
			} : {})
		});

		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		if (IS_CREATE) {
			API.createPromoterBankAccount(props.promoter, form).then(res=>{
				if(res.error) return setModalPrompts([res.message]);
				props.onCreate(res.data);
			}).catch(err=>{
				return setModalPrompts(['Hubo un error creando la cuenta bancaria. (LCL-1)']);
			}).finally(()=>{
				setLoading(false);
			});
		} else {
			API.updatePromoterBankAccount({
				account_id: parseInt(account_id),
				...form
			}).then(res=>{
				if(res.error) return setModalPrompts([res.message]);
				props.onCreate(res.data);
			}).catch(err=>{
				return setModalPrompts(['Hubo un error actualizando la cuenta bancaria. (LCL-2)']);
			}).finally(()=>{
				setLoading(false);
			});
		}
	}

	useEffect(()=>{
		setForm({
			type: null,
			email: '',
			account_owner: '',
			clabe: '',
			card_number: '',
			bank_participant: null,
			rfc: '',
			postal_code: '',
			owner_type: null,
		});
	}, [props.open]);

	var onDataChange = bindFormChange(form, setForm);
	
	return <Modal open={props.open} onClose={props.onClose} size='tiny'>
		<Modal.Header>{account_id===null ? 'Agregar' : 'Editar'} cuenta bancaria</Modal.Header>
		<Modal.Content>
			{loading ? (
				<Header loading text='Cargando' />
			) : <>
				<Field label='Tipo de propietario'>
					<Dropdown selection placeholder='Tipo de propietario' value={form.owner_type} onChange={(e, data)=>{
						setForm({
							...form,
							owner_type: (data.value as number),
							postal_code: ''
						});
					}} options={[
						{ text: 'Persona fisica', value: AccountOwnerType.FISICA },
						{ text: 'Persona moral', value: AccountOwnerType.MORAL }
					]} />
				</Field>
				<Input label='Correo electrónico' value={form.email} onChange={onDataChange('email')} />
				<Field label='Tipo de cuenta'>
					<Dropdown selection placeholder='Tipo de cuenta' value={form.type} onChange={onDataChange('type', true)} options={[
						{ text: 'Transferencia bancaria (SPEI)', value: DepositType.SPEI },
						{ text: 'Tarjeta bancaria', value: DepositType.CARD }
					]} />
				</Field>
				{form.type===DepositType.SPEI ? (
					<>
						<Input label='Propietario de cuenta' value={form.account_owner} onChange={onDataChange('account_owner')} />
						<Field amount={2}>
							<Input label='CLABE' value={form.clabe} onChange={onDataChange('clabe')} />
							<Input label='Banco' readonly value={clabe.validate(form.clabe).bank} />
						</Field>
					</>
				) : form.type===DepositType.CARD ? (
					<>
						<Input label='Nombre en la tarjeta' value={form.account_owner} onChange={onDataChange('account_owner')} />
						<Input label="Número de la tarjeta" value={form.card_number} onChange={onDataChange('card_number')} valueFormat={formatCreditCard} maxLength={16+3} />
						<Field label='Banco'>
							<Dropdown search selection selectOnBlur={false} value={form.bank_participant} onChange={onDataChange('bank_participant', true)} placeholder='Banco de la tarjeta' options={getBankParticipants().map((a,i)=>({
								text: a.bank_name,
								value: a.participant,
								key: `${a.participant}-${i}`
							}))} />
						</Field>
						<Message type='info' style={{ marginBottom: 10, marginTop: 15 }}>
							<ul>
								<li>Para evitar cualquier tipo de problema, <b>favor de ingresar solo tarjetas de débito</b>.</li>
								<li>Por el momento solo podemos hacer reembolsos a <b>tarjetas de instituciones bancarias mexicanas</b>.</li>
							</ul>
						</Message>
					</>
				) : null}
				<Input label='RFC' onChange={onDataChange('rfc')} value={form.rfc} />
				{form.owner_type===AccountOwnerType.FISICA && (
					<Input label='Código postal' onChange={onDataChange('postal_code')} value={form.postal_code} />
				)}
				<Message style={{ marginTop: 15 }} type='error' list={modalPrompts} />
			</>}
		</Modal.Content>
		<Modal.Actions>
			<Button basic text='Cerrar' onClick={props.onClose} />
			<Button color="black" text='Guardar' onClick={submit} />
		</Modal.Actions>
	</Modal>
}

export default BankAccountModal;