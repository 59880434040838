import React, { useEffect, useRef, useState } from 'react';
import { Button, Header, Input, Pagination, Table } from 'react-frontier';
import { CatalogEvent, EventDate, SetLoading } from '@arema/components/Classes';
import { Icon, Modal } from 'semantic-ui-react';
import { CDN_URL, PLACEHOLDER_IMG } from '../AdminConfig';
import moment from 'moment';
import API from '../API';
import classNames from 'classnames';

interface SearchModalProps{
	open: boolean,
	onClose: ()=>void,
	title?: string,
	onEventSelected?: (event: CatalogEvent)=>boolean;
	onDateSelected?: (event: CatalogEvent, date: EventDate)=>boolean;
	pageSize?: number,
	date?: boolean,
}

var SearchEventModal = (props: SearchModalProps)=>{
	var [searching, setSearching] = useState<boolean>(false);
	var [searchInput, setSearchInput] = useState<string>('');
	var [searchResults, setSearchResults] = useState<CatalogEvent[]>(null);
	var [searchError, setSearchError] = useState<string>(null);
	var [sentQuery, setSentQuery] = useState<string>(null);
	var [page, setPage] = useState<number>(0);
	var [dates, setDates] = useState<EventDate[]>(null);
	var [selectedEvent, setSelectedEvent] = useState<CatalogEvent>(null);
	var inputRef = useRef<HTMLInputElement>(null);
	const PAGE_SIZE = (props.pageSize || 15);

	useEffect(()=>{
		if(props.open){
			setSearchInput('');
			inputRef.current.focus();
		}
	}, [props.open]);

	var searchEvent = (setLoading: SetLoading, page?: number)=>{
		if(searching) return;
		setSearching(true);
		setSentQuery(searchInput);
		setPage(page || 0);
		setSearchError(null);
		API.searchEvent(searchInput, page*PAGE_SIZE, PAGE_SIZE).then(res=>{
			if(res.error) return setSearchError(res.message);
			setSearchResults((res.data as any[]) as CatalogEvent[]);
		}).catch(err=>{
			setSearchError('Hubo un error inesperado buscando los eventos (LCL-1)');
		}).finally(()=>{
			setSearching(false);
		});
	}

	var viewCalendarios = (event_id: number)=>{
		setSearching(true);
		API.getEventDates(event_id).then(res=>{
			if(res.error) return setSearchError(res.message);
			setDates(res.data);
		}).catch(err=>{
			setSearchError('Hubo un error buscando los calendarios de el evento.');
		}).finally(()=>{
			setSearching(false);
		})
	}

	var eventSelected = (event: CatalogEvent)=>{
		return ()=>{
			if(props.date){
				viewCalendarios(event.event_id);
				setSelectedEvent(event);
			}else{
				if(!props.onEventSelected) return;
				var done = props.onEventSelected(event);
				if(done!==false){
					props.onClose();
				}
			}
		}
	}

	var dateSelected = (date_id: number)=>{
		if(!props.onDateSelected) return;
		var date = dates.find(a=>a.date_id===date_id);
		if(!date) return;
		var done = props.onDateSelected(selectedEvent, date);
		if(done!==false){
			props.onClose();
		}
	}

	var onPageChange = (page: number)=>{
		setPage(page);
		searchEvent(null, page);
	}
	
	var hasPagination = (searchResults?.length || 0)>=PAGE_SIZE || page!=0;
	
	return <Modal open={props.open} onClose={props.onClose} size='tiny'>
		<Modal.Header>{props.title || (!selectedEvent ? 'Buscar evento' : 'Seleccionar calendario')}</Modal.Header>
		<Modal.Content style={{ paddingBottom: hasPagination ? 0 : 20 }}>
			{!selectedEvent ? (
				<Input ref={inputRef} value={searchInput} onChange={setSearchInput} placeholder='Buscar registros' button={(
					<Button text='Buscar' onClick={searchEvent} loading={searching} />
				)} onSubmit={searchEvent} icon='search' submitOnEnter />
			) : (
				<Input value={selectedEvent.event_name} icon='calendar' readonly button={(
					<Button text='Regresar' onClick={()=>setSelectedEvent(null)} color='black' />
				)} />
			)}
			{searching ? (
				<Header loading text={`Cargando ${selectedEvent ? 'calendarios' : 'eventos'}`} containerStyle={{ marginBottom: 50, marginTop: 50 }} />
			) : searchError ? (
				<Header text='Error' subtext={searchError} iconName='face-frown-open' style={{ marginTop: 30, marginBottom: 30 }} size='small' />
			) : !searchResults ? (
				null
			) : searchResults.length===0 ? (
				<Header text='Eventos no encontrados' subtext={`No se encontraron eventos con "${sentQuery}"`} iconName='face-frown-open' style={{ marginTop: 30, marginBottom: 30 }} size='small' />
			) : !selectedEvent ? (
				<table className="fr fitted selectable first bottom round striped divided table" style={{ marginTop: 20 }}>
					<thead>
						<tr>
							<th className='centered'><i className="image icon"></i></th>
							<th>ID</th>
							<th>Evento</th>
							<th>Fecha</th>
						</tr>
					</thead>
					<tbody>
						{searchResults.map(a=>(
							<tr key={`SMR-${a.event_id}`} onClick={eventSelected(a)}>
								<td className='collapsing' style={{ padding: 5 }}>
									<img src={`${CDN_URL}/events/${a.event_id}/200.webp`} style={{ width: 40, height: 40, borderRadius: 4, marginBottom: -5 }} onError={e=>(e.target as any).src=PLACEHOLDER_IMG} />
								</td>
								<td className='collapsing'>{a.event_id}</td>
								<td>{a.event_name}</td>
								<td className='collapsing centered'>
									{
										a.date ? (
											<>
												<div style={{ fontSize: 12, color: 'gray' }}>Próxima fecha</div>
												{moment.unix(a.date).format('DD/MMM/YYYY')}
											</>
										) : <Icon color='grey' name='minus' />
									}
								</td>
							</tr>
						))}
					</tbody>
					{hasPagination && (
						<tfoot>
							<tr>
								<td colSpan={4} style={{ padding: 10 }}>
									<div style={{ justifyContent: 'flex-end', display: 'flex' }}>
										<Pagination page={page} disabled={searching} onPageChange={onPageChange} hasNext={searchResults.length>=PAGE_SIZE} />
									</div>
								</td>
							</tr>
						</tfoot>
					)}
				</table>
			) : (
				<Table
					fitted
					selectable
					striped
					divided
					style={{ marginTop: 20 }}
					className='first bottom round'
					headers={['ID', 'Fecha', 'Recinto', 'Ciudad', <i className='eye icon'></i>]}
					collapsingIndexes={[0, 2, 3, 4]}
					emptyText='No se encontraron fechas para este evento.'
					onClick={v=>dateSelected(v[0])}
					data={dates.sort((a,b)=>a.date-b.date).map(a=>([
						a.date_id, moment.unix(a.date).format('DD/MMM/YY HH:mm'), a.venue_name, a.city,
						<i className={classNames('icon', {
							'remove grey': !a.available_web && !a.available_pdv,
							check: a.available_web || a.available_pdv,
							green: a.available_web && a.available_pdv,
							orange: (!a.available_web || !a.available_pdv) && !(!a.available_web && !a.available_pdv),
						})}></i>
					]))}
				/>
			)}
		</Modal.Content>
	</Modal>
}

export default SearchEventModal;