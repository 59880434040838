import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Field, Groupper, Header, Input, Message, Toolbar } from "react-frontier";
import { Location, SetLoading, PDV } from "@arema/components/Classes";
import { PLACES_KEY } from "../../AdminConfig";
import { bindClose, bindFormChange, checkLocation, formatPlacesComponents } from "@arema/components/Util";
import { DeletedBanner, LocationView, NotFound } from "../../components";
import { Modal } from "semantic-ui-react";
import { QRCodeSVG } from "qrcode.react";
import { useUser } from "../../AdminHooks";
import Toast from 'react-hot-toast';
import Autocomplete from "react-google-autocomplete";
import API from "../../API";
import Validator from "@arema/components/Validator";
import UserAccess from "../../UserAccess";

interface Props {
	pdv_id?: string,
	pdv?: PDV,
}

enum Modals{
	REGISTER_TOKEN = 1,
	RESET_TOKEN = 2,
}

const PDVCreate = (props: Props) => {
	const navigate = useNavigate()
	let pdv_id = props.pdv_id;
	var IS_CREATE = !props.pdv;
	var { user, hasAccess } = useUser();
	var [pdv, setPdv] = useState<PDV>(null);
	var [editLocation, setEditLocation] = useState<boolean>(false);
	var [newLocation, setNewLocation] = useState<Location>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [shownModal, setShownModal] = useState<Modals>(null);
	var [modalConfirm, setModalConfirm] = useState<boolean>(false);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);

	useEffect(()=>{
		setPdv(props.pdv || {
			pdv_name: '',
			location: null,
			token: null,
		});
	}, [props.pdv]);

	const submit = async (setLoading: SetLoading) => {
		var body: any = { 
			pdv_id: null,
			pdv_name: pdv.pdv_name,
			location: pdv.location,
		}
		if(IS_CREATE || newLocation){
			body.location = newLocation;
		}
		var { valid, prompts } = Validator(body, {
			pdv_name: [{
				rule: 'length', params: [4, 64], label: 'Nombre'
			}],
			...((IS_CREATE || newLocation) ? {
				location: [{ rule: checkLocation, label: 'Ubicación', prompt: 'Ubicacion no valida' }]
			} : {})
		});
		setErrorPrompts(prompts);
		if(!valid) return;

		if(!IS_CREATE){
			body.pdv_id = parseInt(pdv_id);
		}

		setLoading(true);
		if(IS_CREATE){
			API.createPDV(body.pdv_name, body.location).then(res=>{
				if(res.error) return setErrorPrompts([res.message]);
				Toast.success('Se ha creado el punto de venta');
				navigate(`/pdv/${res.data.pdv_id}`);
			}).catch(err=>{
				setErrorPrompts(['Hubo un error inesperado creando el Punto de Venta (LCL-10)']);
			}).finally(()=>{
				setLoading(false);
			});
		}else{
			API.editPDV(body.pdv_id, body.pdv_name, body.location).then(res=>{
				if(res.error) return setErrorPrompts([res.message]);
				if(newLocation){
					var vn = {...pdv};
					vn.location = {...newLocation};
					setPdv(vn);
					setEditLocation(false);
					setNewLocation(null);
				}
				Toast.success('Se ha editando el punto de venta.');
			}).catch(err=>{
				setErrorPrompts(['Hubo un error inesperado editando el punto de venta (LCL-11)']);
			}).finally(()=>{
				setLoading(false);
			});
		}
	}

	const onLocationSelected = (location: Location)=>{
		if(!location) return;
		setEditLocation(false);
		setNewLocation(location);
	}

	var changeLocation = ()=>{
		setEditLocation(true);
		setNewLocation(null);
	}

	var sendReinitialize = (setLoading: SetLoading)=>{
		setLoading(true);
		API.reinitializePdv(pdv.pdv_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			setPdv({
				...pdv,
				token: res.data.token
			});
			Toast.success('Se ha reinicializado el PDV. Se desconectaron los PDV activos con este token.');
			setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado reinicializando el PDV. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	}

	if(!IS_CREATE && Number.isNaN(parseInt(pdv_id))){
		return <NotFound />
	}

	if(!IS_CREATE && !pdv){
		return <Header text="Cargando punto de venta" loading />
	}

	const onChangeField = bindFormChange(pdv, setPdv);

	return (
		<div>
			{!!pdv?.deleted ? <DeletedBanner date_deleted={pdv.date_deleted} /> : null}
			{pdv &&
				<Groupper title={IS_CREATE ? 'Crear Punto de Venta' : `Editar Punto de Venta`} width={500} actions={(
					<Button text="Guardar" color="black" onClick={submit} />
				)}>
					{!IS_CREATE && (
						<Toolbar fitted>
							<Toolbar.Item icon="qrcode" text="Ver token" onClick={()=>setShownModal(Modals.REGISTER_TOKEN)} if={hasAccess(UserAccess.PDV.VIEW_PDV_TOKEN)} />
							<Toolbar.Item icon="refresh" text="Reinicializar" onClick={()=>setShownModal(Modals.RESET_TOKEN)} if={hasAccess(UserAccess.PDV.VIEW_PDV_TOKEN)} />
						</Toolbar>
					)}
					<Input 
						label="Nombre" 
						placeholder="Nombre del punto de venta" 
						value={pdv.pdv_name}
						onChange={onChangeField('pdv_name')} 
					/>

					{!editLocation && (newLocation || pdv?.location) ? (
						<LocationView location={newLocation || pdv?.location} onButtonClick={changeLocation} />
					) : (
						<Field label="Ubicación">
							<Autocomplete 
								apiKey={PLACES_KEY} 
								options={{
									fields: ["formatted_address", "geometry", "name", "address_components", "place_id"],
									strictBounds: false,
									types: ["establishment"],
									componentRestrictions: { country: 'mx' }
								}}
								onPlaceSelected={place=>{
									var loc = null;
									try{
										loc = formatPlacesComponents(place);
									}catch(e){}
									if(!loc) return Toast.error('Hubo un error inesperado seleccionando la ubicación.');
									onLocationSelected(loc);
								}}
							/>
						</Field>
					)}
					<Message list={errorPrompts} type="error" style={{ marginTop: 15 }} />
				</Groupper>
			}
			{!IS_CREATE && hasAccess(UserAccess.PDV.VIEW_PDV_TOKEN) && (
				<Modal open={shownModal===Modals.REGISTER_TOKEN} size='mini' onClose={bindClose(setShownModal)}>
					<Modal.Header>Token de registro</Modal.Header>
					<Modal.Content>
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
							<div style={{ marginBottom: 10, fontWeight: 'bold', maxWidth: 300, textAlign: 'center' }}>
								Este token se usará para registrar el PDV en la plataforma.
							</div>
							<QRCodeSVG value={`${pdv.pdv_id}v${pdv.token}`} width={300} />
							<div className='fr mono' style={{ fontSize: 28, fontWeight: 'bold', marginTop: 10 }}>
								{`${pdv.pdv_id}v${pdv.token}`}
							</div>
							<div style={{ marginTop: -5, color: 'gray' }}>
								Código: {user.admin_id}
							</div>
						</div>
					</Modal.Content>
					<Modal.Actions>
						<Button text='Cerrar' basic onClick={bindClose(setShownModal)} />
					</Modal.Actions>
				</Modal>
			)}
			{!IS_CREATE && hasAccess(UserAccess.PDV.RESET_PDV) && (
				<Modal open={shownModal===Modals.RESET_TOKEN} size='tiny' onClose={bindClose(setShownModal)}>
					<Modal.Header>Reinicializar PDV</Modal.Header>
					<Modal.Content>
						<Header text='¿Reinicializar PDV?' style={{ marginBottom: 20 }} />
						<Message type='info' centered>
							Esto desconectará cualquier PDV que se haya registrado con este token. Los PDV desconectados tendrán que registrarse con un token nuevamente.
						</Message>
						<Checkbox checked={!!modalConfirm} onChange={bindClose(setModalConfirm, true)} label={'Deseo reinicializar el PDV'} style={{ marginTop: 20 }} />
						<Message list={modalPrompts} type='error' />
					</Modal.Content>
					<Modal.Actions>
						<Button text='Cerrar' basic onClick={bindClose(setShownModal)} />
						<Button text='Continuar' color='black' disabled={!modalConfirm} onClick={sendReinitialize} />
					</Modal.Actions>
				</Modal>
			)}
		</div>
	)
}

export default PDVCreate;