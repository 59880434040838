import React, { useState, useEffect, useRef } from "react";
import API, { EventQL } from "../../API";
import { Header, Button, Field, Groupper, Input, Message } from 'react-frontier';
import { Dropdown, Icon, Modal } from "semantic-ui-react";
import { bindClose, bindSemantic } from "@arema/components/Util";
import { CDN_URL } from "../../AdminConfig";
import { ShortUserAdmin, EventData, EventSectionProps, SetLoading, Zone } from "@arema/components/Classes";
import { Link } from "react-router-dom";
import { DeletedBanner, SearchAdminModal, SearchZoneModal } from "../../components";
import { useUser } from "../../AdminHooks";
import MDEditor, { commands } from '@uiw/react-md-editor';
import UserAccess from "../../UserAccess";
import Validator from "@arema/components/Validator";
import Toast from "react-hot-toast";

import '../../style/markdownedit.scss';

const styles = {
	placeholderPoster: {
		height: 308, 
		alignItems: 'center', 
		display: 'flex', 
		justifyContent: 'center', 
		padding: 15, 
		backgroundColor: '#f0f0f0',
		borderRadius: 8, 
		width: 308
	},
	poster: {
		width: 308,
		height: 308,
		borderRadius: 8
	}
}

enum ShownModal{
	EDIT_MODAL = 1,
	PUBLISH_MODAL = 2,
	RESPONSABLE_MODAL = 3,
}

var EventContainer = (props: EventSectionProps)=>{
	var { user, hasAccess } = useUser();
	var posterInputRef = useRef<HTMLInputElement>(null);
	var [event, setEvent] = useState<EventData>(props.event);
	var [eventMeta, setEventMeta] = useState<{[x in EventQL]: any[]}>(null);
	var [status, setEventStatus] = useState<string[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [sinopsis, setSinopsis] = useState<string>(props.event.sinopsis);
	var [missingPoster, setMissingPoster] = useState<boolean>(false);
	var [shownModal, setShownModal] = useState<ShownModal>(null);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [posterTime, setPosterTime] = useState<number>(new Date().getTime());
	var [loadingPoster, setLoadingPoster] = useState<boolean>(false);

	var [eventName, setEventName] = useState<string>(props.event.event_name);
	var [eventType, setEventType] = useState<number>(props.event.event_type);
	var [promoter, setPromoter] = useState<number>(props.event.promoter_id);
	var [category, setCategory] = useState<number>(props.event.category_id);
	var [zone, setZone] = useState<Zone>({zone_name: props.event.zone_name, zone_id: props.event.zone_id });
	const [shownZoneModal, setShownZoneModal] = useState<boolean>(false)

	useEffect(()=>{
		if(!status){
			API.getEventStatus(props.event.event_id).then(res=>{
				if(res.error) return setLoadError(res.message);
				setEventStatus(res.data);
			}).catch(err=>{
				setLoadError('Hubo un error inesperado cargando la información (LCL-2)');
			})
		}
	}, []);

	if(!status || loadError){
		return <Header loading text={loadError ? "Error" : "Cargando evento"} subtext={loadError} iconName={loadError ? 'face-frown-open' : null} />
	}

	var uploadPoster = ()=>{
		posterInputRef.current?.click();
	}

	var handlePoster = (ev: React.ChangeEvent<HTMLInputElement>)=>{
		if(!ev.target.files || ev.target.files.length==0) return;
		if(ev.target.files.length>1) return Toast.error("Favor de solo seleccionar 1 archivo.");
		var image = ev.target.files[0];
		if(image.size>(2.5*1024*1024)) return Toast.error('El poster no puede pesar mas de 2.5MB.');
		setLoadingPoster(true);
		API.setEventPoster(props.event.event_id, image).then(res=>{
			if(res.error) return Toast.error(res.message);
			setMissingPoster(false);
			setPosterTime(new Date().getTime());
			Toast.success('Se ha cambiado el poster del evento. Puede tomar un rato en propagarse el cambio.', {
				duration: 10000
			})
		}).catch(err=>{
			return Toast.error('Hubo un error subiendo el poster del evento. (LCL-3)');
		}).finally(()=>{
			setLoadingPoster(false);
		})
	}

	var editEvent = ()=>{
		setModalPrompts([]);
		setShownModal(ShownModal.EDIT_MODAL)
		setPromoter(event.promoter_id);
		setEventType(event.event_type);
		setCategory(event.category_id);
		if(!eventMeta){
			API.getEventsQL(['promoter', 'categories', 'types']).then(res=>{
				if(res.error) return Toast.error(res.message);
				setEventMeta(res.data);
			}).catch(err=>{
				setShownModal(null)
				return Toast.error('Hubo un error cargando los datos del evento. (LCL-4)');
			})
		}
	}

	var saveSinopsis = (setLoading: (s: boolean)=>void)=>{
		setLoading(true);
		API.saveSinopsis(props.event.event_id, sinopsis).then(res=>{
			if(res.error) return Toast.error(res.message);
			Toast.success('Sinopsis guardada');
		}).catch(err=>{
			return alert('Hubo un error guardando la sinopsis (LCL-5)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var saveEvent = (setLoading: (s: boolean)=>void)=>{
		var { valid, prompts, form } = Validator({
			event_name: eventName,
			promoter_id: promoter,
			category_id: category,
			event_type: eventType,
			zone_id: zone.zone_id,
		}, {
			event_name: {
				label: 'Nombre de evento',
				rules: [
					{ rule: 'minLength', params: [5], prompt: 'El nombre del evento debe de ser mínimo 5 caracteres.' },
					{ rule: 'maxLength', params: [64], prompt: 'El nombre del evento debe de ser máximo 64 caracteres.' },
					'empty'
				]
			},
			promoter_id: {
				label: 'Promotor',
				rules: ['number']
			},
			category_id: {
				label: 'Categoría',
				rules: ['number']
			},
			event_type: {
				label: 'Tipo de evento',
				rules: ['number']
			},
			zone_id: {
				label: 'Zona',
				rules: ['number']
			},
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.editEvent(props.event.event_id, form.event_name, form.promoter_id, form.event_type, form.category_id, form.zone_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha editado el evento');
			var ev = {...event};
			ev.event_name = form.event_name;
			ev.zone_id = zone.zone_id;
			ev.zone_name = zone.zone_name;
			var category = eventMeta.categories.find(a=>a.category_id==form.category_id);
			if(category){
				ev.category_id = category.category_id;
				ev.category_name = category.category_name;
			}

			var promoter = eventMeta.promoter.find(a=>a.promoter_id==form.promoter_id);
			if(promoter){
				ev.promoter_id = promoter.promoter_id;
				ev.promoter_name = promoter.promoter_name;
			}

			var eventType = eventMeta.types.find(a=>a.type_id==form.event_type);
			if(eventType){
				ev.event_type = eventType.type_id;
				ev.type_name = eventType.description;
			}

			setEvent(ev);
			props.onEditEvent(ev);
			setShownModal(null)
		}).catch(err=>{
			setModalPrompts(['Hubo un error editando el evento (LCL-6)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var showPublish = ()=>{
		setShownModal(ShownModal.PUBLISH_MODAL)
		setModalPrompts([]);
	}

	var publishEvent = (setLoading: SetLoading)=>{
		if(!hasAccess(UserAccess.EVENTS.PUBLISH_EVENT)) return;
		setLoading(true);
		setModalPrompts([]);
		API.setEventOptions(event.event_id, {
			published: true
		}).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			var ev = {...event};
			ev.published = true;
			setEvent(ev);
			setShownModal(null);
			Toast.success('Se ha publicado el evento!');
		}).catch(err=>{
			setModalPrompts(['Hubo un error publicando el evento. (LCL-7)']);
		}).finally(()=>{
			setLoading(false)
		});
	}

	var onResponsableChange = (admin: ShortUserAdmin)=>{
		var access = hasAccess(UserAccess.EVENTS.EDIT_USER_ASSIGNED);
		if(!access && admin.admin_id!==user.admin_id){
			return Toast.error('No tienes acceso a asignar otros usuarios como responsables.');
		}

		API.setEventUserAssigned(props.event.event_id, admin.admin_id).then(res=>{
			if(res.error) return Toast.error(res.message);
			var new_event = {
				...event,
				user_assigned: admin.admin_id,
				user_assigned_name: `${admin.first_name} ${admin.last_name}`,
				user_assigned_username: admin.username,
			};
			props.onEditEvent(new_event);
			setEvent(new_event);
			Toast.success(`Se ha asignado a "${admin.username}" como responsable.`);
			setShownModal(null);
		}).catch(err=>{
			Toast.error('Hubo un error inesperado cambiando al responsable del evento.');
		})
	}

	var onSelectZone = (zone: Zone) => {
		setZone(zone);
		setShownZoneModal(null);
	}

	return <div>
		{ !!event.deleted ? <DeletedBanner date_deleted={event.date_deleted} /> : null }
		{status && status.length>0 ? (
			<Message 
				type="error" 
				list={status} 
				header="Evento no publicado" 
				text="El evento no puede ser publicado ya que tiene información faltante."
				style={{ maxWidth: 600, margin: 'auto' }}
			/>
		) : (!status || status.length==0) && !event.published ? (
			<Message style={{ maxWidth: 400, margin: 'auto' }} centered type="info">
				<div className="header">Evento no publicado</div>
				El evento no es visible en la página publica
				{hasAccess(UserAccess.EVENTS.PUBLISH_EVENT) && (
					<Button text="Publicar" color="blue" onClick={showPublish} style={{ width: 200, display: 'block', margin: '10px auto 0 auto' }} />
				)}
			</Message>
		) : null}
		<Groupper style={{ maxWidth: 350, marginTop: 15, marginBottom: 15 }} title="Poster">
			{missingPoster ? (
				<div style={styles.placeholderPoster}>
					<Header text="Este evento no tiene un poster" subtext="Sube un poster para poder publicar el evento" size="small" />
				</div>
			) : (
				<img src={`${CDN_URL}/events/${props.event.event_id}/800.webp?t=${posterTime}`} style={styles.poster} onError={()=>setMissingPoster(true)} onLoad={()=>{
					setMissingPoster(false);
				}} />
			)}
			<input type="file" style={{ display: 'none' }} ref={posterInputRef} accept="image/*" onChange={handlePoster} />
			<div className="actions" style={{ textAlign: 'center' }}>
				<Button text="Subir poster" color={'black'} onClick={uploadPoster} loading={loadingPoster} />
			</div>
		</Groupper>
		<Groupper fitted width={350} style={{ marginBottom: 15 }} title="Responsable">
			{event.user_assigned ? (
				<Link to={`/admins/${event.user_assigned}`}>
					<Header 
						iconName="user-circle" 
						className="horizontal selectable" 
						style={{ margin: 10 }} 
						contentStyle={{ fontSize: 20, lineHeight: '24px'}} 
						subheaderStyle={{ fontSize: 12, lineHeight: '12px' }} 
						text={event.user_assigned_name} 
						subtext={event.user_assigned_username.toUpperCase()} 
					/>
				</Link>
			) : <div style={{ margin: 15 }}>
				<Header text="Sin responsable" size="small" subtext="Este evento no tiene un usuario asignado como responsable" />
				<Button text="Asignar usuario" size="tiny" color="black" style={{ width: 150, marginTop: 15, display: 'block', margin: 'auto' }} onClick={()=>setShownModal(ShownModal.RESPONSABLE_MODAL)} />
			</div>}
		</Groupper>
		<table className="fr details striped table" style={{ maxWidth: 600, margin: 'auto' }}>
			<thead>
				<tr>
					<th className="title" colSpan={2}>Detalles</th>
					<th style={{ textAlign: 'right' }}>
						<div className="fr tiny black button" onClick={editEvent}>
							<i className="pen icon"></i> Editar
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Estatus</td>
					<td colSpan={2}>
						<Icon name={props.event.published ? 'check' : 'minus'} color={props.event.published ? 'green' : 'grey'} />
						{props.event.published ? 'Publicado' : 'No publicado'}
					</td>
				</tr>
				<tr>
					<td>Nombre</td>
					<td colSpan={2}>{props.event.event_name}</td>
				</tr>
				<tr>
					<td>Tipo Evento</td>
					<td colSpan={2}>{props.event.type_name}</td>
				</tr>
				<tr>
					<td>Promotor</td>
					<td colSpan={2}>{props.event.promoter_name}</td>
				</tr>
				<tr>
					<td>Categoría</td>
					<td colSpan={2}>{props.event.category_name}</td>
				</tr>
				<tr>
					<td>Zona</td>
					<td colSpan={2}>{props.event.zone_name}</td>
				</tr>
			</tbody>
		</table>
		<Groupper style={{ maxWidth: 600, marginTop: 15 }} title="Sinopsis" fitted actions={(
			<Button text="Guardar" color='black' onClick={saveSinopsis} />
		)}>
			<div data-color-mode="light">
				<MDEditor preview="edit" value={(sinopsis || '')} onChange={setSinopsis} height={350} placeholder="Sinopsis del evento" extraCommands={[
					{
						...commands.codeEdit,
						icon: <Icon name='pencil' style={{ margin: 0, fontSize: 18 }} />
					},
					{
						...commands.codePreview,
						icon: <Icon name='eye' style={{ margin: 0, fontSize: 18 }} />
					},
				]} commands={[
					commands.bold,
					commands.italic,
					commands.strikethrough,
					commands.divider,
					commands.hr,
					commands.quote,
					commands.code,
					commands.divider,
					commands.unorderedListCommand,
					commands.orderedListCommand,
				]} />
			</div>
		</Groupper>
		<Modal open={shownModal===ShownModal.EDIT_MODAL} onClose={bindClose(setShownModal)} size={'tiny'}>
			<Modal.Header>Editar evento</Modal.Header>
			<Modal.Content>
				<Input label="Nombre del evento" value={eventName} onChange={setEventName} />
				{eventMeta ? (
					<div>
						<Field label="Promotor">
							<Dropdown selection search placeholder="Promotor del evento" value={promoter} onChange={bindSemantic(setPromoter)} options={eventMeta.promoter.map(a=>({
								value: a.promoter_id,
								text: a.promoter_name,
							}))} />
						</Field>
						<Field label="Tipo Evento">
							<Dropdown selection placeholder="Tipo de evento" value={eventType} onChange={bindSemantic(setEventType)} options={eventMeta.types.map(a=>({
								value: a.type_id,
								text: a.description,
							}))} />
						</Field>
						<Field label="Categoría">
							<Dropdown selection placeholder="Categoría del evento" value={category} onChange={bindSemantic(setCategory)} options={eventMeta.categories.map(a=>({
								value: a.category_id,
								text: a.category_name,
							}))} />
						</Field>
						<Field label="Zona">
							<Input 
								readonly
								placeholder="Zona del evento"
								value={zone.zone_name}
								button={<Button 
									icon 
									iconName='pencil' 
									style={{ padding: 5, width: 55, borderLeft: '1px solid #DDDDDD' }} 
									onClick={() => setShownZoneModal(true) }/>} 
							/>
						</Field>
					</div>
				) : (
					<Header loading text="Cargando datos" />
				)}
				<Message type="error" list={modalPrompts} />
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={bindClose(setShownModal)} text="Cerrar" basic />
				<Button color="black" text="Guardar" onClick={saveEvent} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===ShownModal.PUBLISH_MODAL} onClose={bindClose(setShownModal)} size="tiny">
			<Modal.Header>Publicar evento</Modal.Header>
			<Modal.Content>
				<Header text={`¿Publicar ${event.event_name}?`} subtext="El evento se mostrará en la página publica y se venderán boletos de los calendarios disponibles." />
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={bindClose(setShownModal)} text="Cerrar" basic />
				<Button onClick={publishEvent} text="Publicar" color="black" />
			</Modal.Actions>
		</Modal>
		<SearchAdminModal
			open={shownModal===ShownModal.RESPONSABLE_MODAL}
			onClose={bindClose(setShownModal)}
			onSelected={onResponsableChange}
		/>
		<SearchZoneModal
			open={shownZoneModal}
			onClose={() => setShownZoneModal(false)}
			onSelected={onSelectZone}
			title="Buscar Zona"
		/>
	</div>
}

export default EventContainer;