import React, { useState, useEffect } from "react";
import { Header, Table } from 'react-frontier';
import { Link } from "react-router-dom";
import { EventSectionProps } from "@arema/components/Classes";
import { addCommas } from "@arema/components/Util";
import API from "../../API";
import moment from "moment";

interface QuestionsEventDate {
	date_id: number,
	event_id: number,
	date: number,
	venue_name: string,
	city: string,
	questions_count: number
}

var EventFormsContainer = (props: EventSectionProps)=>{
	var [dates, setDates] = useState<QuestionsEventDate[]>(null);
	var [loadError, setLoadError] = useState<string>(null);

	var loadDatesForms = ()=>{
		API.getEventDateForms(props.event.event_id).then(res=>{
			if(res.error) return setLoadError(res.message);
			setDates(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error cargando los calendarios. (LCL-1)');
		});
	}

	useEffect(()=>{
		if(!dates){
			loadDatesForms();
		}
	}, []);

	if(loadError) return <Header text="Error" subtext={loadError} />
	if(!dates) return <Header loading text="Cargando calendarios" />

	return <Table
		selectable
		title="Calendarios"
		headers={['ID', 'Fecha', 'Recinto', 'Ciudad', 'Preguntas']}
		style={{ maxWidth: 800, margin: 'auto' }}
		empty={dates.length===0}
		emptyText="No hay calendarios en este evento"
	>
		{dates.map(a=>(
			<Table.Row
				key={a.date_id}
				compact
				collapsingIndexes={[0, 1, 3, 4]}
				centeredIndexes={[4]}
				as={Link} 
				to={`/events/${a.event_id}/forms/${a.date_id}`} 
				data={[
					a.date_id,
					moment.unix(a.date).format('DD/MMM/YY HH:mm'),
					a.venue_name,
					a.city,
					addCommas(a.questions_count || 0, false)
				]} 
			/>
		))}
	</Table>
}

export default EventFormsContainer;