import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Groupper, Header, Input, Message } from "react-frontier";
import { SetLoading,  Zone } from "@arema/components/Classes";
import { bindFormChange } from "@arema/components/Util";
import { DeletedBanner, NotFound } from "../../components";
import { useUser } from "../../AdminHooks";
import Toast from 'react-hot-toast';
import API from "../../API";
import Validator from "@arema/components/Validator";
import UserAccess from "../../UserAccess";

interface Props {
	zone_id?: string,
	zone?: Zone,
}

const ZoneCreate = (props: Props) => {
	const navigate = useNavigate()
	let zone_id = props.zone_id;
	var IS_CREATE = !props.zone;
	var { user, hasAccess } = useUser();
	var [zone, setZone] = useState<Zone>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);

	useEffect(() => {
		setZone(props.zone || {
			zone_name: '',
			description: null,
		});
	}, [props.zone]);

	const submit = async (setLoading: SetLoading) => {
		var body: Zone = {
			zone_id: null,
			zone_name: zone.zone_name,
			description: zone.description
		}
		var { valid, prompts } = Validator(body, {
			zone_name: [{
				rule: 'length', params: [4, 64], label: 'Nombre'
			}],
			description: [{
				rule: 'length', params: [4], label: 'Descripcion'
			}],
		});
		setErrorPrompts(prompts);
		if (!valid) return;

		if (!IS_CREATE) {
			body.zone_id = parseInt(zone_id);
		}

		setLoading(true);
		if (IS_CREATE) {
			API.createZone(body.zone_name, body.description).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				Toast.success('Se ha creado la zona');
				navigate(`/zones/${res.data.zone_id}`);
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperado creando la Zona (LCL-10)']);
			}).finally(() => {
				setLoading(false);
			});
		} else {
			API.editZone(body.zone_id, body.zone_name, body.description).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				Toast.success('Se ha editando la zona.');
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperado editando la zona (LCL-11)']);
			}).finally(() => {
				setLoading(false);
			});
		}
	}

	if (!IS_CREATE && Number.isNaN(parseInt(zone_id))) {
		return <NotFound />
	}

	if (!IS_CREATE && !zone) {
		return <Header text="Cargando zona" loading />
	}

	const onChangeField = bindFormChange(zone, setZone);

	return (
		<div>
			{!!zone?.deleted ? <DeletedBanner date_deleted={zone.date_deleted} /> : null}
			{zone &&
				<Groupper title={IS_CREATE ? 'Crear Nueva Zona' : `Editar Zona`} width={500} actions={(
					<Button text="Guardar" color="black" onClick={submit} />
				)}>
					<Input
						label="Nombre"
						placeholder="Nombre de la Zona"
						value={zone.zone_name}
						onChange={onChangeField('zone_name')}
					/>

					<Input
						label="Descripcion"
						placeholder="Descripcion"
						value={zone.description}
						onChange={onChangeField('description')}
					/>

					<Message list={errorPrompts} type="error" style={{ marginTop: 15 }} />
				</Groupper>
			}
		</div>
	)
}

export default ZoneCreate;