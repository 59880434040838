import React, { useState, useEffect } from "react";
import { Header } from 'react-frontier';
import { PROMOTER_EVENTS } from '../CatalogList'
import { useParams } from "react-router-dom";
import { useUser } from "../../AdminHooks";
import Catalog from '../Catalog'

interface Balance {
	balance: number,
	events: number,
}

const PromoterEventsList = ()=>{
	var { hasAccess, user, access } = useUser();
	var { id } = useParams()

	return (
		<div style={{ maxWidth: 800, margin: 'auto' }}>
			<Header text="Eventos" />
			<Catalog options={PROMOTER_EVENTS(user, hasAccess, id)} style={{ maxWidth: 800 }} />
		</div>
	)
}

export default PromoterEventsList;