import React, { useEffect, useState } from 'react';
import { Header } from 'react-frontier';

interface Props{
	text?: string,
	error: string,
	loading?: boolean
}

var ErrorHeader = (props: Props)=>{
	if(!props.error) return null;
	return <Header text={props.text || "Error"} subtext={props.error} iconName={'face-frown-open'} />
}

export default ErrorHeader;