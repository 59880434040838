import React, { useEffect, useState } from 'react';
import { Button, Groupper } from 'react-frontier';
import { Link } from 'react-router-dom';

var TestScreen = ()=>{
	var [date, setDate] = useState(null);
	useEffect(()=>{
		
	}, []);
	
	return <div style={{ maxWidth: 400, margin: 'auto', marginTop: 20 }}>
		
	</div>
}

export default TestScreen;