import React, { useEffect, useState } from 'react';
import { ErrorHeader } from '../../components';
import { ListPayout } from '../../AdminClasses';
import { addCommas, bindChange, bindClose, bindFormChange } from '@arema/components/Util';
import { Checkbox, Icon, Loader, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { SetLoading } from '@arema/components/Classes';
import { useTitle } from '@arema/components/Hooks';
import { useUser } from '../../AdminHooks';
import Toast from 'react-hot-toast';
import API from '../../API';
import moment from 'moment';
import classNames from 'classnames';
import UserAccess from '../../UserAccess';
import { Button, Field, Header, Message, Pagination, Table } from 'react-frontier';

const PAYOUT_COUNT = 20;

enum ShownModal{
	VIEW_PAYOUT = 1,
	SELECT_PAYOUTS = 2,
	CONFIRM_PAYOUT = 10,
	REJECT_PAYOUT = 11,
	COMPLETE_PENDING = 20,
	DOWNLOAD_STP = 21,
}

enum SelectType{
	APPROVAL = 1,
	REJECT = 2,
	COMPLETE = 10,
}

interface PayoutChanges{
	payout_id: number,
	data: { [x: string]: any },
}

var PayoutList = ()=>{
	var { user, hasAccess } = useUser();
	var { setTitle } = useTitle();
	var [pendingPayouts, setPendingPayouts] = useState<ListPayout[]>(null);
	var [completedPayouts, setCompletedPayouts] = useState<ListPayout[]>(null);
	var [approvalPayouts, setApprovalPayouts] = useState<ListPayout[]>(null);
	var [shownModal, setShownModal] = useState<ShownModal>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [viewingPayout, setViewingPayout] = useState<number>(null);
	var [selectType, setSelectType] = useState<SelectType>(null);
	var [selectedPayouts, setSelectedPayouts] = useState<number[]>([]);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [loadingStp, setLoadingStp] = useState<boolean>(false);
	var [rejectFeedback, setRejectFeedback] = useState<string>('');
	var [page, setPage] = useState<number>(0);
	var [pageLoading, setPageLoading] = useState<boolean>(false);
	var [completeForm, setCompleteForm] = useState<{
		stp: boolean,
		completed: boolean
	}>(null);

	const ACCESS = {
		approve: hasAccess(UserAccess.CONTABILIDAD.APPROVE_PAYOUT),
		complete: hasAccess(UserAccess.CONTABILIDAD.SET_PAYOUT_COMPLETE),
		stp: hasAccess(UserAccess.CONTABILIDAD.DOWNLOAD_STP_FILE),
	}

	useEffect(()=>{
		setTitle('Liquidaciones');
		if(completedPayouts===null){
			setLoadError(null);
			API.getPayouts(true, true, true, 0, PAYOUT_COUNT).then(res=>{
				if(res.error) return setLoadError(res.message);
				setPendingPayouts(res.data.pending);
				setApprovalPayouts(res.data.approval);
				setCompletedPayouts(res.data.completed);
			}).catch(err=>{
				setLoadError('Hubo un error inesperado cargando las liquidaciones (LCL-1)');
			})
		}
	}, []);

	if(loadError){
		return <ErrorHeader error={loadError} />
	}
	if(completedPayouts===null){
		return <Header loading text='Cargando liquidaciones' />
	}

	var onPageChange = (new_page: number)=>{
		setPage(new_page);
		setPageLoading(true);
		API.getPayouts(false, false, true, new_page*PAYOUT_COUNT, PAYOUT_COUNT).then(res=>{
			if(res.error) return setLoadError(res.message);
			setCompletedPayouts(res.data.completed);
		}).catch(err=>{
			setLoadError('Hubo un error inesperado cargando las liquidaciones (LCL-2)');
		}).finally(()=>{
			setPageLoading(false);
		})
	}

	var showConfirm = (payout_id: number, type: SelectType)=>{
		return ()=>{
			setCompleteForm({ stp: false, completed: false });
			setViewingPayout(payout_id);
			setSelectType(type);
			setModalPrompts(null);
			setRejectFeedback('');
			setShownModal(ShownModal.CONFIRM_PAYOUT);
		}
	}

	var showPayout = (payout_id: number)=>{
		return ()=>{
			setViewingPayout(payout_id);
			setShownModal(ShownModal.VIEW_PAYOUT);
		}
	}

	var updatePayouts = (payouts: PayoutChanges[])=>{
		var all_payouts = [...approvalPayouts, ...completedPayouts, ...pendingPayouts];
		for(var i of payouts){
			var ix = all_payouts.findIndex(a=>a.payout_id==i.payout_id);
			if(ix==-1) return;
			all_payouts[ix] = {
				...all_payouts[ix],
				...i.data
			};
		}
		setPendingPayouts(all_payouts.filter(a=>a.approved && !a.completed && !a.rejected));
		setApprovalPayouts(all_payouts.filter(a=>!a.approved && !a.completed && !a.rejected));
		setCompletedPayouts(all_payouts.filter(a=>a.completed || a.rejected));
	}

	var sendApprove = (payouts: number[])=>{
		return (setLoading: SetLoading)=>{
			setLoading(true);
			API.approvePayouts(payouts).then(res=>{
				if(res.error) return Toast.error(res.message);
				Toast.success('Se ha aprobado la liquidación');
				var payout_changes: PayoutChanges[] = [];
				for(let i of res.data.approved){
					payout_changes.push({ payout_id: i, data: { approved: true }})
				}
				for(let i of res.data.completed){
					payout_changes.push({ payout_id: i, data: { approved: true, completed: true }});
				}

				updatePayouts(payout_changes);
				setShownModal(null);
			}).catch(err=>{
				Toast.error('Hubo un error inesperado aprobando las liquidaciónes (LCL-1)');
			}).finally(()=>{
				setLoading(false);
			})
		}
	}

	var showSTP = (payouts: number[])=>{
		return (setLoading: SetLoading)=>{
			if(!ACCESS.stp){
				setShownModal(null);
				return Toast.error('No tienes acceso a descargar el archivo STP.');
			}
			setModalPrompts(null);
			setSelectedPayouts(payouts);
			setShownModal(ShownModal.DOWNLOAD_STP);
			setLoadingStp(true);
			API.downloadPayoutZip(payouts).then(res=>{
				if(res.error) return setModalPrompts([res.message]);
			}).catch(err=>{
				setModalPrompts(['Hubo un error inesperado descargando el archivo STP. (LCL-1)']);
			}).finally(()=>{
				setLoadingStp(false);
			});
		}
	}

	var sendComplete = (payouts: number[], force: boolean=false)=>{
		return (setLoading: SetLoading)=>{
			if(!ACCESS.complete){
				setShownModal(null);
				return Toast.error('No tienes acceso a completar liquidaciones.');
			}
			if(!completeForm || (!completeForm.completed && !completeForm.stp)){
				return setModalPrompts(['Favor de seleccionar descarga STP o ya depositado.']);
			}
			setModalPrompts(null);
			if(completeForm.stp && !force){
				return showSTP(payouts)(setLoading);
			}

			setLoading(true);
			API.completePayouts(payouts).then(res=>{
				if(res.error) return Toast.error(res.message);
				Toast.success('Se han completado las liquidaciones');
				updatePayouts(res.data.completed.map(a=>({ payout_id: a, data: { approved: true, completed: true }})));
				setShownModal(null);
			}).catch(err=>{
				Toast.error('Hubo un error inesperado completando las liquidaciónes (LCL-1)');
			}).finally(()=>{
				setLoading(false);
			})
		}
	}

	var sendReject = (payouts: number[])=>{
		return (setLoading: SetLoading)=>{
			if(!ACCESS.approve){
				setShownModal(null);
				return Toast.error('No tienes acceso a aprobar o rechazar liquidaciones.');
			}
			if(!rejectFeedback || rejectFeedback.length<5){
				return setModalPrompts(['Favor de ingresar feedback para rechazar la liquidación.']);
			}
			setModalPrompts(null);
			setLoading(true);
			API.rejectPayouts(payouts, rejectFeedback).then(res=>{
				if(res.error) return setModalPrompts([res.message]);
				Toast.success('Se han rechazado las liquidaciones.');
				updatePayouts(payouts.map(a=>({ payout_id: a, data: { rejected: true } })));
				setShownModal(null);
			}).catch(err=>{
				setModalPrompts(['Hubo un error inesperado rechazando la liquidación (LCL-1)']);
			}).finally(()=>{
				setLoading(false);
			})
		}
	}

	var selectPayouts = (select_type: SelectType)=>{
		return ()=>{
			setCompleteForm({ stp: false, completed: false });
			setSelectedPayouts([]);
			setSelectType(select_type);
			setModalPrompts(null);
			setShownModal(ShownModal.SELECT_PAYOUTS);
		}
	}

	var sendSelection = (setLoading: SetLoading)=>{
		if(selectType===SelectType.APPROVAL){
			return sendApprove(selectedPayouts)(setLoading);
		}else if(selectType===SelectType.COMPLETE){
			return sendComplete(selectedPayouts)(setLoading);
		}
	}

	var closeModal = bindClose(setShownModal);
	var onCompleteFormChange = bindFormChange(completeForm, setCompleteForm);
	var shown_payout = viewingPayout ? [...approvalPayouts, ...completedPayouts, ...pendingPayouts].find(a=>a.payout_id==viewingPayout) : null;
	var select_text = selectType===SelectType.APPROVAL ? 'Aprobar' : (selectType===SelectType.COMPLETE ? 'Completar' : (selectType===SelectType.REJECT ? 'Rechazar' : 'UNKWN'));
	var select_status = selectType===SelectType.APPROVAL ? 'aprobada' : (selectType===SelectType.COMPLETE ? 'completada' : (selectType===SelectType.REJECT ? 'rechazada' : 'UNKWN'));

	var CompleteOptions = selectType===SelectType.COMPLETE && ACCESS.complete && <>
		<Checkbox toggle checked={completeForm.completed && !completeForm.stp} label={'Esta liquidación ya se depositó'} onChange={onCompleteFormChange('completed', true)} disabled={completeForm.stp} style={{ marginTop: 15, display: 'block' }} />
		{ACCESS.stp && (
			<Checkbox toggle checked={completeForm.stp && !completeForm.completed} label={'Descargar archivo STP'} onChange={onCompleteFormChange('stp', true)} disabled={completeForm.completed} style={{ marginTop: 5, display: 'block' }} />
		)}
	</>

	var RejectOptions = selectType===SelectType.REJECT && ACCESS.approve && shown_payout.admin_creator!==user.admin_id && <>
		<Field label='Feedback' style={{ marginTop: 15 }}>
			<textarea onChange={bindChange(setRejectFeedback)} value={rejectFeedback} placeholder='Comentarios sobre la razón para rechazar la liquidación' rows={4}></textarea>
		</Field>
	</>
	
	return <div>
		<Header text='Liquidaciones' />
		{approvalPayouts.length>0 && (
			<Table
				title='Aprobación'
				headers={['ID', 'Evento', 'Cantidad', 'Fecha', '']}
				style={{ maxWidth: 600, margin: 'auto' }}
				collapsingIndexes={[0, 2, 3, 4]}
				button={<Button size='tiny' iconName='check-circle' text='Seleccionar' onClick={selectPayouts(SelectType.APPROVAL)} />}
				data={approvalPayouts.map(a=>[
					a.payout_id, 
					a.event_name, 
					addCommas(a.amount), 
					<div className={classNames({
						red: a.date_scheduled && moment.unix(a.date_scheduled).isSameOrBefore(moment(), 'day')
					})}>
						{!!a.date_scheduled && <i className="calendar icon"></i>}
						{moment.unix(a.date_scheduled || a.date_created).format(`DD/MMM/YY${!!a.date_scheduled ? '' : ' HH:mm'}`)}
					</div>,
					<>
						<Button style={{ minWidth: 50, marginRight: 5 }} size='tiny' iconName='check' color='green' onClick={showConfirm(a.payout_id, SelectType.APPROVAL)} />
						<Button style={{ minWidth: 30 }} size='tiny' iconName='info' onClick={showPayout(a.payout_id)} />
					</>
				])}
			/>
		)}
		{pendingPayouts.length>0 && (
			<Table
				title='Pendientes'
				headers={['ID', 'Evento', 'Cantidad', 'Fecha', '']}
				style={{ maxWidth: 600, margin: 'auto', marginTop: 15, }}
				collapsingIndexes={[0, 2, 3, 4]}
				button={ACCESS.stp && ACCESS.complete && (
					<Button size='tiny' iconName='check-circle' text='Seleccionar' onClick={selectPayouts(SelectType.COMPLETE)} />
				)}
				data={pendingPayouts.map(a=>[
					a.payout_id, a.event_name, addCommas(a.amount), moment.unix(a.date_scheduled || a.date_created).format('DD/MMM/YY HH:mm'),
					<>
						{ACCESS.stp && ACCESS.complete && (
							<Button style={{ minWidth: 50, marginRight: 5 }} size='tiny' iconName='check' color='green' onClick={showConfirm(a.payout_id, SelectType.COMPLETE)} />
						)}
						<Button style={{ minWidth: 30 }} size='tiny' iconName='info' onClick={showPayout(a.payout_id)} />
					</>
				])}
			/>
		)}
		<Table
			title='Liquidaciones'
			headers={['ID', 'Evento', 'Cantidad', 'Fecha', 'Estatus']}
			style={{ maxWidth: 600, margin: 'auto', marginTop: 15 }}
			footer={page>0 || pageLoading || completedPayouts.length==PAYOUT_COUNT ? (
				<div style={{ justifyContent: 'flex-end', display: 'flex' }}>
					<Pagination page={page} disabled={pageLoading} onPageChange={onPageChange} hasNext={completedPayouts.length>=PAYOUT_COUNT} />
				</div>
			) : null}
		>
			{!pageLoading ? (
				(!completedPayouts || completedPayouts.length==0) ? (
					<Table.Row>
						<Table.Cell className='empty' colSpan={5}>
							No se encontraron liquidaciones en el sistema
						</Table.Cell>
					</Table.Row>
				) : completedPayouts.map(a=>(
					<Table.Row 
						selectable
						collapsingIndexes={[0, 2, 3, 4]} 
						href={`/payouts/${a.payout_id}`}
						data={[
							a.payout_id, a.event_name, addCommas(a.amount), moment.unix(a.date_completed || a.date_cancelled || a.date_created).format('DD/MMM/YY HH:mm'),
							<div className={classNames('fr label', {
								green: a.completed && !a.rejected,
								red: a.rejected
							})}>
								{a.rejected ? 'Rechazada' : 'Completada'}
							</div>
						]} 
					/>
				)) 
			) : (
				<Table.Row>
					<Table.Cell colSpan={5}>
						<Header loading text='Cargando liquidaciones...' containerStyle={{ margin: '50px 0' }} />
					</Table.Cell>
				</Table.Row>
			)}
		</Table>
		<Modal size='tiny' open={shownModal===ShownModal.CONFIRM_PAYOUT} onClose={closeModal}>
			<Modal.Header>{select_text} liquidación {shown_payout?.payout_id}</Modal.Header>
			{shownModal===ShownModal.CONFIRM_PAYOUT && <Modal.Content>
				<Header text={`¿${select_text} liquidación?`} subtext={`Marcará la liquidación como ${select_status}`} style={{ paddingLeft: 0, paddingRight: 0, fontSize: 26 }} />
				<div style={{ textAlign: 'center' }}>
					{selectType===SelectType.APPROVAL ? (
						(shown_payout.bank_account) ? 'Se agregará a la fila de liquidaciones pendientes a liquidar.' : 'Ya que la liquidación no es por transferencia se agregará a las liquidaciones completadas.'
					) : selectType===SelectType.REJECT ? (
						shown_payout.admin_creator===user.admin_id ? <>
							Se rechazará la liquidación, ya que tu fuiste el que creó la liquidación <b>no se enviará ningún correo.</b>
						</> : (
							'Se rechazará la liquidación y se le enviará un correo con los comentarios ingresados a el creador de la liquidación.'
						)
					) : null}
				</div>
				<Table details titleSize='small'
					title='Información'
					style={{ marginTop: 15 }}
					data={[
						['ID', shown_payout.payout_id],
						['Evento', shown_payout.event_name],
						['Cantidad', addCommas(shown_payout.amount)],
						...(shown_payout.date_scheduled ? [
							['Agendada', moment.unix(shown_payout.date_scheduled).format('DD/MMM/YY')]
						] : []),
						['Creador', shown_payout.admin_creator_username],
					]}				
				/>
				{CompleteOptions}
				{RejectOptions}
				<Message type='error' list={modalPrompts} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={closeModal} />
				<Button text={select_text} color={selectType===SelectType.REJECT ? 'red' : 'black'} onClick={(selectType===SelectType.APPROVAL ? sendApprove : (selectType===SelectType.COMPLETE ? sendComplete : (selectType===SelectType.REJECT ? sendReject : (a: any)=>(setLoading: SetLoading)=>{})))([shown_payout?.payout_id])} />
			</Modal.Actions>
		</Modal>
		<Modal size='tiny' open={shownModal===ShownModal.VIEW_PAYOUT} onClose={closeModal}>
			<Modal.Header>Liquidación {shown_payout?.payout_id}</Modal.Header>
			{shownModal===ShownModal.VIEW_PAYOUT && !!shown_payout && <Modal.Content>
				<Table
					details
					fitted
					data={[
						['ID', shown_payout.payout_id],
						['Tipo', shown_payout.payout_type_name],
						['Cantidad', addCommas(shown_payout.amount)],
						['Calendarios', shown_payout.dates],
						['Transferencia', !(shown_payout.clabe || shown_payout.card_number) ? 'Solo registro' : <>
							<i className={classNames('icon', {
								'credit card': !!shown_payout.card_number,
								'building' : !!shown_payout.clabe
							})}></i>
							{shown_payout.bank} **{shown_payout.clabe || shown_payout.card_number}
						</>],
						null,
						['Aprobada', <>
							<i className={classNames("icon", {
								'red remove': !shown_payout.approved && !shown_payout.completed,
								'green check': !!shown_payout.approved || shown_payout.completed
							})}></i> 
							<span className={classNames({
								'red': !shown_payout.approved && !shown_payout.completed,
								'green': !!shown_payout.approved || shown_payout.completed
							})}>
								{(!shown_payout.approved || !!shown_payout.completed) ? 'No aprobada' : 'Aprobada'}
							</span>
						</>],
						['Estatus', <>
							<i className={classNames("icon", {
								'yellow clock': !shown_payout.completed,
								'green check': !!shown_payout.completed
							})}></i> 
							<span className={classNames({
								'yellow': !shown_payout.completed,
								'green': !!shown_payout.completed
							})}>
								{!!shown_payout.completed ? 'Completada' : 'Pendiente'}
							</span>
						</>],
					]}
				/>
				<Button 
					color='black'
					text='Ver información completa'
					as={Link}
					to={`/payouts/${shown_payout.payout_id}`}
					target='_blank'
					style={{ display: 'block', margin: 'auto', width: 250, marginTop: 15 }} 
				/>
				{!shown_payout.approved && (
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', marginTop: 5 }}>
						<Button text='Aprobar' color='green' onClick={showConfirm(shown_payout.payout_id, SelectType.APPROVAL)} />
						<Button text='Rechazar' color='red' style={{ marginLeft: 5 }} onClick={showConfirm(shown_payout.payout_id, SelectType.REJECT)} />
					</div>
				)}
			</Modal.Content>}
			<Modal.Actions>
				<Button text='Cerrar' onClick={closeModal} />
			</Modal.Actions>
		</Modal>
		<Modal size='small' open={shownModal===ShownModal.SELECT_PAYOUTS} onClose={closeModal}>
			<Modal.Header>{selectType===SelectType.APPROVAL ? 'Aprobar' : 'Completar'} liquidaciones</Modal.Header>
			{shownModal===ShownModal.SELECT_PAYOUTS && <Modal.Content>
				<Table
					fitted
					selectable
					collapsingIndexes={[0, 1, 3, 4, 5]}
					onClick={(v: number[])=>{
						var sp = [...selectedPayouts];
						var ix = sp.indexOf(v[1]);
						if(ix==-1){
							sp.push(v[1]);
						}else{
							sp.splice(ix, 1);
						}
						setSelectedPayouts(sp);
					}}
					headers={[
						<div style={{ textAlign: 'center' }}>
							<i className="checkmark icon" style={{ marginRight: 0 }}></i>
						</div>, 
						'ID', 
						'Evento',
						'Tipo',
						'Cantidad',
						'Fecha',
					]}
					data={(selectType===SelectType.APPROVAL ? approvalPayouts : (selectType===SelectType.COMPLETE ? pendingPayouts : [])).map(a=>{
						var selected = selectedPayouts.indexOf(a.payout_id)!==-1;
						return [
							<i className={classNames('large icon circle', {
								check: selected,
								outline: !selected
							})}></i>,
							a.payout_id,
							a.event_name,
							a.payout_type_name,
							<div style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>
								{addCommas(a.amount)}
							</div>,
							<div>
								{!!a.date_scheduled && <Icon name="calendar" />}
								{moment.unix(a.date_scheduled || a.date_created).format(`DD/MMM/YY${!!a.date_scheduled ? '' : ' HH:mm'}`)}
							</div>,
						]
					})}
				/>
				{CompleteOptions}
				{selectType===SelectType.APPROVAL && (
					<Message style={{ marginTop: 15 }} centered>
						Se agregará a la fila de liquidaciones pendientes a liquidar. Las liquidaciones seleccionadas que son solo por registro (sin transferencia de dinero) se marcarán como completadas. <br />
						<b style={{ fontSize: 20 }}>{selectedPayouts.length} liquidacion{selectedPayouts.length==1 ? '' : 'es'} seleccionada{selectedPayouts.length==1 ? '' : 's'}</b>
					</Message>
				)}
				<Message type='error' list={modalPrompts} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={closeModal} />
				<Button text='Continuar' color='black' onClick={sendSelection} disabled={!selectedPayouts || selectedPayouts.length==0} />
			</Modal.Actions>
		</Modal>
		<Modal size='mini' open={shownModal===ShownModal.DOWNLOAD_STP}>
			<Modal.Header>Descarga STP</Modal.Header>
			{shownModal===ShownModal.DOWNLOAD_STP && <Modal.Content>
				<div style={{ textAlign: 'center', fontSize: 16 }}>Se ha hecho la descarga de el archivo para subir a STP.</div>
				<Message type='warning' centered style={{ marginTop: 15 }}>
					<div className="header">Confirmar descarga</div>
					En cuanto se descargue el archivo y se verifique que no se tengan errores, se necesitará <b>confirmar la descarga</b> para marcar las liquidaciones como liquidadas y evitar cualquier tipo de doble deposito. 
					<br />
					{loadingStp ? (
						<Loader active inline style={{ marginTop: 15 }} />
					) : !modalPrompts || modalPrompts.length<=0 ? (
						<Button color='black' onClick={sendComplete(selectedPayouts, true)} disabled={modalPrompts && modalPrompts.length>0} text='Confirmar descarga' style={{ marginTop: 10 }} />
					) : (
						<Button basic text='Reintentar descarga' style={{ marginTop: 15 }} color='black' onClick={showSTP(selectedPayouts)} />	
					)}
				</Message>
				<Message type='error' list={modalPrompts} />
				{(!modalPrompts || modalPrompts.length<=0) && (
					<Button basic text='Reintentar descarga' disabled={loadingStp} size='tiny' style={{ display: 'block', width: 150, margin: 'auto', marginTop: 15 }} onClick={showSTP(selectedPayouts)} />
				)}
			</Modal.Content>}
		</Modal>
	</div>
}

export default PayoutList;