// =====================================
// NO EDITAR ESTE ARCHIVO DIRECTO
// COMPILADO 2024-11-08 16:18:41
// =====================================

enum EVENTS{
	_ID = 1,
	VIEW_EVENTS = 1000,		// Ver eventos
	CREATE_EVENT = 1001,		// Crear eventos
	PUBLISH_EVENT = 1002,		// Publicar eventos
	EDIT_EVENT_GENERAL = 1020,		// Editar general
	EDIT_SINOPSIS = 1021,		// Editar sinopsis
	EDIT_POSTER = 1022,		// Cambiar poster
	EDIT_USER_ASSIGNED = 1023,		// Asignar responsable
	EDIT_SUBDOMAIN = 1100,		// Editar subdominio
	EDIT_OPTIONS = 1101,		// Editar opciones
	EDIT_OPTIONS_MSI = 1102,		// Editar opcion: MSI
	CREATE_PARENT = 1200,		// Crear evento padre
	VIEW_PARENTS = 1201,		// Ver eventos padre
	EDIT_PARENT = 1202,		// Editar evento padre
	VIEW_BALANCE = 1300,		// Ver estado de cuenta de evento
	CREATE_PAYOUT = 1301,		// Crear liquidación de evento
	VIEW_EVENT_GROUP = 1400,		// Ver eventos padre
	CREATE_EVENT_GROUP = 1401,		// Crear eventos padre
	EDIT_EVENT_GROUP = 1402,		// Editar eventos padre
	EDIT_EVENT_GROUP_POSTER = 1403,		// Editar poster eventos padre
	PUBLISH_EVENT_GROUP = 1404,		// Publicar eventos padre
	DELETE_EVENT_GROUP = 1499,		// Eliminar eventos padre
	DELETE_EVENT = 1900,		// Eliminiar evento
	DELETE_PARENT = 1901,		// Eliminiar evento padre
}
enum DATES{
	_ID = 2,
	VIEW_DATE = 2000,		// Ver calendarios
	CREATE_DATE = 2001,		// Crear calendario
	MULTIPLY_DATE = 2002,		// Multiplicar calendario
	CHANGE_DATE = 2100,		// Cambiar fecha
	CHANGE_STATUS = 2101,		// Cambiar estatus
	CHANGE_VENUE = 2102,		// Cambiar recinto
	EDIT_SECTIONS = 2200,		// Editar secciones
	EDIT_PRICES = 2250,		// Editar precios
	EDIT_PAYMENT_METHODS = 2300,		// Editar formas de pago
	EDIT_DELIVERY_METHODS = 2310,		// Editar formas de entrega
	REQUEST_REFUNDABLE = 2350,		// Solicitar calendario reembolsable
	REQUEST_REFUNDABLE_COMPLETE = 2351,		// Solicitar calendario reembolsable COMPLETO
	SET_REFUNDABLE = 2355,		// Marcar calendario reembolsable
	SET_REFUNDABLE_COMPLETE = 2356,		// Marcar calendario reembolsable COMPLETO
	RECEIVE_REFUNDABLE_EMAIL = 2357,		// Recibir correos de solicitud reembolsable
	CHANGE_START_END_DATES = 2400,		// Editar fechas inicio fin
	ADD_QUEUE = 2450,		// Agregar fila de espera
	REMOVE_QUEUE = 2451,		// Quitar fila de espera
	CHANGE_DATE_SEAT_STATUS = 2500,		// Cambiar estado butacas
	ADD_SECTION_HOLD = 2501,		// Bloquear boletos general
	DELETE_SECTION_HOLD = 2502,		// Eliminar bloqueo general
	DELETE_DATE = 2900,		// Eliminar calendarios
}
enum ORDERS{
	_ID = 3,
	VIEW_ORDERS = 3000,		// Ver ordenes
	VIEW_TICKETS = 3001,		// Ver boletos
	VIEW_PDF = 3002,		// Ver PDF boletos
	VIEW_ORDER_EMAILS = 3003,		// Ver correos de orden
	VIEW_TICKET_USES = 3004,		// Ver usos boleto
	VIEW_PAYMENTS = 3005,		// Ver pagos
	VIEW_TICKET_FORM = 3006,		// Ver formulario
	VIEW_RECEIPT = 3007,		// Ver recibo
	RESEND_EMAIL = 3100,		// Reenviar correos
	SET_ORDER_PAID = 3101,		// Marcar orden pagada
	SET_TICKET_PAID = 3102,		// Marcar boleto pagado
	CONVERT_FREE = 3103,		// Convertir en cortesia
	MANUAL_DEPOSIT = 3104,		// Conciliación manual
	MOVE_TICKETS = 3105,		// Mover boletos
	EXTEND_EXPIRATION = 3106,		// Extender expiración
	MASSIVE_RESEND_EMAILS = 3107,		// Reenvio masivo de boletos
	CHANGE_ORDER_DATA = 3200,		// Cambiar datos
	CHANGE_ORDER_EMAIL = 3201,		// Cambiar correo
	CHANGE_TOTALS = 3202,		// Cambiar totales
	CHANGE_PAYMENT_METHOD = 3203,		// Cambiar forma de pago
	CHANGE_DELIVERY_METHOD = 3204,		// Cambiar forma de entrega
	EDIT_FORM = 3205,		// Editar formulario
	EDIT_TICKET_PRICE = 3206,		// Editar precio boleto
	CHANGE_TICKET_SEAT = 3207,		// Reasignar butaca de boleto
	EDIT_PAYMENT_AMOUNTS = 3208,		// Editar cantidades de pago
	VIEW_REFUND = 3300,		// Ver reembolso
	CREATE_REFUND = 3301,		// Reembolsar
	REFUND_COMPLETE = 3302,		// Reembolsar completo
	CREATE_FAKE_REFUND = 3303,		// Marcar orden reembolsada
	CREATE_REFUND_REQUEST = 3304,		// Crear solicitud de reembolso
	VIEW_REFUND_REQUEST = 3305,		// Ver solicitud de reembolso
	RESEND_REFUND_REQUEST = 3306,		// Reenviar solicitud de reembolso
	SET_REFUND_COMPLETED = 3320,		// Completar reembolso
	REVERT_REFUND_COMPLETE = 3321,		// Regresar reembolso completado
	REQUEST_REFUND_INFO = 3322,		// Solicitar info de reembolso
	UPLOAD_REFUND_FILE = 3380,		// Subir evidencia reembolso
	DELETE_REFUND_FILE = 3381,		// Eliminar evidencia reembolso
	SET_REFUND_CANCELLED = 3390,		// Cancelar reembolso
	VIEW_HIDDEN_ORDERS = 3880,		// Ver ordenes escondidas
	HIDE_ORDERS = 3881,		// Esconder ordenes
	CANCEL_ORDER = 3900,		// Cancelar orden
	CANCEL_PAYMENT = 3901,		// Cancelar pago
	REVIVIE_TICKET = 3902,		// Revivir boleto
}
enum VENUES{
	_ID = 4,
	VIEW_VENUES = 4000,		// Ver recintos
	EDIT_VENUE_INFORMATION = 4001,		// Editar información
	ADD_SECTION = 4050,		// Agregar secciones
	EDIT_SECTION = 4051,		// Editar secciones
	EDIT_VENUE_SECTION_MAP = 4100,		// Editar mapa secciones
	EDIT_VENUE_SEATS = 4101,		// Editar mapa butacas
	DELETE_VENUE = 4900,		// Eliminar recinto
	DELETE_SECTION = 4901,		// Eliminar sección
}
enum PDV{
	_ID = 5,
	VIEW_PDV = 5000,		// Ver puntos de venta
	EDIT_PDV = 5001,		// Editar punto de venta
	VIEW_PDV_ACCESS = 5020,		// Ver accesos a pdv
	VIEW_PDV_TOKEN = 5021,		// Ver tokens de registro
	VIEW_PDV_CORTES = 5100,		// Ver cortes PDV
	DEACTIVE_PDV = 5900,		// Desactivar PDV
	RESET_PDV = 5901,		// Desenlazar PDV
}
enum CATALOGS{
	_ID = 6,
	DELIVERY_METHODS_VIEW = 6000,		// Formas Envio: Ver
	DELIVERY_METHODS_CREATE = 6001,		// Formas Envio: Crear
	DELIVERY_METHODS_EDIT = 6002,		// Formas Envio: Editar
	DELIVERY_METHODS_DELETE = 6019,		// Formas Envio: Eliminar
	COUPONS_VIEW = 6020,		// Cupones: Ver
	COUPONS_CREATE = 6021,		// Cupones: Crear
	COUPONS_EDIT = 6022,		// Cupones: Editar
	COUPONS_EDIT_EVENTS = 6023,		// Cupones: Editar eventos
	COUPONS_DELETE = 6039,		// Cupones: Eliminar
	SCANNER_VIEW = 6040,		// Checadores: Ver
	SCANNER_CREATE = 6041,		// Checadores: Crear
	SCANNER_EDIT = 6042,		// Checadores: Editar
	SCANNER_REINITIALIZE = 6043,		// Checadores: Reinicializar
	SCANNER_REGEN_TOKEN = 6044,		// Checadores: Regenerar token
	SCANNER_DELETE = 6059,		// Checadores: Eliminar
	CAROUSEL_VIEW = 6060,		// Carrusel: Ver
	CAROUSEL_CRAETE = 6061,		// Carrusel: Crear
	CAROUSEL_EDIT = 6062,		// Carrusel: Editar
	CAROUSEL_DELETE = 6079,		// Carrusel: Eliminar
	PROMOTER_VIEW = 6080,		// Promotores: Ver
	PROMOTER_CREATE = 6081,		// Promotores: Crear
	PROMOTER_EDIT = 6082,		// Promotores: Editar
	PROMOTER_ADD_CONTACT = 6090,		// Promotores: Agregar contacto
	PROMOTER_EDIT_CONTACT = 6091,		// Promotores: Editar contacto
	PROMOTER_DELETE_CONTACT = 6099,		// Promotores: Eliminiar contacto
	PROMOTER_ADD_BANK = 6100,		// Promotores: Agregar cuenta bancaria
	PROMOTER_DELETE_BANK = 6109,		// Promotores: Eliminar cuenta bancaria
	PROMOTER_DELETE = 6119,		// Promotores: Eliminiar
	EXTERNAL_VIEW = 6200,		// Empresarios: Ver
	EXTERNAL_CREATE = 6201,		// Empresarios: Crear
	EXTERNAL_EDIT = 6202,		// Empresarios: Editar
	EXTERNAL_EVENT_EDIT = 6203,		// Empresarios: Editar eventos
	EXTERNAL_PROMOTERS_EDIT = 6204,		// Empresarios: Editar promotores
	EXTERNAL_CHANGE_PASSWORD = 6205,		// Empresarios: Cambiar contraseña
	EXTERNAL_EDIT_ACCESS = 6206,		// Empresarios: Cambiar permisos
	EXTERNAL_DISABLE = 6290,		// Empresarios: Deshabilitar
	EXTERNAL_REFRESH = 6291,		// Empresarios: Reiniciar sesión
	ZONES_VIEW = 6300,		// Zonas: Ver
	ZONES_CREATE = 6301,		// Zonas: Crear
	ZONES_EDIT = 6302,		// Zonas: Editar
	ZONES_DELETE = 6390,		// Zonas: Eliminar
}
enum USERS{
	_ID = 7,
	VIEW_USERS = 7001,		// Ver usuarios
	EDIT_USERS = 7002,		// Editar usuarios
	CREATE_USERS = 7003,		// Crear usuarios
	GIVE_ADMIN_ACCESS = 7004,		// Dar acceso a administrador web
	VIEW_USER_ACCESS = 7005,		// Ver logins
	CHANGE_PASSWORD = 7100,		// Cambiar contraseñas
	EDIT_ACCESS = 7200,		// Editar permisos
	DEACTIVATE_USER = 7900,		// Desactivar/reactivar usuarios
	ACTIVATE_USER = 7901,		// Desactivar usuarios
	REFRESH_USER = 7902,		// Reiniciar sesión
}
enum CONTABILIDAD{
	_ID = 8,
	ADD_DEPOSIT = 8000,		// Conciliar
	CANCEL_DEPOSIT = 8001,		// Eliminiar conciliacion
	FIX_DEPOSIT_AMOUNT = 8002,		// Arreglar cantidad depositada
	VIEW_DEPOSITS = 8003,		// Ver conciliación
	CREATE_PDV_DEPOSIT = 8100,		// Conciliar efectivo taquillas
	CANCEL_PDV_DEPOSIT = 8101,		// Eliminar deposito efectivo
	VIEW_DAY_TABLE = 8200,		// Ver tabla del día
	VIEW_PAPELETA = 8201,		// Ver papeleta
	VIEW_PAYOUTS = 8301,		// Ver liquidaciones
	APPROVE_PAYOUT = 8302,		// Aprobar liquidación
	APPROVE_AUTOMATIC_PAYOUT = 8303,		// Agendar liquidación automática
	SET_PAYOUT_COMPLETE = 8304,		// Marcar liquidación completada
	CREATE_RETURN_PAYOUT = 8305,		// Liquidar retorno excediente
	DOWNLOAD_STP_FILE = 8306,		// Descargar archivo STP
	UPLOAD_PAYOUT_FILE = 8307,		// Subir evidencia liquidación
	DELETE_PAYOUT_FILE = 8308,		// Eliminar evidencia liquidación
	CANCEL_PAYOUT = 8490,		// Cancelar liquidación
}
enum REPORTS{
	_ID = 9,
	ACCESS = 9100,		// Acceso a reportes
	INVOICE = 9101,		// Reporte de facturación
	DATE_EVENTS = 9102,		// Reporte de fecha de eventos
	PDV_SALES = 9103,		// Reporte de ventas en taquilla
	PDV_CORTES = 9104,		// Reporte de cortes de taquilla
	PDV_DEPOSITS = 9105,		// Reporte de depositos de taquilla
	AFORO = 9106,		// Reporte de aforo
}
enum TOOLS{
	_ID = 10,
	TEMPLATES_VIEW = 10000,		// Templates: Ver
	TEMPLATES_EDIT = 10001,		// Templates: Editar template
	TEMPLATES_DELETE = 10002,		// Templates: Eliminar template
}
enum SELLERS{
	_ID = 20,
	ACCESS_PDV = 20000,		// Acceso PDV
	ACCESS_CALLCENTER = 20001,		// Acceso callcenter
	ACCESS_ALL_EVENTS = 20002,		// Acceso a todos los eventos
	REQUEST_EVENT_ACCESS = 20003,		// Solicitar accceso a evento
	SELL = 20100,		// Venta
	SELL_CASH = 20101,		// Venta efectivo
	SELL_RESTRICTED_PRICES = 20102,		// Venta precios restringidos
	CANCEL_ORDER = 20190,		// Cancelar venta
	EXPIRE_ANY_PDV_ORDER = 20200,		// Expirar orden cualquier PDV
	CANCEL_OTHER_PDV_ORDER = 20201,		// Cancelar orden otro PDV
	CANCEL_PAID_ORDER = 20202,		// Cancelar ordenes pagadas
	CANCEL_CORTE_ORDER = 20203,		// Cancelar orden corte cerrado
	PRINT = 20300,		// Imprimir ventas PDV
	PRINT_OTHER_PDV = 20301,		// Imprimir ventas otros PDV
	REPRINT = 20302,		// Reimpresión
	RESEND_TICKETS = 20303,		// Reenviar boletos
	REFUND_TICKET_REQUEST = 20400,		// Reembolsar boletos solicitud
	REFUND_EXTRA_CHARGES = 20401,		// Reembolsar otros cargos
	REFUND_CORTE_ENDED = 20402,		// Reembolsar fuera de corte
	START_CORTE = 20500,		// Iniciar corte
	END_CORTE = 20501,		// Cerrar corte
	VIEW_CORTES_OTHER_PDV = 20502,		// Ver cortes otros pdv
	CANCEL_CORTE = 20519,		// Cancelar corte
}
enum CHECKERS{
	_ID = 21,
	LOGIN = 21000,		// Login checador
	SCAN_TICKET = 21001,		// Escanear boleto
	USE_TICKET = 21002,		// Usar boleto
	CANCEL_TICKET_USE = 21003,		// Cancelar uso boleto (5 min)
	CANCEL_TICKET_USE_ALWAYS = 21004,		// Cancelar uso boleto (siempre)
}
enum ADMIN{
	_ID = 60,
	DEVELOPER = 60000,		// Desarrollador
	STATISTICS = 60001,		// Estadisticas
	VIEW_COMMISSIONS = 60002,		// Desglose comisiones
	REPORTS_SITE_ACCESS = 60003,		// Acceso a empresarios
}

export default {
	EVENTS,
	DATES,
	ORDERS,
	VENUES,
	PDV,
	CATALOGS,
	USERS,
	CONTABILIDAD,
	REPORTS,
	TOOLS,
	SELLERS,
	CHECKERS,
	ADMIN,
}