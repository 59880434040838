import React from 'react';
import { Zone } from '@arema/components/Classes';
import API from '../API';
import SearchCoreModal, { SearchCoreProps } from './SearchCoreModal';

var SearchZoneModal = (props: SearchCoreProps<Zone>)=>{
	return <SearchCoreModal
		title='Buscar zona'
		{...props}
		searchData={(query, page, page_size)=>{
			return API.searchZone(query, page * page_size, page_size);
		}}
		pageSize={15}
		table={[
			{ name: 'ID', key: 'zone_id', collapsing: true },
			{ name: 'Zona', key: 'zone_name' },
		]}
	/>
}

export default SearchZoneModal;