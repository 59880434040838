import React, { useEffect, useState } from 'react';
import { Header } from 'react-frontier';

var UserSales = ()=>{
	useEffect(()=>{
		
	}, []);
	
	return <div>
		<Header text='Ventas' />
	</div>
}

export default UserSales;