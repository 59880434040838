import React, { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'semantic-ui-react';
import { Field, Groupper, Header, Message, Button, Table } from 'react-frontier';
import API from '../API';
import { EventPaymentPlan, PaymentPlan, SetLoading } from '@arema/components/Classes';
import { bindClose, bindSemantic } from '@arema/components/Util';
import Toast from 'react-hot-toast';
import { useUser } from '../AdminHooks';

export interface EventPaymentModalProps {
	open: boolean,
	onClose: () => void,
	title?: string,
	event_id: number,
}

var EventPaymentModal = (props: EventPaymentModalProps) => {
	var { user } = useUser()
	const [paymentPlans, setpaymentPlans] = useState<PaymentPlan[]>(null);//cambiar a null
	const [eventPaymentPlans, setEventPaymentPlans] = useState<EventPaymentPlan[]>(null);//cambiar a null
	var [errorPrompts, setErrorPrompts] = useState<string[]>([]);
	var [selectedPaymentPlan, setSelectedPamentPlan] = useState<number>(null);
	var [selectedDeletePaymentPlan, setSelectedDeletePamentPlan] = useState<EventPaymentPlan>(null);
	const [openModalConfirm, setOpenModalConfirm] = useState(false);

	useEffect(() => {
		if (props.open) {
			setpaymentPlans(null);
			setEventPaymentPlans(null);
			setErrorPrompts([]);
			loadData().then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				setpaymentPlans(res.paymentPlans);
				setEventPaymentPlans(res.eventPaymentPlans);
			}).catch((err) => {
				setErrorPrompts(['Hubo un error cargando la informacion (LCL-1)']);
			})
		}
	}, [props.open]);

	var loadData = async () => {
		var resPayment = await API.getPaymentPlans();
		if (resPayment.error) return { error: true, message: resPayment.message };

		var resEventPayments = await API.getEventPaymentPlans(props.event_id);
		if (resEventPayments.error) return { error: true, message: resEventPayments.message };

		return { error: false, paymentPlans: resPayment.data, eventPaymentPlans: resEventPayments.data, };
	}

	var onOpenConfirmDelete = (paymentPlan: EventPaymentPlan) => {
		return () => {
			setSelectedDeletePamentPlan(paymentPlan);
			setOpenModalConfirm(true)
		}
	}

	var deletePaymentPlan = (setLoading: SetLoading) => {
		setLoading(true);
		API.deleteEventPaymentPlan(props.event_id, selectedDeletePaymentPlan.plan_id).then((res) => {
			if (res.error) return Toast.error(res.message);
			var newQ = eventPaymentPlans.filter(e => e.plan_id !== selectedDeletePaymentPlan.plan_id)
			setEventPaymentPlans([...newQ]);
			Toast.success("Se elimino el plan de pago del evento");
			setOpenModalConfirm(false);
		}).catch(() => {
			Toast.error('Hubo un error eliminando el plan de pago. (LCL-1)');
		}).finally(() => {
			setLoading(false);
		})
	}

	var savePaymentPlan = (setLoading: SetLoading) => {
		if (eventPaymentPlans.map(e => e.plan_id).includes(selectedPaymentPlan)) {
			return Toast.error('Este plan de pago ya esta seleccionado');
		}
		setLoading(true);
		API.saveEventPaymentPlan(props.event_id, selectedPaymentPlan).then((res) => {
			if (res.error) return Toast.error(res.message);
			const pp = paymentPlans.find(p => p.plan_id === selectedPaymentPlan)
			setEventPaymentPlans(p => {
				const newPlan = [
					{
						admin_added: user.username,
						admin_id: user.admin_id,
						event_id: props.event_id,
						description: pp.description,
						interest: pp.interest,
						minimum_payment: pp.minimum_payment,
						months: pp.months,
						plan_id: pp.plan_id
					},
					...p
				];
				return newPlan;
			});
			return Toast.success('Se guardo correctamente')
		})
			.catch(() => {
				Toast.error('Hubo un error guardando el plan de pago. (LCL-1)');
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return <>
		<Modal open={props.open} onClose={props.onClose} size='small'>
			<Modal.Header>{props.title}</Modal.Header>
			<Modal.Content>
				{((!paymentPlans || !eventPaymentPlans) && errorPrompts.length === 0) && <Header loading text='Cargando planes de pago' />}
				{(paymentPlans && eventPaymentPlans) && (<div>
						<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }} >
							<Dropdown 
								fluid
								selection
								className='dropdown-action'
								placeholder="Planes de Pago"
								value={selectedPaymentPlan}
								onChange={bindSemantic(setSelectedPamentPlan)}
								options={paymentPlans.map(a => ({
									value: a.plan_id,
									text: `Meses: ${a.months}`,
								}))}
							/>
							<Button 
								style={{ 
									borderBottomLeftRadius: 0,
									borderTopLeftRadius: 0,
									borderBottomRightRadius: 8,
									borderTopRightRadius: 8 
								}}
								text='Agregar' 
								color="blue" 
								onClick={savePaymentPlan} 
							/>
						</div>
					<Table
						fitted
						style={{ marginTop: 10 }}
						title='Planes de pago del evento'
						headers={['Meses', 'Intereses', 'Descripcion', 'Usuario', '']}
						collapsingIndexes={[0, 1, 3, 4]}
					>
						{eventPaymentPlans.map(plan => (
							<Table.Row
								fitted
								key={plan.plan_id}
								collapsingIndexes={[0, 1, 3, 4]}
								centeredIndexes={[0, 1, 3, 4]}
								data={[
									plan.months,
									plan.interest,
									plan.description,
									plan.admin_added,
									<Dropdown icon={null} trigger={(
										<Button icon iconName="pen" className="tiny" />
									)}>
										<Dropdown.Menu>
											<Dropdown.Item text={'Eliminar'} icon={'remove'} onClick={onOpenConfirmDelete(plan)} />
										</Dropdown.Menu>
									</Dropdown>
								]}
							/>
						))}
					</Table>
				</div>)
				}
				<Message type='error' list={errorPrompts} style={{ marginTop: 15 }} />
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={props.onClose} />
			</Modal.Actions>
		</Modal>

		{/* Modal confirmar eliminacion */}
		<Modal open={openModalConfirm && !!selectedDeletePaymentPlan} onClose={bindClose(setOpenModalConfirm)} size="mini">
			<Modal.Header>Eliminar plan de pago</Modal.Header>
			<Modal.Content>
				<Header size='small' text='¿Eliminar el plan de pago de este evento?' />
				<ul style={{ paddingLeft: 15 }}>
					<li>Se eliminara el plan de pago de este evento</li>
				</ul>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cancelar' basic onClick={bindClose(setOpenModalConfirm)} />
				<Button text='Eliminar' color={'red'} onClick={deletePaymentPlan} />
			</Modal.Actions>
		</Modal>
	</>
}

export default EventPaymentModal;