import React, { useEffect, useState, createContext, useContext, PropsWithChildren } from 'react';
import axios from 'axios';
import { APIResponse, SetLoading } from '@arema/components/Classes';
import { Modal } from 'semantic-ui-react';
import { bindClose, bindFormChange } from '@arema/components/Util';
import { Input, Button, Table } from 'react-frontier';
import API, { TokenData, UserLogin } from './API';
import moment from 'moment';

interface PrinterSettings{
	url: string,
	code: string,
	printer: string,
}

interface RoachPrinter{
	printer_name: string,
	available: boolean,
	default: boolean,
}

const PrinterContext = createContext<{
	settings: PrinterSettings,
	getPrinters: ()=>Promise<APIResponse<RoachPrinter[]>>,
	configurePrinter: ()=>void,
	printZPL: (zpl: string)=>Promise<APIResponse>,
}>({
	settings: null,
	getPrinters: async ()=>null,
	configurePrinter: ()=>null,
	printZPL: (zpl: string)=>null,
});
export var usePrinter = () => useContext(PrinterContext);

export var PrinterProvider = (props: PropsWithChildren)=>{
	var [settings, setSettings] = useState<PrinterSettings>(null);
	var [settingsEditing, setSettingsEditing] = useState<PrinterSettings>(null);
	var [settingsModal, setSettingsModal] = useState<boolean>(false);
	var [serverPrinters, setServerPrinters] = useState<RoachPrinter[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	
	useEffect(()=>{
		var roach = window.localStorage.getItem('roach-printer');
		var roach_json : PrinterSettings = null;
		if(roach){
			try{
				roach_json = JSON.parse(roach);
			}catch(e){
				roach_json = null;
			}
		}

		setSettings({
			printer: roach_json ? roach_json.printer : null,
			code: roach_json ? roach_json.code : null,
			url: roach_json ? roach_json.url : null,
		});
	}, []);
	
	var getPrinters = async (editing: boolean=false) : Promise<APIResponse<RoachPrinter[]>>=> {
		var data = editing ? settingsEditing : settings;

		if(!data || !data.url || !data.code || data.url.length<5 || data.code.length<5){
			return { error: true, code: 'RCH-NOSTGS-GTP', message: 'No se ha configurado el servidor de impresión.' };
		}
		try{
			var res = await axios.post(`${data.url.startsWith('http') ? '' : 'http://'}${data.url}${data.url.endsWith('/') ? '' : '/'}printers`, {}, {
				headers: {
					token: data.code,
				}
			});
		}catch(e){
			return { error: true, code: 'RCH-GTPRLC-1', message: 'Hubo un error consiguiendo la lista de impresoras.' }
		}

		if(!res.data){
			return { error: true, code: 'RCH-GTPRLC-2', message: 'Hubo un error consiguiendo la lista de impresoras.' }
		}

		return res.data;
	}

	var printZPL = async (zpl: string)=>{
		if(!settings?.url || !settings?.code || settings.url.length<5 || settings.code.length<5){
			return { error: true, code: 'RCH-NOSTGS-PZL', message: 'No se ha configurado el servidor de impresión.' };
		}
		if(!settings?.printer){
			return { error: true, code: 'RCH-NOSTGS-PZL', message: 'No se ha seleccionado la impresora.' };
		}

		try{
			var res = await axios.post(`${settings.url.startsWith('http') ? '' : 'http://'}${settings.url}${settings.url.endsWith('/') ? '' : '/'}print/zpl`, {
				zpl,
				printer: settings.printer
			}, {
				headers: {
					token: settings.code,
				}
			});
		}catch(e){
			return { error: true, code: 'RCH-PRTZPL-1', message: 'Hubo un error imprimiendo los comandos ZPL.' }
		}

		if(!res.data){
			return { error: true, code: 'RCH-PRTZPL-2', message: 'Hubo un error consiguiendo la lista de impresoras.' }
		}

		return res.data as APIResponse;
	}

	var showPrinters = (setLoading: SetLoading)=>{
		if(!settingsEditing || settingsEditing.url.length<5 || settingsEditing.code.length<5) return;
		setLoading(true);
		getPrinters(true).then(res=>{
			if(res.error){
				return setLoadError(res.message);
			}
			setServerPrinters(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error cargando las impresoras (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var saveSettings = ()=>{
		setSettings({
			...settingsEditing
		});
		window.localStorage.setItem('roach-printer', JSON.stringify(settingsEditing));
		setSettingsModal(false);
		setSettingsEditing(null);
	}

	var configurePrinter = ()=>{
		if(settings){
			setSettingsEditing({
				...settings
			})
		}
		setSettingsModal(true);
	}

	var selectPrinter = (printer_name: string)=>{
		return ()=>{
			setSettingsEditing({
				...settingsEditing,
				printer: printer_name
			});
		}
	}

	var onSettingsChange = bindFormChange(settingsEditing, setSettingsEditing);

	return (
		<PrinterContext.Provider value={{
			configurePrinter,
			getPrinters,
			printZPL,
			settings,
		}}>
			{props.children}
			<Modal open={settingsModal} onClose={bindClose(setSettingsModal)} size='tiny'>
				<Modal.Header>Configurar impresora</Modal.Header>
				{!!settingsEditing && <Modal.Content>
					<Input label='URL' comment='URL del servidor de impresión Roach' value={settingsEditing?.url} onChange={onSettingsChange('url')} />
					<Input label='Código de acceso' comment='Código alfanumérico que provee la impresora.' value={settingsEditing?.code} onChange={onSettingsChange('code')} />

					{(!!serverPrinters || (!!settingsEditing && !!settingsEditing.printer)) && (
						<Table divided striped title='Impresoras' titleSize='small' style={{ marginTop: 15 }}>
							{!!serverPrinters && serverPrinters.map(a=>(
								<Table.Row collapsingIndexes={[1]} data={[a.printer_name, (
									<Button iconName='check' color={a.printer_name===settingsEditing.printer ? 'green' : 'black'} disabled={a.printer_name===settingsEditing.printer} size='tiny' onClick={selectPrinter(a.printer_name)} />
								)]} />
							))}
							{!serverPrinters && !!settingsEditing && !!settingsEditing.printer && (
								<Table.Row collapsingIndexes={[1]} data={[settingsEditing.printer, (
									<Button iconName='check' color='green' disabled />
								)]} />
							)}
							<Table.Row>
								<Table.Cell colSpan={2}>
									<Button style={{ display: 'block', width: 300, margin: 'auto' }} text='Buscar impresoras' onClick={showPrinters} />
								</Table.Cell>
							</Table.Row>
						</Table>
					)}

				</Modal.Content>}
				<Modal.Actions>
					<Button text='Cerrar' basic onClick={bindClose(setSettingsModal)} />
					<Button text={serverPrinters || (settingsEditing && settingsEditing.printer) ? 'Guardar' : 'Buscar'} color='black' onClick={serverPrinters || (settingsEditing && settingsEditing.printer) ? saveSettings : showPrinters} />
				</Modal.Actions>
			</Modal>
		</PrinterContext.Provider>
	)
}

type HasAccessFn = (a: number | number[], use_or?: boolean, ignore_super?: boolean)=>boolean;

const UserAuthContext = createContext<{
	login: boolean,
	user: UserLogin["user"],
	access: UserLogin["access"],
	dev: boolean,
	hasAccess: HasAccessFn,
	logout: ()=>void,
	refresh: ()=>void,
}>({
	login: false,
	user: null,
	access: null,
	dev: false,
	hasAccess: ()=>false,
	logout: ()=>null,
	refresh: ()=>null,
});
export var useUser = () => useContext(UserAuthContext);

export var UserProvider = (props: PropsWithChildren)=>{
	var [user, setUser] = useState<UserLogin>(JSON.parse(localStorage.getItem('user_data') || null));
	var [token, setToken] = useState<TokenData>(JSON.parse(localStorage.getItem('admin_token') || null))
	var now = moment().unix();
	var is_expired = !token || (token.expires<=(now+30));

	var updateData = ()=>{
		try{
			var d = JSON.parse(localStorage.getItem('user_data'));
			setUser(d);
			return d as UserLogin;
		}catch(e){}
	}

	var updateToken = ()=>{
		try{
			var d = JSON.parse(localStorage.getItem('admin_token'));
			setToken(d);
			return d as TokenData;
		}catch(e){}
	}

	var refresh = ()=>{
		API.getLogin(true);
	}

	var hasAccess = (a: number | number[], use_or: boolean=true, ignore_super: boolean=false)=>{
		if(!user || user.access===null || is_expired) return false;
		if((user.user.superadmin && ignore_super===false) || typeof a==='undefined' || (a as number[]).length==0) return true;
		if(Array.isArray(a)){
			var acc = (a as any) as number[], valid = [];
			for(var i of acc){
				var ha = user.access.indexOf(i);
				if(ha!=-1){
					if(use_or) return true;
					valid.push(i);
				}
			}
			return valid.length>=a.length;
		}else{
			return user.access && user.access.indexOf(a)!=-1;
		}
	}

	var logout = ()=>{
		API.logout();
	}

	useEffect(()=>{
		refresh();
		window.addEventListener('user_data', updateData);
		window.addEventListener('user_token', updateToken);
		return ()=>{
			window.removeEventListener('user_data', updateData);
			window.removeEventListener('user_token', updateToken);
		}
	}, [])
	
	return <UserAuthContext.Provider value={{
		login: !is_expired && !!user,
		user: is_expired ? null : user?.user,
		access: is_expired ? null : user?.access,
		dev: hasAccess(60000, false, true),
		hasAccess,
		logout,
		refresh,
	}}>
		{props.children}
	</UserAuthContext.Provider>
}