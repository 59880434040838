import React, { useEffect, useState } from 'react';
import { SeatmapSelect } from '@arema/components';
import { Header, Button } from 'react-frontier';
import { Modal } from 'semantic-ui-react';
import { SetLoading } from '@arema/components/Classes';
import { SectionSeatmap, SeatmapSeat } from '@arema/components/Classes';
import API from '../API';

import '@arema/components/style/seatmap.scss';

interface UpdateSeatTicketProps {
	date_id: number,
	section_id: number,
	open: boolean,
	onClose: () => void,
	title?: string,
	onSeatSelected?: (seat_id: SeatmapSeat) => void,
	onSave?: (l: SetLoading) => void,
	selectedSeats: number[],
	specialSeats?: number[],
	ticket_id: number,
}

function UpdateSeatTicket(props: UpdateSeatTicketProps) {
	var [loadError, setLoadError] = useState<string>(null);
	var [seatmap, setSeatmap] = useState<SectionSeatmap>(null);
	var [seatmapError, setSeatmapError] = useState<string>(null);

	useEffect(() => {
		if (!props.open) return;
		API.getDateSeatmap(props.date_id, props.section_id).then(res => {
			if (res.error) return setLoadError(res.message);
			setSeatmap({
				...res.data,
				seats: res.data.seatmap.map((s: SeatmapSeat) => {
					return {
						...s,
						special: props.specialSeats && props.specialSeats.includes(s.seat_id)
					}
				}),
			});
		}).catch(err => {
			return setLoadError('Hubo un error inesperado cargando el mapa de butacas (LCL-1)');
		})
	}, [props.open, props.section_id, props.date_id]);

	var map: SeatmapSeat[][] = [];
	if (seatmap) {
		for (let y = 0; y < seatmap.seats_height; y++) {
			var row: SeatmapSeat[] = [];
			for (let x = 0; x < seatmap.seats_width; x++) {
				var seat = seatmap.seats.find(a => a.seat_x === x && a.seat_y === y);
				row.push(seat || null);
			}
			map.push(row);
		}
	}

	const onSeatClick = (seat: SeatmapSeat[]) => {
		if (seat[seat.length - 1]) {
			props.onSeatSelected(seat[seat.length - 1]);
		}
	}

	return (
		<Modal open={props.open} onClose={props.onClose} size='large'>
			<Modal.Header>{props.title || 'Asignar Butaca'}</Modal.Header>
			<Modal.Content>
				{(!seatmap || loadError) ? (
					<div style={{ height: 600, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Header loading={!loadError} text={loadError || 'Cargando mapa...'} iconName={loadError ? 'face-frown-open' : null} size={loadError ? 'small' : null} />
					</div>
				) : (
					<SeatmapSelect seatmap={seatmap} error={seatmapError} onSelected={onSeatClick} loading={!seatmap} selected={props.selectedSeats} />
				)}
			</Modal.Content>
			<Modal.Actions>
				<Button basic text='Cerrar' onClick={props.onClose} />
				{
					props.onSave &&
					<Button color="black" text='Guardar' onClick={props.onSave} />
				}
			</Modal.Actions>
		</Modal>
	)
}

export default UpdateSeatTicket
