import React, { useState, useEffect } from "react";
import API from "../../API";
import { bindClose } from '@arema/components/Util';
import { Button, Header } from 'react-frontier';
import { BankAccount } from "@arema/components/Classes";
import { BankAccountModal } from '../../components';

interface Props {
	promoter_id?: number,
	onEditPromoter?: Function,
}

const PromoterAccountsList = (props: Props)=>{
	var [accounts, setAccounts] = useState<BankAccount[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [accountId, setAccountId] = useState<string>('create');
	var [createModal, setCreateModal] = useState<boolean>(false);

	var loadAccounts = ()=>{
		API.getPromoterBankAccounts(props.promoter_id).then(res=>{
			if(res.error) return setLoadError(res.message);
			setAccounts(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error cargando las cuentas. (LCL-1)');
		});
	}

	useEffect(()=>{
		if(!accounts){
			loadAccounts();
		}
	}, []);

	const showCreate = ()=>{
		setAccountId(null);
		setCreateModal(true)
	}

	const onSaveAccount = (account: BankAccount) => {
		setCreateModal(false);
		var cts = [...accounts];
		var ix = cts.findIndex(a=>a.account_id==account.account_id);
		if(ix==-1){
			cts.push(account);
		}else{
			cts[ix] = account;
		}
		setAccounts(cts);
	}

	const onClickCell = (account_id: string) => {
		return ()=>{
			setAccountId(account_id);
			setCreateModal(true);
		}
	}

	if(loadError) return <Header text="Error" subtext={loadError} />
	if(!accounts) return <Header loading text="Cargando cuentas" />

	return (
		<div>
			<table className="fr selectable table" style={{ margin: 'auto', maxWidth: 800 }}>
				<thead>
					<tr>
						<th colSpan={3} className="title">Cuentas bancarias</th>
						<th colSpan={6} style={{ textAlign: 'right' }}>
							<Button size='tiny' text="Crear" iconName='add' color='black' onClick={showCreate} />
						</th>
					</tr>
					<tr>
						<th className="collapsing">ID</th>
						<th>Nombre</th>
						<th>RFC</th>
						<th>Banco</th>
						<th>Correo</th>
					</tr>
				</thead>
				<tbody>
					{accounts.length==0 ? (
						<tr className="normal">
							<td colSpan={7} className="empty">No hay cuentas.</td>
						</tr>
					) : (
						accounts.map(a=>(
							<tr key={`accnt-${a.account_id}`} onClick={onClickCell(`${a.account_id}`)}>
								<td className="collapsing">{a.account_id}</td>
								<td>{a.name_account}</td>
								<td>{a.rfc}</td>
								<td>
									<i className={`${a.clabe ? 'money check alt' : 'credit card'} icon`}></i>
									{a.bank}
								</td>
								<td>{a.email_account}</td>
							</tr>
						))
					)}
				</tbody>
			</table>

			<BankAccountModal 
				open={createModal} 
				onClose={bindClose(setCreateModal)} 
				onCreate={onSaveAccount} 
				promoter={props.promoter_id}
				account_id={accountId}
			/>

		</div>
	)
}

export default PromoterAccountsList;