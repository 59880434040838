import React, { useEffect, useState } from 'react'
import API from '../../API'
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorHeader, NotFound } from '../../components';
import { Accordion, AccordionContent, AccordionTitle, Checkbox, Icon, Select } from 'semantic-ui-react';
import { Button, Field, Groupper, Header, Input } from 'react-frontier';
import { bindFormChange } from '@arema/components/Util';
import { SetLoading } from '@arema/components/Classes';
import Validator from '@arema/components/Validator';
import Toast from "react-hot-toast";
import SortableList, { SortableItem } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';

enum QuestionType {
	OPEN = 1,
	TEXTBOX = 2,
	SELECTION = 10,
	MULTIPLE = 11,
	YES_NO = 12,
	RATING = 13,
	FILE_DOWNLOAD = 30,
	FILE_IMAGE = 31,
	FILE_OTHER = 32,
	DATE = 50,
	STATIC_TEXT = 90,
}

interface FormQuestionValue {
	value_id?: number,
	question_id: number,
	value: string,
	sort_order?: number,
	active: boolean,
	edit_mode?: boolean,
	[key: string]: any
}

interface DateQuestion {
	question_id?: number,
	question_type: QuestionType,
	question: string,
	active: boolean,
	comment: string,
	default_value: string,
	required: boolean,
	sort_order?: number,
	values?: FormQuestionValue[],
}

function EventDateCreateQuestions() {
	var params = useParams();
	const p = params['*'].split('/')
	var event_id = params.id;
	var date_id = parseInt(p[1]);
	var question_id = p[p.length - 1];
	const IS_CREATE = question_id === 'create';

	var navigate = useNavigate();
	var [question, setQuestion] = useState<DateQuestion>(null);
	var [questionValuesOrdered, setQuestionValuesOrdered] = useState<FormQuestionValue[]>([]);
	var [loadError, setLoadError] = useState<string>(null);
	var [sortMode, setSortMode] = useState<boolean>(false);
	var questionTypes = Object.keys(QuestionType).filter((v) => isNaN(Number(v))).map((t: any) => ({
		question_type: QuestionType[t],
		description: t,
	}))

	useEffect(() => {
		loadData();
	}, [question_id])

	var loadData = () => {
		if (!IS_CREATE && !Number.isNaN(question_id)) {
			API.getEventDateQuestion(parseInt(question_id)).then(res => {
				if (res.error) return setLoadError(res.message);
				setQuestion(res.data);
				if (res.data.values) {
					setQuestionValuesOrdered([...res.data.values]);
				}

			}).catch(err => {
				setLoadError('Hubo un error cargando las preguntas (LCL-1)');
			});
		} else {
			setQuestion({
				question: '',
				question_type: QuestionType.OPEN,
				active: true,
				comment: '',
				required: false,
				default_value: '',
			});
		}
	}

	var onOptionChange = (index: number, label: any) => {
		return (value: any) => {
			var q = {
				...question,
				values: [...question.values],
			};
			q.values[index][label] = value;
			setQuestion(q);
		}
	}

	var addOption = () => {
		return () => {
			var q = {
				...question,
				values: question.values ? [...question.values] : [],
			};
			q.values.push({
				question_id: question.question_id,
				value: 'Nueva Opcion',
				edit_mode: true,
				active: true
			})
			setQuestion(q);
		}
	}

	var deleteOption = (option: FormQuestionValue) => {
		return (setLoading: SetLoading) => {
			setLoading(true);
			if (option.value_id) {
				API.deleteQuestionValue(option.value_id).then((res) => {
					if (res.error) return Toast.error(res.message);
					Toast.success('Se elimino la opcion');
					setQuestion(q => {
						q.values = q.values.filter(v => v.value_id !== option.value_id)
						return {...q }
					});
				}).catch(() => {
					return Toast.error('Hubo un error inesperado actualizando la pregunta (LCL-11)');
				})
			} else {
				setQuestion(q => {
					q.values = q.values.filter( v =>  !!v.value_id)
					return {...q }
				});
			}
		}
	}

	var saveQuestion = (setLoading: SetLoading) => {
		setLoading(true);
		var { valid, prompts } = Validator(question, {
			question: [{ rule: 'length', params: [3, 256], label: 'Pregunta' }],
			comment: [{ rule: 'length', params: [0, 128], label: 'Comentario', skipEmpty: true }],
			default_value: [{ rule: 'length', params: [0, 256], label: 'Valor Inicial', skipEmpty: true }]
		});
		/* if (valid && question.values && question.values.length > 0) {
			for (const v of question.values) {
				if ((!v.value && v.value.length > 0 && v.value.length <= 255)) {
					valid = false;
					prompts = [`El valor ${v.value} es demasiado largo, min 0 y max 255 caracteres`]
				}
			}
		} */
		if (!valid) return Toast.error(prompts[0]);
		if (!!question.question_id) {
			API.updateDateQuestion({
				question_id: question.question_id,
				question: question.question,
				comment: question.comment,
				active: question.active,
				default_value: question.default_value,
				question_type: question.question_type,
				required: question.required,
				values: question.values
			}).then(res => {
				if (res.error) return Toast.error(res.message);
				Toast.success('Se ha actualizado la pregunta');
			}).catch(err => {
				return Toast.error('Hubo un error inesperado actualizando la pregunta (LCL-11)');
			}).finally(() => {
				setLoading(false);
			});
		} else {
			API.createDateQuestion(date_id, {
				question: question.question,
				comment: question.comment,
				active: question.active,
				default_value: question.default_value,
				question_type: question.question_type,
				required: question.required,
				values: question.values
			}).then(res => {
				if (res.error) return Toast.error(res.message);
				setQuestion(q => ({ ...q, question_id: res.data }))
				navigate(`/events/${event_id}/forms/${date_id}/q/${res.data}`);
				Toast.success('Se ha creado la pregunta');
			}).catch(err => {
				return Toast.error('Hubo un error inesperado creando la pregunta (LCL-11)');
			}).finally(() => {
				setLoading(false);
			});
		}
	}

	var onSortEnd = (oldIndex: number, newIndex: number) => {
		setQuestionValuesOrdered((array) => arrayMoveImmutable(array, oldIndex, newIndex));
	};

	var onSaveOption = (option: FormQuestionValue) => {
		return (setLoading: SetLoading) => {
			setLoading(true);
			var { valid, prompts } = Validator(option, {
				value: [{ rule: 'length', params: [1, 256], label: 'Valor' }],
			});
			if (!valid) {
				setLoading(false);
				return Toast.error(prompts[0]);
			}

			if (!!option.value_id) {
				API.updateQuestionValue({
					value_id: option.value_id,
					active: option.active,
					value: option.value,
				}).then(res => {
					if (res.error) return Toast.error(res.message);
					setQuestion(q => {
						q.values = q.values.map(v => ({...v, edit_mode: false}));
						return q
					})
					Toast.success('Se ha actualizado la opcion');
				}).catch(err => {
					return Toast.error('Hubo un error inesperado actualizando la opcion (LCL-11)');
				}).finally(() => {
					setLoading(false);
				});
			} else {
				API.createQuestionValue({
					question_id: parseInt(question_id),
					active: option.active,
					value: option.value
				}).then(res => {
					if (res.error) return Toast.error(res.message);
					const values = [...question.values.map(v => ({...v, edit_mode: false, value_id: v.value_id || res.data }))]
					setQuestion(q => {
						q.values = values;
						return {...q}
					});
					setQuestionValuesOrdered(values)
					Toast.success('Se ha creado la opcion');
				}).catch(err => {
					return Toast.error('Hubo un error inesperado creando la opcion (LCL-11)');
				}).finally(() => {
					setLoading(false);
				});
			}
		}
	}

	var onSaveOrder = (setLoading: SetLoading) => {
		setLoading(true);
		var qOrder: { value_id: number, sort_order: number }[] = questionValuesOrdered.map((v, index) => ({ value_id: v.value_id, sort_order: questionValuesOrdered.length - index }))
		API.updateQuestionValueOrder(qOrder).then((res) => {
			if (res.error) return setLoadError(res.message);
			Toast.success("Se guardaron las opciones");
			setQuestion(q => {
				q.values = questionValuesOrdered;
				return { ...q };
			});
			setSortMode(false);
		}).catch(() => {
			setLoadError('Hubo un error guardando las preguntas. (LCL-1)');
		}).finally(() => {
			setLoading(false);
		})
	}

	if ((Number.isNaN(parseInt(question_id)) && !IS_CREATE)) {
		return <NotFound />
	}
	if (loadError) {
		return <ErrorHeader error={loadError} />
	}
	if (!question) {
		return <Header loading text='Cargando preguntas' />
	}

	var onFormChange = bindFormChange(question, setQuestion);
	var someOptionIsEdit = question && question.values && question.values.some(v => v.edit_mode)

	return (
		<div>
			{question && <Groupper
				title={`${IS_CREATE ? 'Crear' : 'Editar'} pregunta`}
				style={{ marginBottom: 10 }}
				width={600}
				actions={(
					<Button text="Guardar" color="black" onClick={saveQuestion} />
				)}
			>
				<div style={{ width: '100%', height: 'auto' }} >
					<Input label="Pregunta" placeholder="Pregunta" value={question.question} onChange={onFormChange('question')} />
					<Field amount={2} style={{ marginBottom: 0 }} >
						<Field label={'Tipo de pregunta'}>
							<Select
								fluid
								value={question.question_type}
								onChange={onFormChange('question_type', true)}
								placeholder='Tipo de pregunta'
								options={questionTypes.map(t => ({
									key: t.question_type,
									value: t.question_type,
									text: t.description
								}))}
							/>
						</Field>
						<Input label="Comentario" placeholder="Comentario" value={question.comment} onChange={onFormChange('comment')} />
					</Field>
					<Input label="Valor Inicial" placeholder="Valor Inicial" value={question.default_value} onChange={onFormChange('default_value')} />
					<Field amount={2}>
						<Field>
							<Checkbox
								toggle
								label={'Pregunta requerida'}
								onChange={onFormChange('required', true)}
								checked={!!question.required}
								style={{ marginBottom: 10 }}
							/>
						</Field>
						<Field>
							<Checkbox
								toggle
								label={'Activa'}
								onChange={onFormChange('active', true)}
								checked={!!question.active}
								style={{ marginBottom: 10 }}
							/>
						</Field>
					</Field>
				</div>
			</Groupper>
			}

			{
				!IS_CREATE && [QuestionType.MULTIPLE, QuestionType.SELECTION].includes(question.question_type) ?
					<table className="fr table" style={{ margin: 'auto', maxWidth: 600 }}>
						<thead>
							<tr>
								<th colSpan={1} className="title">Opciones</th>
								<th colSpan={4} style={{ textAlign: 'right' }} >
									{!sortMode ? <>
										<Button disabled={someOptionIsEdit} size='tiny' text="Ordenar" onClick={() => { setSortMode(true) }} style={{ marginRight: 5 }} />
										<Button disabled={someOptionIsEdit} size='tiny' text="Agregar Opcion" color="black" onClick={addOption()} />
									</>
										:
										<>
											<Button size='tiny' text="Cancelar" style={{ marginRight: 5 }} onClick={() => { setSortMode(false) }} />
											<Button size='tiny' text="Guardar" color="black" onClick={onSaveOrder} />
										</>
									}
								</th>
							</tr>
							<tr>
								<th className="collapsing">ID</th>
								<th>Valor</th>
								<th className="collapsing">Activa</th>
								<th style={{ textAlign: 'center' }} className="collapsing"><Icon name="pencil" /></th>
								<th style={{ textAlign: 'center' }} className="collapsing"><Icon name="remove" /></th>
							</tr>

						</thead>

						{!sortMode ? (<tbody>
							{!question.values || question.values.length === 0 ? (
								<tr className="normal">
									<td colSpan={3} className="empty">No hay opciones en esta pregunta.</td>
								</tr>
							) : question.values && question.values.map((a, index) => (
								<tr key={`val-${a.value_id}-${index}`}>
									{
										!a.edit_mode ? (<>
											<td>{a.value_id}</td>
											<td>{a.value}</td>
											<td style={{ textAlign: 'center' }} >{<Icon name={a.active ? 'check' : 'remove'} color={a.active ? 'green' : 'black'} />}</td>
											<td style={{ textAlign: 'center' }} >
												<Button disabled={someOptionIsEdit} icon iconName="pencil" className="tiny" onClick={() => { 
													setQuestion(q => {
														q.values = q.values.map(q => ({ ...q, edit_mode: q.value_id === a.value_id }))
														return {...q}
													})
												}} />
											</td>
											<td style={{ textAlign: 'center' }} > <Button disabled={someOptionIsEdit} icon iconName="remove" className="tiny" onClick={deleteOption(a)} /> </td>
										</>) : (
											<>
												<td colSpan={3}>
													<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0px' }} >
														<Input placeholder='Nueva Opcion' value={a.value} onChange={onOptionChange(index, 'value')} style={{ margin: 0, width: '66%' }} />
														<div style={{ margin: 0, width: '34%', display: 'flex', justifyContent: 'center', minWidth: 120 }} >
															<Checkbox
																toggle
																label={'Activa'}
																onChange={(v, data) => { onOptionChange(index, 'active')(data.checked) }}
																checked={!!a.active}
															/>
														</div>
													</div>
												</td>
												<td>
													<Button size='small' text='Guardar' color='black' onClick={onSaveOption(a)} />
												</td>
												<td> <Button icon iconName="remove" className="tiny" onClick={deleteOption(a)} /> </td>
											</>)
									}
								</tr>
							))}
						</tbody>)
							: <SortableList
								onSortEnd={onSortEnd}
								as={'tbody'}
							>
								{questionValuesOrdered.map((a) => (
									<SortableItem key={`qt-v-${a.value_id}`}>
										<tr style={{ backgroundColor: '#fff' }} >
											<td>{a.value_id}</td>
											<td>{a.value}</td>
											<td>{<Icon name={a.active ? 'check' : 'remove'} color={a.active ? 'green' : 'black'} />}</td>
										</tr>
									</SortableItem>
								))}
							</SortableList>
						}
					</table>
					: null
			}
		</div>
	)
}

export default EventDateCreateQuestions;