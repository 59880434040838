import React, { useEffect, useState } from 'react';
import { Button, Field, Header, Input, Message, Placeholder, Stat, Table, Toolbar } from 'react-frontier';
import { EventSectionProps, PayoutType, SetLoading } from '@arema/components/Classes';
import { Link } from 'react-router-dom';
import { Checkbox, Dropdown, Icon, Modal } from 'semantic-ui-react';
import { addCommas, bindClose, bindFormChange } from '@arema/components/Util';
import { BankAccount } from '../../AdminClasses';
import { BankAccountModal } from '../../components';
import { useUser } from '../../AdminHooks';
import Toast from 'react-hot-toast';
import API from '../../API';
import moment from 'moment';
import UserAccess from '../../UserAccess';
import Validator from '@arema/components/Validator';
import classNames from 'classnames';

interface DateSales{
	event_id: number,
	date_id: number,
	date: number,
	total: number,
	tickets: number,
	total_tickets: number,
	total_commission: number,
	total_payout: number,
	methods: {
		method_id: number,
		method_name: string,
		tickets: number,
		total: number,
		total_tickets: number,
		total_commission: number,
		total_deposit: number,
	}[],
	concilia: {
		tickets: number,
		tickets_deposit: number,
		total: number,
		total_deposit: number,
		total_deposit_tickets: number,
		total_deposit_commission: number,
	}
}

interface BalancePayout{
	payout_id: number,
	payout_type: number,
	payout_type_name: string,
	approved: boolean,
	completed: boolean,
	comments: string,
	admin_creator: number,
	admin_completed: number,
	admin_approver: number,
	admin_creator_username: string,
	admin_completed_username: string,
	admin_approver_username: string,
	dates: number,
	amount: number,
	date_scheduled: number,
	date_approved: number,
	date_completed: number,
	date_created: number,
}

interface CreatePayout{
	payout_type?: number,
	account_id?: number,
	date_scheduled: number,
	comments?: string,
	completed?: boolean,
	scheduled?: boolean,
	amounts: {
		date_id: number,
		date: number,
		amount: string
	}[]
}

enum ModalType{
	METHOD_INFO = 1,
	BANK_ACCOUT_CREATE = 2,

	PAYOUT_CREATE = 10,
	PAYOUT_VIEW = 11,
}

var EventBalance = (props: EventSectionProps)=>{
	var { hasAccess } = useUser();
	var [dates, setDates] = useState<DateSales[]>(null);
	var [payouts, setPayouts] = useState<BalancePayout[]>(null);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [shownModal, setShownModal] = useState<ModalType>(null);
	var [selectedMethod, setSelectedMethod] = useState<number>(null);
	var [selectedPayout, setSelectedPayout] = useState<BalancePayout>(null);
	var [bankAccounts, setBankAccounts] = useState<BankAccount[]>(null)
	var [payoutForm, setPayoutForm] = useState<CreatePayout>(null);
	var [createAccountModal, setCreateAccountModal] = useState<boolean>(false);

	var getData = (force: boolean=false)=>{
		setDates(null)
		setPayouts(null);
		API.getEventBalance(props.event.event_id, true, force).then(res=>{
			if(res.error) return setLoadError(res.message);
			setPayouts(res.data.payouts)
			setDates(res.data.dates);
		}).catch(err=>{
			setLoadError('Hubo un error inesperado cargando el estado de cuenta. (LCL-1)')
		});
	}

	useEffect(()=>{
		if(!dates) getData()
	}, []);

	if(loadError){
		return <Header text='Error' subtext={loadError} iconName='face-frown-open' />
	}

	var viewMethod = (method_id: number)=>{
		return ()=>{
			setSelectedMethod(method_id);
			setShownModal(ModalType.METHOD_INFO);
		}
	}

	var viewPayout = (payout: BalancePayout)=>{
		return ()=>{
			setSelectedPayout(payout);
			setShownModal(ModalType.PAYOUT_VIEW);
		}
	}

	var showCreatePayout = ()=>{
		if(!hasAccess(1301)) return;
		if(bankAccounts===null){
			API.getPromoterBankAccounts(props.event.promoter_id).then(res=>{
				if(res.error){
					return Toast.error(`Hubo un error inesperado cargando las cuentas bancarias del promotor: ${res.message}`);
				}
				setBankAccounts(res.data);
			}).catch(err=>{
				Toast.error('Hubo un error inesperado cargando las cuentas bancarias del promotor (LCL-1)');
			});
		}
		setPayoutForm({ amounts: [], date_scheduled: null });
		setShownModal(ModalType.PAYOUT_CREATE);
	}

	var showAddAccount = ()=>{
		if(!hasAccess(UserAccess.CATALOGS.PROMOTER_ADD_BANK)){
			return Toast.error('No tienes permiso a realizar esta acción.');
		}
		setCreateAccountModal(true);
	}

	var onBankAccountCreated = (account: BankAccount)=>{
		if(!bankAccounts){
			setBankAccounts([account]);
		}else{
			setBankAccounts([
				...bankAccounts,
				account
			]);
		}
		setCreateAccountModal(false);
	}

	var createPayout = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(payoutForm, {
			amounts: [{
				rule: (v: CreatePayout["amounts"])=>{
					var amt = 0;
					for(var i of v){
						amt += parseFloat(i.amount);
					}
					return amt>0;
				}, 
				prompt: 'Favor de ingresar por lo menos una liquidación'
			}, {
				rule: (v: CreatePayout["amounts"])=>{
					for(var i of v){
						var date = dates.find(a=>a.date_id==i.date_id);
						if(Number.isNaN(parseFloat(i.amount))) return false;
						var total_income = payoutForm.payout_type===PayoutType.RETORNO ? date.concilia.total_deposit : date.concilia.total_deposit_tickets
						var total_remaining = Math.min(total_income, date.total_tickets)-date.total_payout;
						if(parseFloat(i.amount)>total_remaining) return false;
					}
					return true;
				},
				prompt: 'Uno o mas calendarios están liquidando una cantidad inválida.'
			}],
			payout_type: [{
				rule: 'empty', prompt: 'Favor de seleccionar el tipo de liquidación'
			}],
			account_id: [{
				rule: 'empty',
				if: !payoutForm.completed,
				prompt: 'Favor de seleccionar la cuenta bancaria a transferir.'
			}],
			date_scheduled: [{
				rule: (v: number)=>{
					return v && moment().startOf('day').unix()<=v;
				},
				if: payoutForm.scheduled && !payoutForm.completed,
				prompt: 'Favor de ingresar una fecha agendada futura.'
			}, {
				rule: (v: number)=>{
					if(!v) return false;
					return moment().unix()>=v;
				},
				if: payoutForm.completed,
				prompt: 'Favor de seleccionar una fecha pasada del deposito.'
			}]
		});
		setModalPrompts(prompts);
		if(!valid) return;
		
		setLoading(true);
		API.createPayout(props.event.event_id, {
			payout_type: payoutForm.payout_type,
			account_id: payoutForm.completed ? null : payoutForm.account_id,
			comments: payoutForm.comments && payoutForm.comments.length>0 ? payoutForm.comments : null,
			completed: payoutForm.completed,
			date_scheduled: payoutForm.completed || payoutForm.scheduled ? payoutForm.date_scheduled : null,
			dates: payoutForm.amounts.map(a=>({
				date_id: a.date_id,
				amount: parseFloat(a.amount),
			}))
		}).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			setShownModal(null);
			setDates(null);
			setPayouts(null);
			getData();
			Toast.success('Se ha creado la liquidación con éxito');
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado creando la liquidación (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var total_payment_methods : DateSales["methods"] = [];
	var calendario_count = dates?.length;
	var total_money = dates ? 0 : null;
	var total_tickets = dates ? 0 : null;
	var total_commission = dates ? 0 : null;
	var total_deposit = dates ? 0 : null;
	var total_deposit_tickets = dates ? 0 : null;
	var total_payout = dates ? 0 : null;
	var total_remaining = dates ? 0 : null;
	var ticket_count = dates ? 0 : null;

	if(dates){
		for(var i of dates){
			total_money += i.total;
			total_tickets += i.total_tickets;
			total_commission += i.total_commission;
			total_deposit += i.concilia.total_deposit;
			total_deposit_tickets += i.concilia.total_deposit_tickets;
			ticket_count += i.tickets;
			total_payout += i.total_payout;

			for(var m of i.methods){
				var pix = total_payment_methods.findIndex(a=>a.method_id==m.method_id);
				if(pix===-1){
					total_payment_methods.push({
						...m
					});
				}else{
					total_payment_methods[pix].tickets += m.tickets;
					total_payment_methods[pix].total += m.total;
					total_payment_methods[pix].total_tickets += m.total_tickets;
					total_payment_methods[pix].total_commission += m.total_commission;
					total_payment_methods[pix].total_deposit += m.total_deposit;
				}
			}
		}
	}

	total_remaining = total_tickets - total_payout;
	var viewing_method = total_payment_methods.find(a=>a.method_id==selectedMethod);

	var onPayoutFormChange = bindFormChange(payoutForm, setPayoutForm);

	return <div>
		<Toolbar stretch style={{ maxWidth: 500, margin: 'auto' }}>
			{hasAccess(UserAccess.EVENTS.CREATE_PAYOUT) && (
				<Toolbar.Item icon='money-bill-wave' text='Liquidar' onClick={showCreatePayout} />
			)}
			<Toolbar.Item icon='funnel-dollar' text='Filtar fechas' onClick={()=>alert('Funcionalidad pendiente')} />
			<Toolbar.Item icon='wrench' text='Herramientas' items={[
				{ text: 'Refrescar datos', onClick: ()=>getData(true) }
			]} />
		</Toolbar>
		<table className="fr details striped divided table" style={{ margin: 'auto', marginTop: 15, maxWidth: 500 }}>
			<thead>
				<tr>
					<th colSpan={2} className='title'>Detalles</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>ID</td>
					<td>{props.event.event_id}</td>
				</tr>
				<tr>
					<td>Promotor</td>
					<td>
						{props.event.promoter_name}
						<div className="meta">
							<Button size='tiny' color='black' text='Ver promotor' as={Link} to={`/promoters/${props.event.promoter_id}`} target={'_blank'} />
						</div>
					</td>
				</tr>
				<tr>
					<td>Calendarios</td>
					<td>
						<Placeholder value={calendario_count} />
					</td>
				</tr>
				<tr>
					<td>Boletos</td>
					<td>
						<Placeholder value={ticket_count} />
					</td>
				</tr>
				<tr className="divider">
					<td></td>
					<td></td>
				</tr>
				<tr>
					<td>Total ingresos</td>
					<td>
						<Placeholder value={addCommas(total_money)} />
					</td>
				</tr>
				<tr>
					<td>Ingresos promotor</td>
					<td>
						<Placeholder value={addCommas(total_tickets)} />
					</td>
				</tr>
				<tr>
					<td>Comisión</td>
					<td><Placeholder value={addCommas(total_commission)} /></td>
				</tr>
				<tr className="divider">
					<td></td>
					<td></td>
				</tr>
				<tr>
					<td>Total conciliado</td>
					<td><Placeholder value={addCommas(total_deposit)} /></td>
				</tr>
				<tr>
					<td>Conciliado promotor</td>
					<td><Placeholder value={addCommas(total_deposit_tickets)} /></td>
				</tr>
				<tr className="divider">
					<td></td>
					<td></td>
				</tr>
				<tr>
					<td>Liquidado</td>
					<td><Placeholder value={addCommas(total_payout)} /></td>
				</tr>
				<tr>
					<td>Cantidad restante</td>
					<td><Placeholder value={addCommas(total_remaining)} check={!!dates} /></td>
				</tr>
				<tr>
					<td>Liquidable</td>
					<td><Placeholder value={addCommas(Math.min(total_remaining, total_deposit_tickets)-total_payout)} check={!!dates} /></td>
				</tr>
			</tbody>
		</table>
		{hasAccess(UserAccess.CONTABILIDAD.VIEW_PAYOUTS) && !!payouts && payouts.length>0 && (
			<table className="fr table striped selectable divided" style={{ margin: 'auto', marginTop: 15, maxWidth: 700 }}>
				<thead>
					<tr>
						<th className="title" colSpan={6}>Liquidaciones</th>
					</tr>
					<tr>
						<th>ID</th>
						<th>Fecha</th>
						<th>Comentarios</th>
						<th className='centered'>Cantidad</th>
						<th className='centered'>Estatus</th>
					</tr>
				</thead>
				<tbody>
					{payouts.map(a=>{
						var scheduled_late = !a.completed && !!a.date_scheduled && moment().unix()>a.date_scheduled;
						return (
							<tr key={`PYT-${a.payout_id}`} onClick={viewPayout(a)}>
								<td className='collapsing'>{a.payout_id}</td>
								<td className={classNames('collapsing',{
									red: scheduled_late
								})}>
									{!!!a.completed && !!a.date_scheduled && (
										<i className="calendar icon"></i>
									)}
									{moment.unix(a.date_completed || a.date_scheduled || a.date_created).format('DD/MMM/YY HH:mm')}
								</td>
								<td>{a.comments}</td>
								<td className='collapsing centered'>{addCommas(a.amount)}</td>
								<td className={classNames('collapsing centered', {
									yellow: !a.completed && !scheduled_late && !a.approved,
									red: scheduled_late && !a.completed,
									green: !!a.completed
								})}>
									<i className={classNames('icon', {
										clock: !a.completed && (!a.approved || !a.date_scheduled),
										calendar: !a.completed && a.approved && !!a.date_scheduled,
										check: a.completed
									})}></i>
									{
										a.completed ? 'Completado' :
										!a.approved ? 'En aprobación' : 
										!!a.date_scheduled ? 'Agendado' :
										a.approved ? 'Pendiente' : null
									}
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		)}
		{total_payment_methods.length>0 && (
			<table className="fr table striped selectable divided" style={{ margin: 'auto', marginTop: 15, maxWidth: 700 }}>
				<thead>
					<tr>
						<th className="title" colSpan={7}>Formas de pago</th>
					</tr>
					<tr>
						<th>ID</th>
						<th>Método</th>
						<th>Boletos</th>
						<th>Concilia</th>
						<th>Promotor</th>
						<th>Comisión</th>
						<th>Total</th>
					</tr>
				</thead>
				<tbody>
					{total_payment_methods.sort((a,b)=>a.method_id-b.method_id).map(a=>(
						<tr key={`PMT-${a.method_id}`} onClick={viewMethod(a.method_id)}>
							<td className='collapsing centered'>{a.method_id || (
								<Icon name="minus" color='grey' />
							)}</td>
							<td>{a.method_name || (
								<i style={{ color: 'gray' }}>Sin forma de pago</i>
							)}</td>
							<td className='collapsing centered'>{addCommas(a.tickets, false)}</td>
							<td className='collapsing centered'>{addCommas((a.total_deposit*100)/a.total)}%</td>
							<td className='collapsing'>{addCommas(a.total_tickets)}</td>
							<td className='collapsing'>{addCommas(a.total_commission)}</td>
							<td className='collapsing'>{addCommas(a.total)}</td>
						</tr>
					))}
				</tbody>
			</table>
		)}
		{!!dates && dates.length>0 && (
			<table className="fr table striped divided" style={{ margin: 'auto', marginTop: 15, maxWidth: 700 }}>
				<thead>
					<tr>
						<th className="title" colSpan={8}>Calendarios</th>
					</tr>
					<tr>
						<th>ID</th>
						<th>Fecha</th>
						<th>Boletos</th>
						<th>Total</th>
						<th>Comisión</th>
						<th>Promotor</th>
						<th>Liquidado</th>
						<th>Liquidable</th>
					</tr>
				</thead>
				<tbody>
					{dates.sort((a,b)=>a.date-b.date).map(a=>(
						<tr key={`DTTB-${a.date_id}`}>
							<td className='collapsing'>{a.date_id}</td>
							<td className='collapsing'>{moment.unix(a.date).format('DD/MMM/YY HH:mm')}</td>
							<td className='collapsing centered'>{addCommas(a.tickets, false)}</td>
							<td className='collapsing'>{addCommas(a.total)}</td>
							<td className='collapsing'>{addCommas(a.total_commission)}</td>
							<td className='collapsing'>{addCommas(a.total_tickets)}</td>
							<td className='collapsing'>{addCommas(a.total_payout)}</td>
							<td className='collapsing'>{addCommas(a.total_tickets-a.total_payout)}</td>
						</tr>
					))}
				</tbody>
			</table>
		)}
		<Modal open={shownModal===ModalType.METHOD_INFO} onClose={bindClose(setShownModal)} size='tiny'>
			<Modal.Header>{viewing_method?.method_name || <i>Sin forma de pago</i>}</Modal.Header>
			{!!viewing_method && (
				<Modal.Content>
					<Table
						fitted
						details
						className='last'
						data={[
							['ID', (viewing_method.method_id || <Icon name="minus" color='grey' />)],
							['Boletos', addCommas(viewing_method.tickets, false)],
							null,
							['Total', addCommas(viewing_method.total)],
							['Promotor', addCommas(viewing_method.total_tickets)],
							['Comisión', addCommas(viewing_method.total_commission)],
							null,
							['Conciliado', <>
								{addCommas(viewing_method.total_deposit)}
								<span style={{ marginLeft: 5, color: 'gray', fontSize: 12 }}>({addCommas((viewing_method.total_deposit*100)/viewing_method.total)}%)</span>
							</>],
							['Faltante', addCommas(Math.max(0, viewing_method.total-viewing_method.total_deposit))]
						]}
					/>
					<Button 
						color='black'
						text='Ver conciliación faltante'
						as={Link}
						to={`/conciliacion/events/${props.event.event_id}/${(viewing_method.method_id || -1)}`}
						target={'_blank'}
						style={{ display: 'block', margin: 'auto', width: 250, marginTop: 15 }} 
					/>
				</Modal.Content>
			)}
			<Modal.Actions>
				<Button text='Cerrar' onClick={bindClose(setShownModal)} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===ModalType.PAYOUT_CREATE && !!dates} onClose={bindClose(setShownModal)} size='tiny'>
			<Modal.Header>Crear liquidación</Modal.Header>
			{!!payoutForm && !!dates && (
				<Modal.Content>
					<Field label='Tipo de liquidación'>
						<Dropdown selection placeholder='Tipo de liquidación' value={payoutForm.payout_type} onChange={onPayoutFormChange('payout_type', true)} options={[
							{ value: 1, text: 'Liquidación' },
							{ value: 10, text: 'Anticipo' },
							...(hasAccess(UserAccess.CONTABILIDAD.CREATE_RETURN_PAYOUT) ? [
								{ value: 20, text: 'Retorno' },
							] : [])
						]} />
					</Field>
					<Field amount={2} style={{ marginBottom: 5, marginTop: 15 }}>
						<Field>
							<Checkbox toggle label="Agendar transferencia" checked={payoutForm.scheduled && !payoutForm.completed} disabled={payoutForm.completed} onChange={onPayoutFormChange('scheduled', true)} />
						</Field>
						{hasAccess(UserAccess.CONTABILIDAD.SET_PAYOUT_COMPLETE) && (
							<Field>
								<Checkbox toggle label="Liquidación completada" checked={payoutForm.completed} onChange={onPayoutFormChange('completed', true)} />
							</Field>
						)}
					</Field>
					{(!!payoutForm.scheduled || !!payoutForm.completed) && (
						<Input label='Fecha de deposito' value={payoutForm.date_scheduled} onChange={onPayoutFormChange('date_scheduled')} calendar={{ date: payoutForm.date_scheduled, mode: 'date' }} />
					)}
					{!!!payoutForm.completed && (
						<Field label='Cuenta bancaria'>
							<Dropdown 
								selection
								selectOnBlur={false}
								loading={bankAccounts===null} 
								disabled={bankAccounts===null}
								value={payoutForm.account_id}
								onChange={onPayoutFormChange('account_id', true)}
								placeholder='Selecciona la cuenta bancaria'
								options={[
									{ text: (<i>Nueva cuenta bancaria</i>), onClick: showAddAccount, key: `BA-${-1}` },
									...(bankAccounts ? bankAccounts.map(a=>({
										text: <div key={`BKA-${a.account_id}`}>
											<i className={`${a.card_number ? 'credit card' : 'money check alt'} icon`}></i>
											{a.card_number ? (
												`${a.bank} **${a.card_number.slice(-4)}`
											) : a.clabe ? (
												`${a.bank} **${a.clabe.slice(-5)}`
											) : null}
										</div>, value: a.account_id
									})) : [])
								]}
							/>
						</Field>
					)}
					<Field label='Comentarios'>
						<textarea rows={3} onChange={onPayoutFormChange('comments', false, true)} value={payoutForm.comments} placeholder='Comentarios adicionales sobre la liquidación'></textarea>
					</Field>
					<Field label='Calendarios'>
						<Dropdown
							selection
							selectOnBlur={false}
							placeholder='Selecciona el calendario para agregar'
							value={null}
							options={dates.filter(a=>(!payoutForm || payoutForm.amounts.findIndex(b=>b.date_id==a.date_id)===-1)).map(a=>{
								var total_income = payoutForm.payout_type===PayoutType.RETORNO ? a.concilia.total_deposit : a.concilia.total_deposit_tickets
								var total_remaining = Math.min(total_income, a.total_tickets)-a.total_payout;
								var payout_total = payoutForm.amounts.find(b=>b.date_id===a.date_id);
								if(payout_total) return {};
								return {
									value: a.date_id,
									disabled: total_remaining<=0,
									text: moment.unix(a.date).format('DD/MMM/YY HH:mm'),
									description: `$${addCommas(total_remaining)}`,
									onClick: total_remaining<=0 ? null : ()=>{
										var pf = {...payoutForm};
										pf.amounts.push({
											date_id: a.date_id,
											date: a.date,
											amount: total_remaining.toString(),
										});
										setPayoutForm(pf);
									}
								}
							})}
						/>
					</Field>
					{payoutForm.amounts && payoutForm.amounts.length>0 && <>
						{payoutForm.payout_type===PayoutType.RETORNO && (
							<Message centered text='Las liquidaciones de tipo retorno pueden liquidar hasta la comisión de AREMA.' type='info' size="small" />
						)}
						<table className="fr table first" style={{ marginTop: 15 }}>
							<thead>
								<tr>
									<th>ID</th>
									<th>Calendario</th>
									<th>Liquidación</th>
									<th>Liquidable</th>
									<th className='collapsing'><Icon name="remove" /></th>
								</tr>
							</thead>
							<tbody>
								{payoutForm.amounts.map(a=>{
									var date = dates.find(b=>b.date_id==a.date_id);
									if(!date) return null;
									var total_income = payoutForm.payout_type===PayoutType.RETORNO ? date.concilia.total_deposit : date.concilia.total_deposit_tickets;
									var total_remaining = Math.min(total_income, date.total_tickets)-date.total_payout;
									return (
										<tr key={`PYA-${a.date_id}`}>
											<td className='collapsing'>{a.date_id}</td>
											<td className='collapsing'>{moment.unix(a.date).format('DD/MMM/YY HH:mm')}</td>
											<td className='input'>
												<Input 
													value={Number.isNaN(a.amount) ? '' : a.amount}
													error={total_remaining<parseFloat(a.amount)}
													onChange={v=>{
														var pf = {...payoutForm};
														var ax = pf.amounts.findIndex(b=>b.date_id==a.date_id);
														pf.amounts[ax].amount = v;
														setPayoutForm(pf);
													}}
												/>
											</td>
											<td className='collapsing'>{addCommas(total_remaining)}</td>
											<td>
												<Button color='black' size='tiny' iconName='remove' icon onClick={()=>{
													var pf = {...payoutForm};
													pf.amounts = pf.amounts.filter(b=>b.date_id!==a.date_id);
													setPayoutForm(pf);
												}} />
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
						<Stat label='Total liquidación' value={addCommas(payoutForm.amounts.reduce((a,b)=>a+parseFloat(b.amount), 0) || 0)} style={{ paddingBottom: 0 }} />
					</>}
					<Message list={modalPrompts} type='error' />
				</Modal.Content>
			)}
			<Modal.Actions>
				<Button basic text='Cerrar' onClick={bindClose(setShownModal)} />
				<Button color='black' text='Crear liquidación' onClick={createPayout} />
			</Modal.Actions>
		</Modal>
		{hasAccess(UserAccess.CONTABILIDAD.VIEW_PAYOUTS) && (
			<Modal open={shownModal===ModalType.PAYOUT_VIEW && !!payouts} onClose={bindClose(setShownModal)} size='tiny'>
				<Modal.Header>Liquidación</Modal.Header>
				{!!selectedPayout && <Modal.Content>
					<Table
						fitted
						details
						className='last'
						data={[
							['ID', selectedPayout.payout_id],
							['Tipo', selectedPayout.payout_type_name],
							['Cantidad', addCommas(selectedPayout.amount)],
							['Calendarios', addCommas(selectedPayout.dates, false)],
							...(selectedPayout.comments && selectedPayout.comments.trim().length>0 ? [['Comentarios', selectedPayout.comments]] : []),
							null,
							['Aprobada', <>
								<i className={classNames("icon", {
									'red remove': !selectedPayout.approved && !selectedPayout.completed,
									'green check': !!selectedPayout.approved || selectedPayout.completed
								})}></i> 
								{(!!selectedPayout.approved || !!selectedPayout.completed) ? 'No aprobada' : 'Aprobada'}
							</>],
							['Estatus', <>
								<i className={classNames("icon", {
									'yellow clock': !selectedPayout.completed,
									'green check': !!selectedPayout.completed
								})}></i> 
								{!!selectedPayout.completed ? 'Completada' : 'Pendiente'}
							</>],
							null,
							['Creador', <>
								<Link className='normal' to={`/admins/${selectedPayout.admin_creator}`} target='_blank'>
									{selectedPayout.admin_creator_username}
								</Link>
								<div className="meta">
									{moment.unix(selectedPayout.date_created).format('DD/MM/YY HH:mm')}
								</div>
							</>],
							...(!!selectedPayout.approved ? [['Aprobador', <>
								<Link className='normal' to={`/admins/${selectedPayout.admin_approver}`} target='_blank'>
									{selectedPayout.admin_approver_username}
								</Link>
								<div className="meta">
									{moment.unix(selectedPayout.date_approved).format('DD/MM/YY HH:mm')}
								</div>
							</>]] : []),
							...(!!selectedPayout.completed ? [['Completed', <>
								<Link className='normal' to={`/admins/${selectedPayout.admin_completed}`} target='_blank'>
									{selectedPayout.admin_completed_username}
								</Link>
								<div className="meta">
									{moment.unix(selectedPayout.date_completed).format('DD/MM/YY HH:mm')}
								</div>
							</>]] : [])
						]}
					/>
					<Button 
						color='black'
						text='Ver información completa'
						as={Link}
						to={`/payouts/${selectedPayout.payout_id}`}
						target={'_blank'}
						style={{ display: 'block', margin: 'auto', width: 250, marginTop: 15 }} 
					/>
				</Modal.Content>}
				<Modal.Actions>
					<Button onClick={bindClose(setShownModal)} text='Cerrar' />
				</Modal.Actions>
			</Modal>
		)}
		<BankAccountModal open={createAccountModal} onClose={bindClose(setCreateAccountModal)} onCreate={onBankAccountCreated} promoter={props.event.promoter_id} />
	</div>
}

export default EventBalance;