import React, { useEffect, useState } from 'react';
import { CatalogOptions, CatalogParams } from './Catalog';
import { CatalogEvent, DeliveryMethod, EventGroup, SetLoading, TicketType } from '@arema/components/Classes';
import { Image } from 'react-frontier';
import { CDN_URL, PLACEHOLDER_IMG } from '../AdminConfig';
import { addCommas } from '@arema/components/Util';
import { TicketTemplate } from '../AdminClasses';
import { Icon } from 'semantic-ui-react';
import API, { UserLogin } from '../API';
import moment from 'moment';

type UserLoginUser = UserLogin["user"];

type HasAccessFn = (a: number | number[], use_or?: boolean, ignore_super?: boolean)=>boolean;
type CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn, ...args: any[])=>CatalogOptions;

var formatUnix = (a: number)=> a ? moment.unix(a).format('DD/MM/YY')  : '';
var formatTime = (a: number)=> a ? moment.unix(a).format('DD/MM/YY HH:mm:ss') : '';
var formatTimeEvent = (a: number)=> a ? moment.unix(a).format('DD/MM/YY HH:mm:ss') : <Icon name="minus" color="grey" />;
var formatNull = (a: number)=> a && a!==0 ? `${a}` : <Icon name="minus" color="grey" />;

const EVENTS : CatalogType = ()=>({
	key: 'events',
	title: 'Eventos',
	emptyText: 'No hay eventos en el sistema',
	catalogUrl: e=>`/events/${e.event_id}`,
	search: true,
	createText: 'Crear evento',
	createUrl: '/events/create',
	pageSize: 20,
	pagination: true,
	getData: (params: CatalogParams, offset: number, count: number)=>{
		if(params && params.query){
			return API.searchEvent(params.query, offset, count);
		}else{
			return API.getPopularEvents();
		}
	},
	renderData: (a: CatalogEvent)=>{
		return <>
			<Image src={`${CDN_URL}/events/${a.event_id}/200.webp`} alt={a.event_name} fallback={PLACEHOLDER_IMG} />
			<div className="left">
				<div className="meta" style={{ fontSize: 12, lineHeight: '14px' }}>Evento #{a.event_id}</div>
				<div className="name">{a.event_name}</div>
				{!!a.date && <>
					<div className="date" style={{ fontSize: 12, lineHeight: '14px' }}>{moment.unix(a.date).format('DD/MM/YY')}</div>
					<div className="city" style={{ fontSize: 12, lineHeight: '14px' }}>{a.city}</div>
				</>}
			</div>
		</>
	},
	extractKey: (item: CatalogEvent)=>{
		return item.event_id;
	}
})

var USER_ADMIN : CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn)=>({
	key: 'useradmin',
	title: 'Usuarios',
	emptyText: 'No se encontraron usuarios en el sistema',
	catalogUrl: u=>{
		if(!u || !u.admin_id) return null;
		return `/admins/${u.admin_id}`;
	},
	search: true,
	createText: 'Nuevo usuario',
	pageSize: 20,
	pagination: true,
	filters: [
		[
			{ type: 'checkbox', shorthand: 'aa', key: 'admin_access', label: 'Acceso administrador' },
		]
	],
	table: [
		{ name: 'ID', key: 'admin_id', collapsing: true },
		{ name: 'Usuario', key: 'username', render: a=>a && a.toUpperCase(), collapsing: true },
		{ name: 'Nombre', render: (b, a)=>`${a.first_name} ${a.last_name}` },
		{ name: 'Creación', key: 'date_created', render: formatUnix, collapsing: true }
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getAdminList(params?.query, offset, count, params);
	},
	submitCreate: (data: any, setLoading: SetLoading)=>{
		return API.createAdmin(data.username, data.email, data.first_name, data.last_name, data.admin_access)
	},
	form: {
		username: {
			type: 'text',
			label: 'Usuario',
			rules: [
				{ rule: /^[0-9a-zA-Z]{4,32}$/, prompt: 'El usuario no es válido.' }
			]
		},
		email: {
			type: 'text',
			label: 'Correo electrónico',
			rules: [
				{ rule: 'email', prompt: 'El correo electrónico es inválido' }
			]
		},
		first_name: {
			type: 'text',
			label: 'Nombre',
			rules: [
				{ rule: 'minLength', params: [3], prompt: 'Favor de ingresar el nombre del usuario.' }
			]
		},
		last_name: {
			type: 'text',
			label: 'Apellido',
			rules: [
				{ rule: 'minLength', params: [3], prompt: 'Favor de ingresar el apellido del usuario.' }
			]
		},
		...(hasAccess(6004) ? {
			admin_access: {
				type: 'checkbox',
				label: 'Acceso a admin',
				description: 'Solo usar si se desea que el usuario tenga acceso a el administrador web (esta página).',
				value: false
			},
		} : {}),
		info: {
			type: 'message',
			content: 'La contraseña del usuario se le enviará a el correo electrónico que se ingresó.'
		}
	}
});

const VENUE : CatalogType = (user: UserLoginUser)=>({
	key: 'venue',
	title: 'Recintos',
	emptyText: 'No se encontraron recintos en el sistema',
	search: true,
	catalogUrl: u=>`/venues/${u.venue_id}`,
	createText: 'Nuevo recinto',
	createUrl: `/venues/create`,
	pagination: true,
	pageSize: 20,
	table: [
		{ name: 'ID', key: 'venue_id', collapsing: true },
		{ name: 'Nombre', key: 'venue_name' },
		{ name: 'Ciudad', key: 'city', collapsing: true }
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getAllVenues(params?.query, offset, count);
	},
});

const VENUE_SECTION : CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn, venue_id: number )=>({
	key: 'venue-section',
	title: 'Seccion',
	emptyText: 'No hay secciones en este recinto',
	catalogUrl: u=>`/venues/${venue_id}/sections/${u.section_id}`,
	createText: 'Nueva Seccion',
	createUrl: `/venues/${venue_id}/sections/create`,
	pageSize: 20,
	table: [
		{ name: 'ID', key: 'section_id', collapsing: true },
		{ name: 'Area', key: 'section_name', collapsing: true },
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getAllVenueSections(venue_id);
	},
});

const DELIVERY : CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn)=>({
	key: 'delivery',
	title: 'Formas de Envio',
	emptyText: 'No existen formas de envio',
	catalogUrl: u=>`/c/delivery/${u.delivery_id}`,
	createText: 'Nueva Forma de Envio',
	search: true,
	createUrl: `/c/delivery/create`,
	pageSize: 20,
	table: [
		{ name: 'ID', key: 'delivery_id', collapsing: true },
		{ name: 'Envío', key: 'method_name' },
		{ name: 'Tipo', key: 'ticket_type', collapsing: true, render: (v: number, obj: DeliveryMethod)=>{
			return <>
				<Icon name={obj.needs_location ? 'shipping fast' : 'mail'} style={{ marginRight: 10 }} />
				{(()=>{
					switch(v){
						case TicketType.DIGITAL: return 'Digital';
						case TicketType.PHYSICAL: return 'Impreso';
						default: return '???';
					}
				})()}
			</>
		}},
		{ name: 'Precio', key: 'delivery_cost', collapsing: true, render: v=>`$${addCommas(v)}` },
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getDeliveryMethods(params?.query, offset, count);
	},
});

const PROMOTER : CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn)=>({
	key: 'promoter',
	title: 'Promotores',
	emptyText: 'No existen promotores',
	catalogUrl: u=>`/promoters/${u.promoter_id}`,
	createText: 'Nuevo Promotor',
	search: true,
	pagination: true,
	pageSize: 20,
	table: [
		{ name: 'ID', key: 'promoter_id', collapsing: true },
		{ name: 'Nombre', key: 'promoter_name', collapsing: false },
		{ name: 'Creación', key: 'date_created', collapsing: true, render: formatUnix },
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getPromoters(params?.query, offset, count);
	},
	submitCreate: (data: any, setLoading: SetLoading)=>{
		return API.createPromoter(data.promoter_name);
	},
	form: {
		promoter_name: {
			type: 'text',
			label: 'Nombre de promotor',
			rules: [
				{ rule: 'length', params: [3,128], prompt: 'El nombre del promotor no es válido.' }
			]
		},
		msg: {
			type: 'message',
			content: 'Favor de asegurarse de que el promotor no exista antes de crearlo.'
		}
	}
});

const PDV : CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn)=>({
	key: 'pdv',
	title: 'Puntos de Venta',
	emptyText: 'No existen Puntos de Venta',
	catalogUrl: u=>`/pdv/${u.pdv_id}`,
	createUrl: `/pdv/create`,
	createText: 'Nuevo PDV',
	search: true,
	pagination: true,
	pageSize: 20,
	table: [
		{ name: 'ID', key: 'pdv_id', collapsing: true },
		{ name: 'Nombre', key: 'pdv_name', collapsing: false },
		{ name: 'Ciudad', key: 'city', collapsing: true },
		{ name: 'Creación', key: 'date_created', collapsing: true, render: formatUnix },
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getPDVList(params?.query, offset, count);
	},
});

const PDV_ACCESS : CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn, pdv_id: number)=>({
	key: 'pdv-acc',
	title: 'Accesos',
	emptyText: 'No hay accesos en este PDV',
	pageSize: 20,
	table: [
		{ name: 'ID', key: 'login_id', collapsing: true },
		{ name: 'Usuario', key: 'admin_id', collapsing: true, centered: true },
		{ name: 'Correo', key: 'email', collapsing: false },
		{ name: 'Fecha', key: 'date_login', collapsing: true, render: formatTime },
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getPDVAcces(pdv_id, offset, count);
	},
});

const PDV_CORTES : CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn, pdv_id: number)=>({
	key: 'pdv-cortes',
	title: 'Cortes',
	emptyText: 'No hay cortes en este PDV',
	pageSize: 20,
	table: [
		{ name: 'ID', key: 'corte_id', collapsing: true },
		{ name: 'Nombre Inicio', key: 'name_start', collapsing: true },
		{ name: 'Fecha Inicio', key: 'date_start', collapsing: true, render: formatTime },
		{ name: 'Nombre Fin', key: 'name_end', collapsing: true},
		{ name: 'Fecha Fin', key: 'date_end', collapsing: true, render: formatTime },
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getPDVCortes(pdv_id, offset, count);
	},
});

const LOGIN_TYPE = [
	{ id: 1, name: 'Web Admin' },
	{ id: 20, name: 'PDV' },
	{ id: 255, name: 'API' },
];

var getLoginType = (a: number)=>LOGIN_TYPE.find(b=>b.id===a)?.name;

const PDV_ACCESS_USER : CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn, admin_id: number)=>({
	key: 'pdv-user',
	title: 'Accesos',
	emptyText: 'No hay accesos en este PDV',
	pageSize: 20,
	pagination: true,
	useTitle: false,
	table: [
		{ name: 'Tipo', key: 'login_type', collapsing: true, render: getLoginType },
		{ name: 'ID', key: 'pdv_id', collapsing: true },
		{ name: 'Nombre', key: 'pdv_name', collapsing: false, render: (a, v)=>(a || getLoginType(v.login_type)) },
		{ name: 'Fecha', key: 'date_login', collapsing: true, render: formatTime },
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getUserLogins(admin_id, offset, count);
	},
});

const SCANNER : CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn)=>({
	key: 'scanner',
	title: 'Checadores',
	emptyText: 'No hay checadores en el sistema',
	catalogUrl: c=>`/scanners/${c.scanner_id}`,
	createText: 'Crear checador',
	createUrl: '/scanners/create',
	pageSize: 10,
	pagination: true,
	table: [
		{ name: 'ID', key: 'scanner_id', collapsing: true },
		{ name: 'Nombre', key: 'scanner_name', collapsing: false },
		{ name: 'Creación', key: 'date_created', collapsing: true, render: formatUnix },
	],
	search: true,
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getScannerList(params?.query, offset, count);
	},
});

const COUPON : CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn, admin_id: number)=>({
	key: 'coupon',
	title: 'Cupones',
	emptyText: 'No hay cupones en el sistema',
	createUrl: '/c/coupons/create',
	catalogUrl: u =>`/c/coupons/${u.coupon_id}`,
	pageSize: 20,
	pagination: true,
	search: true,
	table: [
		{ name: 'ID', key: 'coupon_id', collapsing: true },
		{ name: 'Codigo', key: 'coupon_code', collapsing: false },
		{ name: 'Descripcion', key: 'description', collapsing: true },
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getCouponList(params?.query, offset, count);
	},
});

const TICKET_TEMPLATE : CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn, admin_id: number)=>({
	key: 'ticket_template',
	title: 'Templates boletos',
	emptyText: 'No hay templates en el sistema',
	createUrl: '/templates/creator',
	createText: 'Crear template',
	catalogUrl: u=>`/templates/${u.template_id}`,
	pageSize: 20,
	pagination: true,
	search: true,
	renderData: (a: TicketTemplate)=>{
		return <>
			{/* <Image src={`${CDN_URL}/events/${a.event_id}/200.webp`} alt={a.event_name} fallback={PLACEHOLDER_IMG} /> */}
			<div className="left">
				<div className="meta" style={{ fontSize: 12, lineHeight: '14px' }}>Template #{a.template_id}</div>
				<div className="name">{a.template_name}</div>
			</div>
		</>
	},
	extractKey: (i: TicketTemplate)=>{
		return i.template_id;
	},
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getTicketTemplates(params?.query, offset, count);
	}
})

const EVENT_GROUPS : CatalogType = ()=>({
	key: 'eventsgroups',
	title: 'Eventos Padres',
	emptyText: 'No hay eventos en el sistema',
	catalogUrl: e=>`/parent/${e.group_id}`,
	search: true,
	createText: 'Crear evento',
	createUrl: '/parent/create',
	pageSize: 20,
	pagination: true,
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getEventGroups(params?.query, offset, count);
	},
	renderData: (a: EventGroup)=>{
		return <>
			<Image src={`${CDN_URL}/groups/${a.group_id}/200.webp`} alt={a.group_name} fallback={PLACEHOLDER_IMG} />
			<div className="left">
				<div className="meta" style={{ fontSize: 12, lineHeight: '14px' }}>Evento #{a.group_id}</div>
				<div className="name">{a.group_name}</div>
			</div>
		</>
	},
	extractKey: (item: EventGroup)=>{
		return item.group_id;
	}
})

const USER_EXTERNAL : CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn, admin_id: number)=>({
	key: 'partners',
	title: 'Empresarios',
	emptyText: 'No hay empresarios en el sistema',
	createUrl: '/c/partners/create',
	catalogUrl: u =>`/c/partners/${u.external_id}`,
	pageSize: 20,
	pagination: true,
	search: true,
	table: [
		{ name: 'ID', key: 'external_id', collapsing: true },
		{ name: 'Nombre', key: 'first_name', collapsing: true },
		{ name: 'Apellido', key: 'last_name', collapsing: true },
		{ name: 'Correo', key: 'email', collapsing: false },
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getUserExternals(params?.query, offset, count);
	},
});

// const INVOICE: CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn, admin_id: number)=>({
// 	key: 'partners',
// 	title: 'Facturas',
// 	emptyText: 'No hay facturas en el sistema',
// 	pageSize: 20,
// 	pagination: true,
// 	alwaysShowFilters: true,
// 	search: true,
// 	filters:[
// 		[
// 			{ key:'date_start', type: 'calendar', label: 'Fecha Inicio', shorthand: 'start', defaultValue: moment().add(-7, 'd').unix() }, 
// 			{ key:'date_end', type: 'calendar', label: 'Fecha Fin', shorthand: 'end', defaultValue: moment().unix() }
// 		]
// 	],
// 	table: [
// 		{ name: 'ID', key: 'invoice_id', collapsing: true },
// 		{ name: 'Nombre', key: 'legal_name', collapsing: true },
// 		{ name: 'RFC', key: 'rfc', collapsing: true },
// 		{ name: 'Cantidad', key: 'amount', collapsing: true, centered: true, render: addCommas },
// 	],
// 	getData: (params: CatalogParams, offset: number, count: number)=>{
// 		console.log(params);

// 		return API.getInvoices(params.date_start, params.date_end, 0, 30);
// 	},
// });

const EVENT_ORDERS_CONCILIA: CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn, admin_id: number, event_id: number, method_id: number)=>({
	key: 'partners',
	title: 'Ordenes',
	emptyText: 'No hay ordenes',
	pageSize: 20,
	pagination: true,
	search: true,
	table: [
		{ name: 'Folio', key: 'order_id', collapsing: true },
		{ name: 'Estado', key: 'estado', collapsing: true },
		{ name: 'PDV', key: 'pdv_name', collapsing: true },
		{ name: 'Fecha', key: 'date', collapsing: true },
		{ name: 'Metodo', key: 'method_name', collapsing: true },
		{ name: 'Total', key: 'total', collapsing: true, render: addCommas },
		{ name: 'Conciliado', key: 'total_deposit', render: addCommas },
		{ name: 'Completado', key: 'completed', collapsing: true },
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getEventOrdersConcilia(event_id, method_id, params?.query, offset, count);
	},
});

const PROMOTER_EVENTS: CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn, promoter_id: number,)=>({
	key: 'promoter_event',
	title: null,
	emptyText: 'No hay eventos',
	catalogUrl: u =>`/events/${u.event_id}`,
	pageSize: 20,
	pagination: true,
	search: true,
	table: [
		{ name: 'ID', key: 'event_id', collapsing: true },
		{ name: 'Evento', key: 'event_name' },
		{ name: 'Proxima Fecha', key: 'date', collapsing: true, render: formatTimeEvent },
		{ name: 'Recinto', key: 'venue_name', collapsing: true, render: formatNull },
		{ name: 'Ciudad', key: 'city', collapsing: true, render: formatNull  },
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getPromoterEvents(promoter_id, params?.query, offset, count);
	},
});

const PROMOTER_EVENTS_BALANCE: CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn, promoter_id: number)=>({
	key: 'promoter_event_balance',
	title: null,
	emptyText: 'No hay eventos',
	catalogUrl: u =>`/events/${u.event_id}`,
	pageSize: 20,
	pagination: true,
	search: true,
	table: [
		{ name: 'ID', key: 'event_id', collapsing: true },
		{ name: 'Evento', key: 'event_name' },
		{ name: 'Tickets', key: 'total_tickets', collapsing: true, render: addCommas },
		{ name: 'Comision', key: 'total_commission', collapsing: true, render: addCommas },
		{ name: 'Liquidado', key: 'total_payout', collapsing: true, render: addCommas },
		{ name: 'Total', key: 'total_balance', collapsing: true, render: addCommas },
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getPromoterEventsBalance(promoter_id, params?.query, offset, count);
	},
});

const EVENT_DATE_QUESTION: CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn, event_id: number, date_id: number)=>({
	key: 'event_date_question',
	title: `Preguntas Calendario [${date_id}]`,
	emptyText: 'No hay preguntas en este evento',
	catalogUrl: u => `/events/${event_id}/forms/${date_id}/q/${u.question_id}`,
	pageSize: 20,
	pagination: true,
	createText: 'Crear pregunta',
	createUrl: `/events/${event_id}/forms/${date_id}/q/create`,
	search: true,
	table: [
		{ name: 'ID', key: 'question_id', collapsing: true },
		{ name: 'Question', key: 'question' },
		{ name: 'Comentario', key: 'comment' },
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getEventDateQuestions(date_id);
	},
});

const ZONES : CatalogType = (user: UserLoginUser, hasAccess: HasAccessFn)=>({
	key: 'zones',
	title: 'Zonas',
	emptyText: 'No hay zonas en el sistema',
	createUrl: '/zones/create',
	catalogUrl: u =>`/zones/${u.zone_id}`,
	pageSize: 20,
	pagination: true,
	search: true,
	table: [
		{ name: 'ID', key: 'zone_id', collapsing: true },
		{ name: 'Nombre', key: 'zone_name' },
		{ name: 'Creación', key: 'date_created', collapsing: true, render: formatUnix },
	],
	getData: (params: CatalogParams, offset: number, count: number)=>{
		return API.getZonesList(params?.query, offset, count);
	},
});

export {
	COUPON,
	DELIVERY,
	EVENTS,
	PDV_ACCESS_USER,
	PDV_ACCESS,
	PDV_CORTES,
	PDV,
	PROMOTER,
	SCANNER,
	TICKET_TEMPLATE,
	USER_ADMIN,
	VENUE_SECTION,
	VENUE,
	EVENT_GROUPS,
	USER_EXTERNAL,
	// INVOICE,
	EVENT_ORDERS_CONCILIA,
	PROMOTER_EVENTS,
	PROMOTER_EVENTS_BALANCE,
	EVENT_DATE_QUESTION,
	ZONES,
}