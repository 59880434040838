import React from 'react';
import { Promoter } from '@arema/components/Classes';
import API from '../API';
import SearchCoreModal, { SearchCoreProps } from './SearchCoreModal';

var SearchPromoterModal = (props: SearchCoreProps<Promoter>)=>{
	return <SearchCoreModal
		title='Buscar promotor'
		{...props}
		searchData={(query, page, page_size)=>{
			return API.searchPromoter(query, page * page_size, page_size);
		}}
		pageSize={15}
		table={[
			{ name: 'ID', key: 'promoter_id', collapsing: true },
			{ name: 'Promotor', key: 'promoter_name' },
		]}
	/>
}

export default SearchPromoterModal;