import moment from 'moment';
import React from 'react'
import { Message } from 'react-frontier';
interface DeltedBannerProps {
	date_deleted?: number,
	admin_deleter?: string,
	style?: React.CSSProperties
}

function DeletedBanner(props: DeltedBannerProps) {
	return (
		<Message
			header="Este elemento ya ha sido eliminado"
			text={`${props.admin_deleter ? `Eliminado por: ${props.admin_deleter} -` : ''} 
					${props.date_deleted ? `Fecha eliminado: ${moment.unix(props.date_deleted).format('DD/MM/YYYY HH:mm')}` : ''}`}
			type="error"
			centered
			style={{ maxWidth: 500, width: 'auto', margin: 'auto', marginBottom: 15 }}
		/>
	)
}

export default DeletedBanner;
