import React, { useEffect, useState } from 'react';
import { Modal, } from 'semantic-ui-react';
import { Button, Checkbox, Table, Header, Icon } from 'react-frontier';
import { SetLoading } from '@arema/components/Classes';
import { useUser } from '../AdminHooks';
import { UserExternalEvent } from '../AdminClasses';
import UserAccess from '../UserAccess';
import API from '../API';
import Toast from 'react-hot-toast';

interface UserExternalAcccessModalProps {
	open: boolean,
	onClose: ()=>void,
	onAccessChange: (event: UserExternalEvent, access: number[])=>void,
	title?: string,
	event: UserExternalEvent,
	external_id: number,
	access: number[],
}

interface AccessCategory {
	access_id: number,
	access_name: string,
	restricted: boolean
}

var UserExternalAcccessModal = (props: UserExternalAcccessModalProps)=>{
	var { hasAccess } = useUser();
	var [loadError, setLoadError] = useState<string>(null);
	var [allAccess, setAllAccess] = useState<AccessCategory[]>(null);
	var [access, setAccess] = useState<number[]>(null);

	useEffect(()=>{
		setAccess(props.access);
		setLoadError(null);
		if(allAccess) return;
		if(props.open){
			API.getUserExternalAllAccess().then(res=>{
				if(res.error) return setLoadError(res.message);
				setAllAccess(res.data);
			}).catch(err=>{
				return setLoadError('Hubo un error cargando los permisos de usuarios (LCL-1)');
			});
		}
	}, [props.event, props.open]);

	var onAccessChange = (access_id: number)=>{
		return (checked: boolean)=>{
			var acc = [...access];
			acc = acc.filter(a=>a != access_id);
			if(checked) {
				acc.push(access_id);
			}
			setAccess(acc);
		}
	}

	var saveAccess = (setLoading: SetLoading)=>{
		if(!hasAccess(UserAccess.USERS.EDIT_ACCESS)) return;
		if(access.length==0) return Toast.error('Favor de seleccionar por lo menos 1 permiso');
		setLoading(true);
		API.saveUserExternalEventAccess(props.external_id, props.event.event_id, access).then(res=>{
			if(res.error) return Toast.error(res.message);
			Toast.success('Se han editado los permisos del usuario.');
			props.onAccessChange(props.event, access);
		}).catch(err=>{
			Toast.error('Hubo un error inesperado editando los permisos del usuario (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		});
	}

	var deleteEvent = (setLoading: SetLoading)=>{
		setAccess([]);
		setLoading(true);
		API.saveUserExternalEventAccess(props.external_id, props.event.event_id, []).then(res=>{
			if(res.error) return Toast.error(res.message);
			Toast.success('Se han editado los permisos del usuario.');
			props.onAccessChange(props.event, []);
		}).catch(err=>{
			Toast.error('Hubo un error inesperado eliminando el evento (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var is_dev = hasAccess(UserAccess.ADMIN.DEVELOPER);

	return (
		<Modal open={props.open} onClose={props.onClose} size='tiny' >
			<Modal.Header>{props.title}</Modal.Header>
			{!!props.open && (
				<Modal.Content>
					<Table details fitted striped title='Información' titleSize='small' style={{ marginBottom: 15 }} >
						<Table.Row data={['ID', props.event.event_id]} />
						<Table.Row data={['Evento', props.event.event_name]} />
						<Table.Row data={['Promotor', props.event.promoter_name || <Icon name="minus" color='gray' />]} />
					</Table>
					{loadError ? (
						<Header iconName='face-frown-open' text={'Error'} subtext={loadError} style={{ marginTop: 15 }} />
					) : !allAccess ? (
						<Header loading text={'Cargando permisos'} />
					) : (
						<div style={{ marginTop: 15 }}>
							{allAccess.map((a, i)=>(
								<Checkbox key={`acc-${a.access_id}`} checked={access.indexOf(a.access_id) != -1} onChange={onAccessChange(a.access_id)} style={{ marginBottom: 5 }} label={<>
									{is_dev && (
										<span style={{ color: 'gray', fontSize: 12, marginRight: 5 }}>[{a.access_id}]</span>
									)}
									<span style={{ color: a.restricted ? 'brown' : 'black' }}>{(a.access_name)}</span>
								</>}/>
							))}
							
							<Button text='Eliminar evento' onClick={deleteEvent} color='red' basic style={{ width: 200, margin: 'auto', display: 'block', marginTop: 10, marginBottom: -5 }} />
						</div>
					)}
				</Modal.Content>
			)}
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={props.onClose} />
				{!loadError && (
					<Button text='Continuar' color='black' onClick={saveAccess} disabled={!allAccess} />
				)}
			</Modal.Actions>
		</Modal>
	)
}

export default UserExternalAcccessModal;