import React, { useEffect, useState, useRef } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Button, Header, Icon, Input, Message, Table } from 'react-frontier';
import { useTitle } from '@arema/components/Hooks';
import { ErrorHeader, PaymentInfoModal, UserLog } from '../../components';
import { addCommas, bindChange, bindClose, bindFormChange, dashify } from '@arema/components/Util';
import { Checkbox, Dropdown, Loader, Modal } from 'semantic-ui-react';
import { SetLoading } from '@arema/components/Classes';
import { useUser } from '../../AdminHooks';
import API from '../../API';
import UserAccess from '../../UserAccess';
import moment from 'moment';
import classNames from 'classnames';
import Toast from 'react-hot-toast';
import Validator from '@arema/components/Validator';

interface Refund {
	refund_id: number,
	order_id: number,
	order_hash: string,
	email: string,
	cancelled: boolean,
	completed: boolean,
	executed: boolean,
	refund_type: number,
	date_created: number,
	payment_cost: number,
	delivery_cost: number,
	admin_creator: number,
	admin_completed: number,
	date_completed: number,
	admin_creator_name: string,
	admin_completed_name: string,
	admin_cancelled_name: string,
	admin_cancelled: number,
	date_cancelled: number,
	payments: {
		payment_id: number,
		email_account: string,
		bank: string,
		postal_code: string,
		rfc: string,
		name_account: string,
		method_name: string,
		method_name_internal: string,
		clabe: string,
		card_number: string,
		direct_refund: boolean,
		request_info: boolean,
		amount: number,
		completed: boolean,
		date_created: number,
		date_paid: number,
		external_id: string,
		payment_link?: string,
		refund_expired: boolean,
		cancelled: boolean,
	}[],
	tickets: {
		ticket_id: number,
		ticket_hash: string,
		event_name: string,
		amount: number,
		commission: number,
		paid: boolean,
		cancelled: boolean,
		date: number,
	}[],
	evidence: {
		filename: string,
		date: number,
		link: string,
	}[],
}

enum ShownModal {
	COMPLETE = 1,
	CANCEL = 2,
	PENDING = 3,
	VIEW_REQUEST = 4,
	RESEND_REQUEST = 5,
	EVIDENCE_DELETE = 6,
	INFO_REQUEST = 7,
	DOWNLOAD_STP = 10,
}

var RefundView = () => {
	var { id } = useParams();
	var { setTitle } = useTitle();
	var { user, hasAccess } = useUser();
	var [refund, setRefund] = useState<Refund>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [modalConfirm, setModalConfirm] = useState<boolean>(false);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [loadingStp, setLoadingStp] = useState<boolean>(false);
	var [shownModal, setShownModal] = useState<ShownModal>(null);
	var [requestUrl, setRequestUrl] = useState<string>(null);
	var [loadFile, setLoadFile] = useState<boolean>(false);
	var [resendEmail, setResendEmail] = useState<string>('');
	var [editingPayments, setEditingPayments] = useState<number[]>(null);
	var [selectedEvidence, setSelectedEvidence] = useState<Refund["evidence"][0]>(null);
	var [selectedPayment, setSelectedPayment] = useState<number>(null);
	var [completeForm, setCompleteForm] = useState<{
		stp: boolean,
		completed: boolean
	}>(null);

	const hiddenFileInput = useRef(null)

	var ACCESS = {
		complete: hasAccess(UserAccess.ORDERS.SET_REFUND_COMPLETED),
		cancel: hasAccess(UserAccess.ORDERS.SET_REFUND_CANCELLED),
		pending: hasAccess(UserAccess.ORDERS.REVERT_REFUND_COMPLETE),
		stp: hasAccess(UserAccess.CONTABILIDAD.DOWNLOAD_STP_FILE),
		view_request: hasAccess(UserAccess.ORDERS.VIEW_REFUND_REQUEST),
		resend_request: hasAccess(UserAccess.ORDERS.RESEND_REFUND_REQUEST),
		upload_file: hasAccess(UserAccess.CONTABILIDAD.UPLOAD_PAYOUT_FILE),
		delete_file: hasAccess(UserAccess.CONTABILIDAD.DELETE_PAYOUT_FILE),
	}

	useEffect(() => {
		setTitle(`Reembolso ${id}`);
		if (!Number.isNaN(id) && refund === null) {
			API.getRefund(parseInt(id)).then(res => {
				if (res.error) return setLoadError(res.message);
				setRefund(res.data);
			}).catch(err => {
				return setLoadError('Hubo un error inesperado cargando el reembolso. (LCL-1)');
			});
		}
	}, []);

	if (loadError) {
		return <ErrorHeader text='Error' error={loadError} />
	}
	if (!refund) {
		return <Header loading text='Cargando reembolso' />
	}

	var showModal = (modal: ShownModal) => {
		return () => {
			setCompleteForm({
				stp: false,
				completed: false,
			});
			setModalPrompts(null);
			setModalConfirm(false);
			setShownModal(modal);
		}
	}


	var showSTP = (setLoading: SetLoading) => {
		if (!ACCESS.stp) {
			setShownModal(null);
			return Toast.error('No tienes acceso a descargar el archivo STP.');
		}
		setModalPrompts(null);
		setShownModal(ShownModal.DOWNLOAD_STP);
		setLoadingStp(true);
		API.downloadRefundZip([refund.refund_id]).then(res => {
			if (res.error) return setModalPrompts([res.message]);
		}).catch(err => {
			setModalPrompts(['Hubo un error inesperado descargando el archivo STP. (LCL-1)']);
		}).finally(() => {
			setLoadingStp(false);
		});
	}

	var showCompleteModal = (payment_id: number)=>{
		return ()=>{
			setEditingPayments([payment_id]);
			showModal(ShownModal.COMPLETE)();
		}
	}

	var completePayment = (payments: number[])=>{
		return (setLoading: SetLoading)=>{
			if(!payments || payments.length==0) return;
			if(!modalConfirm) return;
			if (!ACCESS.complete) {
				setShownModal(null);
				return Toast.error('No tienes acceso a completar reembolsos.');
			}
			setModalPrompts(null);
			setLoading(true);
			API.completeRefundPayments(refund.refund_id, payments).then(res=>{
				if(res.error) return setModalPrompts([res.message]);
				setShownModal(null);
				var rfd = {...refund};
				var pending_pays = 0;
				for(var i of rfd.payments){
					if(payments.indexOf(i.payment_id)>-1){
						i.completed = true;
					}
					if(!i.completed) pending_pays++;
				}

				Toast.success('Se han marcado los pagos como completados.');
				if(pending_pays<=0 && !rfd.completed){
					rfd.completed = true;
					rfd.admin_completed = user.admin_id;
					rfd.admin_completed_name = user.username;
					rfd.date_completed = moment().unix();
					Toast.success('Se ha completado el reembolso')
				}
				setRefund(rfd);
			}).catch(err=>{
				return setModalPrompts(['Hubo un error marcando los pagos como completados.']);
			}).finally(()=>{
				setLoading(false);
			})
		}
	}

	var showInfoRequestModal = ()=>{
		setShownModal(ShownModal.INFO_REQUEST)
	}

	var cancelRefund = (setLoading: SetLoading) => {
		if (!ACCESS.cancel) {
			setShownModal(null);
			return Toast.error('No tienes acceso a cancelar reembolsos.');
		}
		if (!modalConfirm) return;
		setModalPrompts(null);
		setLoading(true);
		API.cancelRefunds([refund.refund_id]).then(res => {
			if (res.error) return setModalPrompts([res.message]);
			if(res.data?.refunds && res.data.refunds.indexOf(refund.refund_id)>-1){
				Toast.success('Se han cancelado el reembolso.');
				setShownModal(null);
				var rf = { ...refund };
				rf.cancelled = true;
				rf.admin_cancelled = user.admin_id;
				rf.admin_cancelled_name = user.username;
				rf.date_cancelled = moment().unix();
				setRefund(rf);
			}else{
				setModalPrompts(['Hubo un error cancelando el reembolso. (LCL-2)']);
			}
		}).catch(err => {
			setModalPrompts(['Hubo un error inesperado rechazando el reembolso (LCL-1)']);
		}).finally(() => {
			setLoading(false);
		})
	}

	var showPendingPayment = (payment_id: number)=>{
		return ()=>{
			setEditingPayments([payment_id]);
			showModal(ShownModal.PENDING)();
		}
	}

	var setPaymentPending = (payments: number[])=>{
		return (setLoading: SetLoading) => {
			if(!editingPayments || editingPayments.length==0) return;
			if(!modalConfirm) return;
			if (!ACCESS.pending) {
				setShownModal(null);
				return Toast.error('No tienes acceso a marcar como pendiente reembolsos.');
			}
	
			setModalPrompts(null);
			setLoading(true);
			API.revertRefundPayments(refund.refund_id, editingPayments).then(res=>{
				if(res.error) return setModalPrompts([res.message]);
				Toast.success('Se ha marcado como pendiente el pago del reembolso.');
				var rfd = {...refund};
				for(var i of rfd.payments){
					if(payments.indexOf(i.payment_id)>-1){
						i.completed = false;
					}
				}
	
				rfd.completed = false;
				rfd.admin_completed = null;
				rfd.admin_completed_name = null;
				setRefund(rfd);
				setShownModal(null);
			}).catch(err=>{
				setModalPrompts(['Hubo un error inesperado marcando como pendiente el pago del reembolso (LCL-1)']);
			}).finally(()=>{
				setLoading(false);
			})
		}
	}

	var resendRequest = (setLoading: SetLoading)=>{
		setLoading(true);
		API.resendRefundRequest(refund.refund_id, refund.email || resendEmail).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha reenviado el correo de la solicitud de reembolso.');
			setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado reenviando la solicitud de reembolso (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	}

	var sendInfoRequest = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator({
			email: refund.email || resendEmail
		}, {
			email: ['email']
		});
		setModalPrompts(prompts);
		if(!valid) return;

		setLoading(true);
		API.sendRefundInfoRequest(refund.refund_id, (refund.email || resendEmail)).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha enviado la solicitud de información');
			var ref = {...refund};
			for(var i of ref.payments){
				if(i.refund_expired) i.request_info = true;
			}
			setRefund(ref);
			setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado reenviando la solicitud de reembolso (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var viewRefundRequest = ()=>{
		setShownModal(ShownModal.VIEW_REQUEST);
		if(requestUrl) return;
		API.viewRefundRequest(refund.refund_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			setRequestUrl(res.data.request_url);
			setShownModal(ShownModal.VIEW_REQUEST);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado consiguendo el enlace del reembolso (LCL-1)']);
		});
	}

	var onRefundFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if(!hasAccess(UserAccess.ORDERS.UPLOAD_REFUND_FILE)) return;
		setLoadFile(true);
		const fileList = event.target.files;
		if (!fileList || !fileList[0]) return;
		if (fileList.length > 1) return Toast.error("Favor de solo seleccionar 1 archivo.");
		if (fileList[0].size > (2 * 1024 * 1024)) return Toast.error('El archivo no puede pesar mas de 2MB.');
		API.uploadRefundEvidence(refund.refund_id, fileList[0]).then(res => {
			if (res.error) return Toast.error(res.message);
			Toast.success('Se subío la evidencia correctamente');
			refund.evidence.push(res.data);
		}).catch(err => {
			return Toast.error('Hubo un error subiendo la evidencia del rembolso. (LCL-3)');
		}).finally(() => {
			setLoadFile(false);
		});
	}

	var onClickUpload = () => {
		if (hiddenFileInput) {
			hiddenFileInput.current.click();
		}
	}

	var showDeleteEvidence = (evidence: Refund["evidence"][0])=>{
		return ()=>{
			setSelectedEvidence(evidence);
			showModal(ShownModal.EVIDENCE_DELETE)();
		}
	}

	var deleteEvidence = (setLoading: SetLoading) => {
		if(!hasAccess(UserAccess.ORDERS.DELETE_REFUND_FILE)) return;
		setLoading(true);
		API.removedRefundEvidence(refund.refund_id, selectedEvidence.filename).then(res=>{
			if (res.error) return setModalPrompts([res.message]);
			refund.evidence = refund.evidence.filter(e => e.filename!==selectedEvidence.filename)
			setRefund({...refund});
			setShownModal(null);
			Toast.success('Se elimino la evidencia correctamente');
		}).catch(err=>{
			setModalPrompts(['Hubo un error eliminando la solicitud (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var onCompleteFormChange = bindFormChange(completeForm, setCompleteForm);
	var refund_tickets = 0, refund_commission = 0, refund_total = 0;
	for(var i of refund.tickets){
		refund_tickets += i.amount-i.commission;
		refund_commission += i.commission;
		refund_total += i.amount;
	}

	refund_total += refund.payment_cost+refund.delivery_cost;
	var has_indirect = false, has_expired_payment = false, has_pending_info = false;
	var pending_payments = 0, completed_payments = 0;
	for(var p of refund.payments){
		if(p.cancelled) continue;
		if(!p.completed) pending_payments++;
		else completed_payments++;
		if(p.direct_refund && p.refund_expired){
			if(!p.request_info) has_expired_payment = true;
			else has_pending_info = true;
		}
		if(!p.direct_refund && !p.completed){
			has_indirect = true;
		}
	}

	var editing_payments_data = editingPayments ? refund.payments.filter(a=>editingPayments.indexOf(a.payment_id)>-1) : [];

	var EditingPaymentsTable = editing_payments_data.length>0 && (
		<Table 
			details 
			title='Pagos'
			titleSize='small'
			style={{ marginTop: 15 }}
		>
			{editing_payments_data.map(a=><React.Fragment key={`PYCMPL-${a.payment_id}`}>
				<Table.Row data={['Pago', addCommas(a.amount)]} />
				<Table.Row data={['Forma de pago', a.method_name_internal]} />
				<Table.Row data={['Tipo de reembolso', <>
					<div className="fr label" style={{ marginRight: 5 }}>
						<Icon name={a.card_number ? 'credit-card' : 'landmark'} />
						{a.bank}
					</div>
					{dashify(a.card_number || a.clabe, a.card_number ? 4 : 6, ' ')}
				</>]} />
				<Table.Divider />
			</React.Fragment>)}
			<Table.Row 
				data={['Total Reembolsado', (
					<span style={{ fontWeight: 'bold', fontSize: 18 }}>{addCommas(editing_payments_data.reduce((a,b)=>a+b.amount, 0))}</span>	
				)]}
			/>
		</Table>
	)

	return (
		<div>
			<Table
				striped
				details
				title={`Reembolso ${id}`}
				style={{ maxWidth: 600, margin: 'auto' }}
				actions={<>
					{ACCESS.cancel && !refund.completed && !refund.cancelled && (
						<Button text='Cancelar reembolso' color='red' basic onClick={showModal(ShownModal.CANCEL)} />
					)}
					{has_indirect && ACCESS.stp && !refund.completed && !refund.cancelled && (
						<Button color='black' onClick={showSTP} text='Descargar STP' />
					)}
				</>}
			>
				<Table.Row data={['ID Reembolso', refund.refund_id]} />
				<Table.Row data={['Estatus', <>
					<div className={classNames('fr label', {
						red: refund.cancelled,
						green: !refund.cancelled && refund.executed && refund.completed,
						yellow: !refund.cancelled && refund.executed && !refund.completed,
						blue: !refund.cancelled && !refund.executed
					})} style={{ fontWeight: 'bold' }} >
						<i className={classNames('icon', {
							remove: refund.cancelled,
							checkmark: refund.executed && !refund.cancelled && refund.completed,
							clock: !refund.cancelled && !refund.completed,
						})}></i>
						{(!refund.cancelled && !refund.completed) ? (refund.executed ? 'Pendiente' : 'Enviada') : (refund.completed ? 'Completado' : 'Cancelado')}
					</div>
					{(has_expired_payment || has_pending_info) && (
						<div className="fr red label" style={{ marginLeft: 5 }}>
							No completable
						</div>
					)}
				</>]} />
				<Table.Row data={['Orden', <>
					{refund.order_hash}
					<div className="meta">
						<Button size='tiny' text='Ver' as={Link} to={`/orders/${refund.order_hash}`}  />
					</div>
				</>]} />
				<Table.Row data={['Email', (
					refund.email || <i>Sin correo electrónico</i>
				)]} />
				<Table.Row data={['Fecha creación', <>
					<i className="calendar icon"></i>
					{moment.unix(refund.date_created).format('DD/MMM/YY HH:mm')}
				</>]} />
				<Table.Divider />
				<Table.Row data={['Boletos', addCommas(refund_tickets)]} />
				{refund.payment_cost>0 && (
					<Table.Row data={['Forma de pago', addCommas(refund.payment_cost)]} />
				)}
				{refund.delivery_cost>0 && (
					<Table.Row data={['Entrega', addCommas(refund.delivery_cost)]} />
				)}
				{refund_commission>0 && (
					<Table.Row data={['Comisión', addCommas(refund_commission)]} />
				)}
				<Table.Row data={[refund.completed ? 'Total reembolsado' : (refund.cancelled ? 'Total reembolso' : 'Total a reembolsar'), (
					<div style={{ fontSize: 20, fontWeight: 'bold', lineHeight: '24px', color: refund.cancelled ? 'brown' : (!refund.completed ? 'green' : 'black') }}>
						{addCommas(refund_total)}
					</div>
				)]} />
			</Table>
			{!!refund.completed && !refund.cancelled && !!refund.executed && (
				<Table
					striped
					title='Evidencias'
					style={{ margin: 'auto', marginTop: 15, maxWidth: 600 }}
					centeredIndexes={[2]}
					headers={['Archivo', 'Fecha', <Icon name="ellipsis-h" />]}
					actions={
						<div style={{ textAlign: 'center' }}>
							<Button
								loading={loadFile}
								iconName='upload'
								text='Agregar evidencia'
								onClick={onClickUpload}
							/>
							<input
								ref={hiddenFileInput}
								onChange={onRefundFile}
								style={{ display: 'none' }}
								type="file"
								accept="application/pdf"
							/>
						</div>
					}
				>
					{(refund.evidence && refund.evidence.length > 0) ? refund.evidence.map(a => (
						<Table.Row key={`evi-${a.filename}`} collapsingIndexes={[1,2]} centeredIndexes={[2]} data={[
							a.filename,
							moment.unix(a.date).format('DD/MMM/YY HH:mm'),
							<Dropdown
								icon={null}
								trigger={(
									<Button icon iconName="ellipsis-h" size="tiny" />
								)}
							>
								<Dropdown.Menu>
									<Dropdown.Item as={Link} to={a.link} target='_blank'>Descargar</Dropdown.Item>
									{ACCESS.delete_file && (
										<Dropdown.Item onClick={showDeleteEvidence(a)}>Eliminar</Dropdown.Item>
									)}
								</Dropdown.Menu>
							</Dropdown>
						]} />
					)) : (
						<Table.Cell row empty colSpan={5}>
							No hay evidencias en este reembolso
						</Table.Cell>
					)}
				</Table>
			)}
			{has_expired_payment && (
				<Message style={{ margin: '15px auto', maxWidth: 500 }} type='error' header='Pagos expirados' centered>
					Este reembolso tiene pagos que en su momento se pudieron haber reembolsado automáticamente, pero se ha expirado el tiempo para realizar el reembolso directo. Se necesitará enviar a el cliente una solicitud para pedir datos de transferencia. <br />
					<Button color='black' text='Enviar solicitud' style={{ marginTop: 15, minWidth: 200 }} onClick={showInfoRequestModal} />
				</Message>
			)}
			{has_pending_info && !has_expired_payment && (
				<Message style={{ margin: '15px auto', maxWidth: 500 }} header='Información solicitada' centered>
					Se le ha enviado a este cliente la solicitud para información de transferencia. El reembolso se podrá completar cuando el cliente ingrese su información de transferencia. <br />
					<Button color='black' text='Renviar solicitud' style={{ marginTop: 15, minWidth: 200 }} onClick={showInfoRequestModal} />
				</Message>
			)}
			{refund.payments.length > 0 ? refund.payments.map(p=>(
				<Table 
					striped 
					details 
					key={`tblp-${p.payment_id}`} 
					title={`Pago ${p.payment_id}`} 
					style={{ maxWidth: 600, margin: 'auto', marginTop: 15 }}
					button={(
						<Button text='Ver info' size='tiny' onClick={()=>setSelectedPayment(p.payment_id)} />
					)}
					footer={!p.refund_expired && !refund.cancelled && (ACCESS.complete || ACCESS.pending) && (
						<Table.Cell row colSpan={100} style={{ textAlign: 'right' }}>
							{!p.completed ? (
								!!p.direct_refund ? (
									<Button color='green' text='Reembolsar' />
								) : (
									<Button color='green' text='Completar' onClick={showCompleteModal(p.payment_id)} />
								)
							) : ACCESS.pending ? (
								<Button text='Regresar a pendiente' onClick={showPendingPayment(p.payment_id)} />
							) : null}
						</Table.Cell>
					)}
				>
					<Table.Row data={['Cantidad', addCommas(p.amount)]} />
					<Table.Row data={['Estado', <>
						<div className={classNames('fr label', {
							green: p.completed,
							blue: !refund.cancelled && !p.completed && !p.refund_expired,
							red: p.refund_expired || (!p.completed && refund.cancelled),
						})}>
							{p.completed ? 'Completado' : (p.refund_expired ? 'Expirado' : (refund.cancelled ? 'Cancelado' : 'Pendiente'))}
						</div>
						{!!p.direct_refund && !!p.request_info && (
							<div className="fr orange label" style={{ marginLeft: 5 }}>Información solicitada</div>
						)}
						{!!p.direct_refund && !p.refund_expired && (
							<div className="fr orange label" style={{ marginLeft: 5 }}>Reembolso directo</div>
						)}
					</>]} />
					<Table.Row data={[`Método de pago`, p.method_name_internal]} />
					<Table.Row data={['Fecha pago', <>
						{moment.unix(p.date_paid).format('DD/MMM/YYYY HH:mm')}
						<div className="meta">
							{moment().diff(moment.unix(p.date_paid), 'days')} días
						</div>
					</>]} />
					{p.payment_link ? (
						<Table.Row>
							<Table.Cell>Referencia</Table.Cell>
							<Table.Cell>
								<Link className='normal' to={p.payment_link} target='_blank'>
									{p.external_id || 'Ver pago'}
								</Link>
							</Table.Cell>
						</Table.Row>
					) : null}
					{p.direct_refund ? (
						null
					) : <>
						<Table.Row data={['Propietario', p.name_account]} />
						<Table.Row data={['Email', p.email_account]} />
						<Table.Row data={['RFC', p.rfc]} />
						<Table.Row data={['Codigo postal', p.postal_code]} />
						{(p.clabe || p.card_number) && <>
							<Table.Divider />
							<Table.Row data={['Tipo cuenta', <>
								<i className={`${p.clabe ? 'landmark' : 'credit card'} icon`}></i>
								{p.clabe ? 'Cuenta bancaria' : 'Tarjeta bancaria'}
							</>]} />
							<Table.Row data={[`Banco`, p.bank]} />
							<Table.Row data={[
								p.clabe ? 'Cuenta bancaria' : 'Tarjeta bancaria',
								p.clabe ? dashify(p.clabe, 6, ' ') : dashify(p.card_number || '', 4, ' '),
							]} />
						</>}
					</>}
				</Table>
			)) : refund.executed ? (
				<Header size='small' text='Sin pagos' subtext='No se encontraron pagos en este reembolso. Favor de contactar desarrollo.' iconName='warning' style={{ margin: '20px 0 25px 0' }} />
			) : (
				<Message centered style={{ maxWidth: 500, margin: 'auto', marginTop: 15 }} header='Solicitud pendiente' type='info'>
					Se le ha enviado a el cliente la solicitud de reembolso y no se ha completado. La solicitud de reembolso no se ha completado.
					{ACCESS.view_request ? <div>
						<Button style={{ display: 'block', width: 200, margin: 'auto', marginTop: 15 }} color='blue' text='Ver solicitud' onClick={viewRefundRequest} />
						{ACCESS.resend_request && (
							<Button basic style={{ display: 'block', width: 150, margin: 'auto', marginTop: 5 }} size='tiny' color='black' text='Reenviar solicitud' onClick={showModal(ShownModal.RESEND_REQUEST)} />
						)}
					</div> : ACCESS.resend_request ? <div style={{ marginTop: 15 }}>
						<Button style={{ display: 'block', width: 200, margin: 'auto', marginTop: 15 }} color='blue' text='Reenviar solicitud' />
					</div> : null}
				</Message>
			)}
			<Table
				striped
				title={'Boletos'}
				headers={['Codigo', 'Evento', 'Fecha Evento', 'Reembolso']}
				style={{ maxWidth: 600, margin: 'auto', marginTop: 15, }}
			>
				{refund.tickets.length > 0 ? (refund.tickets.map(t => (
					<Table.Row key={`dt-${t.ticket_id}`} collapsingIndexes={[0, 2, 3, 4]} data={[
						t.ticket_hash,
						t.event_name,
						moment.unix(t.date).format('DD/MMM/YY HH:mm'),
						addCommas(t.amount)
					]} />
				))) : (
					<Table.Row>
						<Table.Cell className='empty' colSpan={5}>
							El reembolso no tiene boletos asignados
						</Table.Cell>
					</Table.Row>
				)}
			</Table>
			<UserLog user={refund} />

			<Modal size='tiny' open={shownModal === ShownModal.COMPLETE && editingPayments && editingPayments.length>0} onClose={bindClose(setShownModal)}>
				<Modal.Header>Completar pago{editingPayments && editingPayments.length>1 ? 's' : ''}</Modal.Header>
				{editingPayments && <Modal.Content>
					<Header text={`¿Completar pago${editingPayments && editingPayments.length>1 ? 's' : ''}?`} subtext={`Se marcará este pago de reembolso como completaado`} style={{ paddingLeft: 0, paddingRight: 0, fontSize: 26 }} />
					{(pending_payments-editingPayments.length)>0 && (
						<Message>
							El reembolso no se completará por completo, faltan <b>{pending_payments-editingPayments.length}</b> pagos por completar, una vez completados todos los pagos se marcará el reembolso como completado.
						</Message>
					)}
					{EditingPaymentsTable}
					<Checkbox toggle checked={modalConfirm} onChange={bindChange(setModalConfirm, true)} label={`Se depositó ${addCommas(editing_payments_data.reduce((a,b)=>a+b.amount, 0))} a las cuentas correspondientes`} style={{ marginTop: 15 }} />
					<Message style={{ marginTop: 15 }} type='error' list={modalPrompts} />
				</Modal.Content>}
				<Modal.Actions>
					<Button text='Cancelar' basic onClick={bindClose(setShownModal)} />
					<Button text='Completar' color='black' disabled={!modalConfirm} onClick={completePayment(editingPayments)} />
				</Modal.Actions>
			</Modal>

			<Modal size='mini' open={shownModal === ShownModal.CANCEL} onClose={bindClose(setShownModal)}>
				<Modal.Header>Cancelar reembolso</Modal.Header>
				<Modal.Content>
					<Header text={'Cancelar Reembolso'} subtext={`Marcará el rembolso como cancelado`} style={{ paddingLeft: 0, paddingRight: 0, fontSize: 26 }} />
					{completed_payments>0 ? (
						<Message type='error' centered header='No cancelable'>
							Ya que el reembolso ya cuenta con pagos realizados, no se puede cancelar el reembolso.
						</Message>
					) : <>
						{!!refund.tickets && !!refund.tickets.find(a=>a.cancelled) ? (
							<Message type='error' centered>
								Los boletos cancelados por el reembolso no serán restablecidos, se tendrán que revivir manualmente.
							</Message>
						) : null}
						<Message type='error' centered style={{ marginTop: 5 }}>
							<b>Una vez cancelado el reembolso no se podrá reestablecer y se tendrá que crear un reembolso nuevamente.</b>
						</Message>
						<Checkbox toggle checked={modalConfirm} onChange={bindChange(setModalConfirm, true)} label="Este reembolso no se ha realizado y se desea cancelar" style={{ marginTop: 15 }} />
					</>}
					<Message list={modalPrompts} type='error' />
				</Modal.Content>
				<Modal.Actions>
					<Button text='Regresar' basic onClick={bindClose(setShownModal)} />
					<Button text='Cancelar' color='red' onClick={cancelRefund} disabled={!modalConfirm || completed_payments>0} />
				</Modal.Actions>
			</Modal>

			<Modal size='tiny' open={shownModal === ShownModal.PENDING} onClose={bindClose(setShownModal)}>
				<Modal.Header>Regresar pago a pendiente</Modal.Header>
				<Modal.Content>
					<Header text={'Regresar pago a pendiente'} subtext={`Marcará el el pago del reembolso como pendiente`} />
					{!!refund.completed && (
						<Message>
							El estatus del reembolso se regresará a pendiente hasta que se complete de nuevo este pago.
						</Message>
					)}
					{EditingPaymentsTable}
					<Checkbox toggle checked={modalConfirm} onChange={bindChange(setModalConfirm, true)} label={`Hubo un error y se desea regresar este pago del reembolso a pendiente`} style={{ marginTop: 15 }} />
					<Message style={{ marginTop: 15 }} type='error' list={modalPrompts} />
				</Modal.Content>
				<Modal.Actions>
					<Button text='Cancelar' basic onClick={bindClose(setShownModal)} />
					<Button text='Regresar a pendiente' color='red' onClick={setPaymentPending(editingPayments)} disabled={!modalConfirm} />
				</Modal.Actions>
			</Modal>

			<Modal size='mini' open={shownModal === ShownModal.DOWNLOAD_STP}>
				<Modal.Header>Descarga STP</Modal.Header>
				{shownModal === ShownModal.DOWNLOAD_STP && <Modal.Content>
					<div style={{ textAlign: 'center', fontSize: 16 }}>Se ha hecho la descarga de el archivo para subir a STP.</div>
					<Message type='warning' centered style={{ marginTop: 15 }}>
						<div className="header">Confirmar descarga</div>
						En cuanto se descargue el archivo y se verifique que no se tengan errores, se necesitará <b>confirmar la descarga</b> para marcar los reembolsos como completados y evitar cualquier tipo de doble deposito.
						<br />
						{loadingStp ? (
							<Loader active inline style={{ marginTop: 15 }} />
						) : !modalPrompts || modalPrompts.length <= 0 ? (
							<Button color='black' onClick={completePayment(refund.payments.filter(a=>!a.completed && a.direct_refund).map(a=>a.payment_id))} disabled={modalPrompts && modalPrompts.length > 0} text='Confirmar descarga' style={{ marginTop: 10 }} />
						) : (
							<Button basic text='Reintentar descarga' style={{ marginTop: 15 }} color='black' onClick={showSTP} />
						)}
					</Message>
					<Message type='error' list={modalPrompts} />
					{(!modalPrompts || modalPrompts.length <= 0) && (
						<Button basic text='Reintentar descarga' disabled={loadingStp} size='tiny' style={{ display: 'block', width: 150, margin: 'auto', marginTop: 15 }} onClick={showSTP} />
					)}
				</Modal.Content>}
			</Modal>
			
			<Modal open={shownModal===ShownModal.VIEW_REQUEST} onClose={bindClose(setShownModal)} size="mini">
				<Modal.Header>Ver solicitud de reembolso</Modal.Header>
				<Modal.Content>
					{requestUrl ? (
						<div>
							<Header size="small" text={'Enlace de solicitud'} subtext="En seguida se muestra el enlace para ver la solicitud de reembolso." />
							<Input readonly value={requestUrl} onFocus={(e)=>e.target.select()} button={(
								<Button icon iconName="external-link" onClick={()=>window.open(requestUrl, '_blank')} />
							)} />
						</div>
					) : (
						<Header loading text="Cargando enlace..." />
					)}
					<Message list={modalPrompts} type='error' />
				</Modal.Content>
				<Modal.Actions>
					<Button text="Cerrar" onClick={bindClose(setShownModal)} />
				</Modal.Actions>
			</Modal>

			<Modal open={shownModal===ShownModal.RESEND_REQUEST} onClose={bindClose(setShownModal)} size="mini">
				<Modal.Header>Reenviar solicitud</Modal.Header>
				<Modal.Content>
					<Message centered text='Se le reenviará a el cliente el correo con instrucciones de como completar su reembolso.' type='info' />
					<Input label='Correo electrónico' style={{ marginTop: 15 }} value={refund.email || resendEmail} onChange={refund.email ? null : bindChange(setResendEmail)} readonly={!!refund.email} />
					<Message list={modalPrompts} type='error' />
				</Modal.Content>
				<Modal.Actions>
					<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
					<Button text='Reenviar' color='black' onClick={resendRequest} />
				</Modal.Actions>
			</Modal>

			<Modal open={shownModal===ShownModal.INFO_REQUEST} onClose={bindClose(setShownModal)} size='mini'>
				<Modal.Header>Solicitar información</Modal.Header>
				<Modal.Content>
					<Header text='Enviar solicitud de información' subtext={'Se le enviará a el cliente un correo para que pueda ingresar su información de transferencia bancaria.'} />
					<Input label='Correo electrónico' value={refund.email || resendEmail} onChange={refund.email && refund.email.length>=5 ? null : bindChange(setResendEmail)} readonly={refund.email && refund.email.length>=5} />
				</Modal.Content>
				<Modal.Actions>
					<Button text="Cerrar" basic onClick={bindClose(setShownModal)} />
					<Button text='Enviar' color='black' onClick={sendInfoRequest} />
				</Modal.Actions>
			</Modal>

			<Modal open={shownModal===ShownModal.EVIDENCE_DELETE} onClose={bindClose(setShownModal)} size='tiny'>
				<Modal.Header>Eliminar evidencia</Modal.Header>
				{!!selectedEvidence && <Modal.Content>
					<Header text='¿Eliminar evidencia?' subtext={'Se eliminará por completo la evidencia seleccionada de este reembolso'} />
					<Table
						details
						title='Evidencia'
						titleSize='small'
						data={[
							['Reembolso', refund.refund_id],
							['Evidencia', selectedEvidence.filename],
							['Fecha creación', selectedEvidence.date],
							['Enlace', (
								<Link to={selectedEvidence.link} target='_blank' style={{ color: '#4183c4', padding: 0 }}>
									<Icon name='external-link' /> Ver evidencia
								</Link>
							)]
						]} 
					/>
				</Modal.Content>}
				<Modal.Actions>
					<Button text='Cerrar' basic onClick={bindClose(setShownModal)} />
					<Button text='Eliminar' color='red' onClick={deleteEvidence} />
				</Modal.Actions>
			</Modal>
			<PaymentInfoModal
				payment_id={selectedPayment}
				open={!!selectedPayment}
				onClose={bindClose(setSelectedPayment)}
				editable={false}
			/>
		</div>
	)
}

export default RefundView;