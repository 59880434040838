import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { EventSectionProps, SetLoading } from "@arema/components/Classes";
import { Button, Header, Icon, Table } from "react-frontier";
import { arrayMoveImmutable } from "array-move";
import { bindClose } from "@arema/components/Util";
import { Dropdown, Modal} from "semantic-ui-react";
import SortableList, { SortableItem } from "react-easy-sort";
import API from "../../API";
import Toast from "react-hot-toast";

enum QuestionType {
	OPEN = 1,
	TEXTBOX = 2,
	SELECTION = 10,
	MULTIPLE = 11,
	YES_NO = 12,
	RATING = 13,
	FILE_DOWNLOAD = 30,
	FILE_IMAGE = 31,
	FILE_OTHER = 32,
	DATE = 50,
	STATIC_TEXT = 90,
}

interface DateQuestion {
	question_id?: number,
	question_type: QuestionType,
	question: string,
	active: boolean,
	comment: string,
	default_value: string,
	required: boolean,
	sort_order?: number,
}

enum ModalType {
	'CONFIRM_DELETE_QUESTION' = 101,
}

var EventFormsContainer = (props: EventSectionProps) => {
	var params = useParams();
	var event_id = parseInt(params.id);
	const p = params['*'].split('/')
	var date_id = parseInt(p[1]);

	var [datesQuestions, setDatesQuestions] = useState<DateQuestion[]>(null);
	var [questionsOrdered, setQuestionsOrdered] = useState<DateQuestion[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [sortMode, setSortMode] = useState<boolean>(false);
	var [selectedQuestion, setSelectedQuestion] = useState<DateQuestion>(null);
	const [shownModal, setShownModal] = useState<ModalType>(null)

	var loadDatesForms = () => {
		API.getEventDateQuestions(date_id).then(res => {
			if (res.error) return setLoadError(res.message);
			setDatesQuestions(res.data);
			setQuestionsOrdered(res.data);
		}).catch(err => {
			setLoadError('Hubo un error cargando las preguntas. (LCL-1)');
		});
	}

	useEffect(() => {
		if (!datesQuestions) {
			loadDatesForms();
		}
	}, []);

	const onSortEnd = (oldIndex: number, newIndex: number) => {
		setQuestionsOrdered((array) => arrayMoveImmutable(array, oldIndex, newIndex));
	};

	var onSaveOrder = (setLoading: SetLoading) => {
		setLoading(true);
		var qOrder: { question_id: number, sort_order: number }[] = questionsOrdered.map((q, index) => ({ question_id: q.question_id, sort_order: questionsOrdered.length - index }))
		API.updateQuestionOrder(qOrder).then((res) => {
			if (res.error) return setLoadError(res.message);
			Toast.success("Se guardaron las preguntas");
			setDatesQuestions([...questionsOrdered]);
			setSortMode(false);
		}).catch(() => {
			setLoadError('Hubo un error guardando las preguntas. (LCL-1)');
		}).finally(() => {
			setLoading(false);
		})
	}

	var deleteQuestion = (setLoading: SetLoading) => {
		setLoading(true);
		let question_id = selectedQuestion.question_id;
		API.deleteQuestion(question_id).then((res) => {
			if (res.error) return setLoadError(res.message);
			var newQ = datesQuestions.filter(q => q.question_id !== question_id)
			setDatesQuestions([...newQ]);
			setQuestionsOrdered([...newQ]);
			Toast.success("Se elimino la pregunta");
		}).catch(() => {
			setLoadError('Hubo un error eliminando la pregunta. (LCL-1)');
		}).finally(() => {
			setLoading(false);
		})
	}

	var onOpenConfirmDelete = (question: DateQuestion) => {
		return () => {
			setSelectedQuestion(question);
			setShownModal(ModalType.CONFIRM_DELETE_QUESTION)
		}
	}

	if (loadError) return <Header text="Error" subtext={loadError} />
	if (!datesQuestions) return <Header loading text="Cargando preguntas" />

	if(sortMode){
		return <table className="fr table" style={{ margin: 'auto', maxWidth: 800 }}>
			<thead>
				<tr>
					<th className="title button" colSpan={4}>
						<div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
							Preguntas
							<Button size='tiny' text="Guardar" color="black" onClick={onSaveOrder} style={{ textTransform: 'none', minWidth: 100 }} />
						</div>
					</th>
				</tr>
				<tr>
					<th>ID</th>
					<th>Pregunta</th>
					<th>Tipo</th>
					<th>Activa</th>
				</tr>
			</thead>
			<SortableList onSortEnd={onSortEnd} draggedItemClassName="dragged" as="tbody">
				{datesQuestions.map(a=>(
					<SortableItem key={`qt-o-${a.question_id}`}>
						<tr style={{ backgroundColor: 'white' }}>
							<td style={{ padding: '.75rem 1rem', zIndex: 1 }} className="collapsing">{a.question_id}</td>
							<td style={{ padding: '.75rem 1rem', flexGrow: 1, zIndex: 1 }}>{a.question}</td>
							<td style={{ padding: '.75rem 1rem', zIndex: 1 }} className="collapsing">{QuestionType[a.question_type]}</td>
							<td style={{ padding: '.75rem 1rem', zIndex: 1, textAlign: 'center' }} className="collapsing">
								<Icon name={a.active ? 'check' : 'remove'} color={a.active ? 'green' : 'black'} />
							</td>
						</tr>
					</SortableItem>
				))}
			</SortableList>
		</table>
	}

	return <>
		<Table
			title="Preguntas"
			button={<div>
				{!sortMode ? <>
					<Button size='tiny' text="Ordenar" iconName="sort" onClick={bindClose(setSortMode, true)} style={{ marginRight: 10, textTransform: 'none' }} />
					<Button size='tiny' text="Agregar" color="black" as={Link} iconName='add' to={`/events/${event_id}/forms/${date_id}/q/create`} style={{ textTransform: 'none' }} />
				</> : (
					<Button size='tiny' text="Guardar" color="black" onClick={onSaveOrder} style={{ textTransform: 'none', minWidth: 100 }} />
				)}
			</div>}
			style={{ margin: 'auto', maxWidth: 800 }}
			centeredIndexes={[3, 4]}
			headers={['ID', 'Pregunta', 'Tipo', 'Activa', <Icon name="pen" />]}
		>
			{datesQuestions.map(a=>(
				<Table.Row
					collapsingIndexes={[0, 2, 3, 4]}
					centeredIndexes={[3]}
					key={a.question_id}
					data={[
						a.question_id,
						a.question,
						QuestionType[a.question_type],
						<Icon name={a.active ? 'check' : 'remove'} color={a.active ? 'green' : 'black'} />,
						<Dropdown icon={null} trigger={(
							<Button icon iconName="pen" className="tiny" />
						)}>
							<Dropdown.Menu>
								<Dropdown.Item text={'Editar'} icon={'pencil'} as={Link} to={`/events/${event_id}/forms/${date_id}/q/${a.question_id}`} />
								<Dropdown.Item text={'Eliminar'} icon={'remove'} onClick={onOpenConfirmDelete(a)} />
							</Dropdown.Menu>
						</Dropdown>
					]}
				/>
			))}
		</Table>
		<Modal open={shownModal === ModalType.CONFIRM_DELETE_QUESTION && !!selectedQuestion} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Eliminar Pregunta</Modal.Header>
			<Modal.Content>
				<Header size='small' text='¿Eliminar la pregunta?' />
				<ul style={{ paddingLeft: 15 }}>
					<li>Una vez eliminada la pregunta, no podrá ser recuperada</li>
				</ul>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cancelar' basic onClick={bindClose(setShownModal)} />
				<Button text='Eliminar' color={'red'} onClick={deleteQuestion} />
			</Modal.Actions>
		</Modal>

	</>
}

export default EventFormsContainer;